.books-row {
  position: relative;
  margin-bottom: 60px;
}
.skip-link-wrp {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: absolute;
  top: 0;
  width: 100%;
}
.skip-link-div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.empty-row {
  display: flex;
}
.mini-books-row {
  position: relative;
  height: 230px;
}
.rows-wrapper {
  /* margin-top: 540px; */
  position: relative;
  /* top: -100px; */
  /* margin-top: auto; */
  overflow: hidden;
}
h2.row-title {
  font-size: var(--fs-row-titles);
  color: var(--primary);
  font-weight: 300;
  padding: 0 10px 0 30px;
}
h2.row-title.mini-row-title {
  font-size: var(--fs-xl);
}
.row-title span {
  font-size: var(--fs-sm);
  font-weight: bold;
}
.rows-container {
  width: 100%;
  overflow: hidden;
}

.lsp-positioner-bs {
  width: 100%;
  z-index: 1;
}

.history-icon {
  color: var(--primary);
  /* min-width: 290px;
  min-height: 450px; */
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.history-icon svg {
  font-size: var(--icn-desktop-lg);
}
.history-icon p {
  font-size: var(--fs-md);
  text-align: center;
}

.loader-container {
  position: relative;
  top: 37%;
  left: 40%;
}

@media only screen and (max-width: 992px) {
  .history-icon {
    min-width: 100px;
    min-height: 160px;
  }
  .history-icon svg {
    font-size: var(--icn-desktop-lg);
  }
  .history-icon p {
    font-size: var(--fs-sm);
  }
}
@media only screen and (max-width: 768px) {
  .books-row {
    margin-bottom: 30px;
    min-height: 160px;
  }
  .books-row .slick-slider.slick-initialized {
    height: 210px;
  }
  .loader-container {
    top: 80px;
    left: 35px;
  }
  h2.row-title {
    font-size: var(--fs-lg);
    padding: 0 10px;
  }
  h2.row-title.mini-row-title {
    font-size: var(--fs-lg);
  }
}
