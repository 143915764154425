.shareButton-center {
  position: absolute;
  bottom: -35px;
  left: -5px;
  /* transform: translate(-50%, -50%); */
}
.share-menu {
  position: relative;
  width: 220px;
  height: 100px;
}
.share-menu .share-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  /* background: #000; */
  color: var(--primary);
  /* font-size: 30px; */
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}
.share-menu .share-btn-close {
  display: none;
}
.share-menu.active .share-btn-share {
  display: none;
}
.share-menu.active .share-btn-close {
  display: inline;
}
.share-menu .social-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: var(--card-color);
  color: var(--primary);
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  font-size: 1.7rem;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* Clipboard */
.share-menu.active div:nth-child(2) {
  top: 105%;
  left: 85%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* EMails */
.share-menu.active div:nth-child(3) {
  top: 125%;
  left: 68%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* WhatsApp */
.share-menu.active div:nth-child(4) {
  top: 120%;
  left: 51%;
  transform: translate(-50%, 0%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* Twitter */
.share-menu.active div:nth-child(5) {
  top: 125%;
  left: 36%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* Facebook */
.share-menu.active div:nth-child(6) {
  top: 105%;
  left: 20%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
button.react-share__ShareButton:focus,
.shareButton-outer {
  position: relative;
}
.share-menu-linear {
  display: none;
  justify-content: flex-start;
  align-items: center;
  width: 315px;
  height: 48px;
  background-color: var(--card-color);
}
.share-menu-linear.active {
  position: absolute;
  top: -7px;
  right: 8px;
  background: var(--card-color);
  z-index: 1;
  box-shadow: 0 0 5px 1px var(--shadow-dark);
  transition: right 0.3s ease-out;
  display: flex;
}
.share-btn svg {
  vertical-align: auto;
  height: 50px;
}
.shareButton-outer .share-btn {
  position: absolute;
  font-size: var(--fs-xl);
  right: 0;
  color: var(--primary);
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  /* z-index: 2; */
}
.share-menu-linear .social-btn {
  width: 50px;
  font-size: var(--fs-xl);
  color: var(--primary);
  border-right: 1px solid;
  cursor: pointer;
}
.shareButton-outer.active .share-btn-close {
  display: inline;
}
.shareButton-outer .share-btn-close {
  display: none;
}
.shareButton-outer.active .share-btn-share {
  display: none;
}

@media only screen and (max-width: 480px) {
  .share-menu-linear .social-btn {
    width: 15vw;
  }
  .shareButton-outer .share-btn {
    height: 15vw;
    line-height: 15vw;
  }
  .share-menu-linear {
    width: 90vw;
    height: 15vw;
  }
}
@media only screen and (max-width: 768px) {
  .share-menu-linear.active {
    right: 7px;
  }
}
