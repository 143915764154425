nav {
  position: fixed;
  top: 0;
  z-index: 101;
}

.brand-link {
  height: 100px;
}
.search-box-wrap {
  overflow: hidden;
  position: relative;
}
.search-box-container {
  /* height: 100px; */
  position: relative;
  right: -410px;
  margin: 35px 0 10px 10px;
  -webkit-transition: right 0.3s ease-out;
  -moz-transition: right 0.3s ease-out;
  -o-transition: right 0.3s ease-out;
  transition: right 0.3s ease-out;
}
.search-box-container.active {
  right: 5px;
}
.search-box-container svg {
  position: absolute;
  left: 10px;
  top: 10px;
  color: rgb(180, 180, 180);
  font-size: var(--fs-xl);
}
.search-box-container.mobile {
  position: relative;
  margin: 10px 5px;
  right: auto;
}
#search-box {
  padding: 10px 10px 10px 50px;
  font-size: var(--fs-md);
  height: 50px;
  border-radius: 25px;
  width: 100%;
  border: 1px solid var(--primary);
  box-shadow: inset 1px 2px 5px 0px var(--shadow-dark);
}
#search-icon {
  cursor: pointer;
}
.navbar-container {
  height: 100px;
  display: flex;
  justify-content: stretch;
  align-items: top;
  color: var(--secondary);
  width: 100%;
}
.nav-links-titles {
  font-size: var(--nav-fs-lg);
  font-weight: bold;
  /* text-transform: uppercase; */
}
.navbar-container a,
.navbar-container button {
  border-radius: 4px;
}
.nav-menu .nav-links-titles {
  padding-top: 10px;
}
.nav-menu.active .nav-links-titles {
  padding-top: 0;
}

nav .nav-links:hover {
  color: var(--primary) !important;
  border-bottom: 3px solid var(--primary);
  cursor: pointer;
}
.inactive-nav p {
  margin-top: -1px;
}
.active-nav section {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
}
.active-nav .user-avatar {
  color: var(--primary);
}
.mob-links-titles {
  font-size: var(--nav-mob-fs);
}

section.nav-links {
  padding-bottom: 5px;
  font-size: var(--nav-icns);
}
section.mobile-links {
  padding-bottom: 5px;
  font-size: var(--nav-icns);
  display: flex;
  align-items: center;
}

nav section:hover,
.active-nav {
  color: var(--primary);
  /* transition: all 0.2s ease-out; */
}

section.mobile-links {
  height: auto;
}

.inside-navs {
  margin: auto 28%;
}

.navbar-logo {
  justify-self: start;
  margin: 15px;
  height: 70px;
  width: 200px;
  cursor: pointer;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.nav-menu {
  display: flex;
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 40px 10px 0;
  height: 100%;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  z-index: 12;
}
.menu-icon,
nav .copyright {
  display: none;
  z-index: 1001;
}

/* nav section svg {
  width: 45px;
  height: auto;
  display: block;
} */

.hidden {
  display: none;
}

.items-inside {
  display: block;
  width: 100%;
  color: var(--navbar-icons-color);
  padding: 10px 0;
}
#user-menu:focus .user-avatar {
  color: var(--primary);
}
@media screen and (max-width: 960px) {
  .navbar-container {
    position: fixed;
    height: 100px !important;
  }

  .nav-menu {
    position: absolute;
    top: 0;
    flex-direction: column;
    padding: 60px 40px 0;
    width: 50vw;
    height: 100vh;
    margin-right: 0;
    right: -100%;
  }
  .nav-menu.active section.nav-links {
    padding: 10px 0;
  }

  .nav-menu a {
    display: none;
  }
  .nav-menu.active a {
    display: block;
    padding: 10px 0;
  }
  .nav-menu.active {
    position: fixed;
    background-color: var(--background-color);
    right: 0;
    transition: all 0.5s ease;
    overscroll-behavior: contain;
    box-shadow: 0px 0px 10px 0px var(--shadow-dark);
    justify-content: flex-start;
    transition: all 0.5s ease;
    overflow: auto;
  }

  nav .nav-links:hover,
  nav section:hover,
  .active-nav section {
    border: none;
  }

  .menu-icon {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  nav .copyright {
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    left: 35%;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  #user-menu {
    display: none;
  }
  .hidden {
    display: block;
  }
  .search-box-wrap {
    width: 100%;
    position: sticky;
    top: -60px;
    background: var(--background-color);
    margin: 5px 0;
  }
  .search-box-container.active {
    padding: 5px;
    height: auto;
    margin: 10px 5px;
  }
  .search-box-container.active svg {
    left: 15px;
    top: 15px;
  }
  nav section {
    text-align: left;
    padding: 30px 0 0 40px;
  }

  nav section svg {
    margin: 0 20px 0 0;
    float: left;
  }
}

@media screen and (max-width: 600px) {
  .search-box-container {
    padding: 0;
  }
  .nav-menu {
    width: 100vw;
    padding: 60px 40px 0;
  }

  .nav-menu.active {
    font-size: 0.9rem;
  }

  .nav-links-titles {
    font-size: var(--fs-md);
  }
}

@media screen and (max-width: 340px) {
  .nav-links-titles {
    font-size: var(--fs-sm);
  }
  nav section {
    padding: 5px 0 0 20px;
  }
}
