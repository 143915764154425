.settings-outer {
  /* margin: 0 10vw; */
  overflow: hidden;
}
.settings-tabs {
  padding: 200px 5vw 0;
  width: 100%;
}
.settings-tabs ul {
  list-style: none;
  display: flex;
  text-align: center;
}
.settings-tabs .settings-tabs-item {
  color: var(--secondary);
  display: inline-block;
  font-size: var(--fs-lg);
  font-weight: 900;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  width: 100%;
}
.settings-tabs .settings-tabs-item:hover,
.settings-tabs-item.activeTab {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
}
.settings-tabs ul li {
  flex: 1;
}
.settings-middle {
  padding: auto 10vw;
  display: flex;
  flex-flow: row wrap;
}
.settings-middle > * {
  flex: 1 1 200px;
}
.settings-column {
  background-color: var(--card-color);
  padding: 10px 20px;
  margin: 15px 5px;
  min-height: 360px;
  position: relative;
  border-bottom: 1px solid var(--primary);
}
.settings-column.active {
  outline: 3px solid var(--primary);
}
.settings-column .centered-form {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.settings-long {
  min-width: 90%;
  overflow: hidden;
}
.settings-free {
  width: 100%;
  /* overflow: hidden; */
}

.preference-list-item {
  color: var(--primary);
  font-size: var(--fs-lg);
  /* width: 250px; */
  padding: 10px 20px;
}
.preference-list-item svg {
  margin: 0 20px;
}
.settings-text {
  color: var(--secondary);
  font-size: var(--fs-md);
  padding: 20px 20px 0 0;
}

.settings-column h2,
.settings-long h2 {
  font-size: var(--fs-lg);
  color: var(--primary);
  padding: 10px;
}

button.edit-btn {
  color: var(--secondary);
  border: 1px solid;
  padding: 4px 10px;
  border-radius: 30px;
  opacity: 0.5;
  cursor: pointer;
  height: 25px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
button.edit-btn:hover,
button.edit-btn:focus {
  opacity: 1;
  background: var(--primary);
  color: #fff;
}
button.edit-btn svg {
  font-size: var(--icn-ins-btn);
  margin-left: 10px;
}
button.edit-btn.wide-edit-btn {
  margin: 5px auto;
}
.user-avatar-div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.user-avatar-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.avatar-sqr-img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.user-avatar-icon {
  font-size: 150px;
  display: block;
  margin: 0 auto;
  color: rgb(192, 192, 192);
}
.settings-container {
  margin: 30px;
}
/*------------------------ styling the Radio Buttons -----------------------*/
.settings-radio-group {
  position: relative;
  color: var(--secondary);
  font-size: var(--fs-md);
}
.settings-radio-group label {
  display: block;
  padding: 12px 0 12px 48px;
}
/*style and hide original checkbox*/
.settings-radio-group input {
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

/*position new box*/
.settings-radio-group input + label::before {
  border: 2px solid;
  content: "";
  height: 30px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 30px;
  border-radius: 50%;
  color: var(--primary);
}

/*radio pseudo element styles*/
.settings-radio-group input + label::after {
  content: "";
  opacity: 0;
  border: 10px solid;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: opacity 0.2s ease-in-out;
  color: var(--primary);
}

/*reveal check for 'on' state*/
.settings-radio-group input:checked + label::after {
  opacity: 1;
}

/*focus styles*/
.settings-radio-group input:focus + label::before {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  z-index: 1;
}

.settings-radio-group input:disabled + label::before,
.settings-radio-group input:disabled + label::after {
  color: var(--secondary);
}
/* ----------------------------------------------------------- END Radio Buttons ----------------------------------------------------------- */

.settings-account-list {
  padding: 0 30px;
  font-size: var(--fs-md);
  display: flex;
}

.preference-list {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  overflow-y: auto;
  max-height: 250px;
}
.preference-list.limited {
  max-height: 135px;
  overflow-y: scroll;
  margin: 10px auto 20px;
}
.pref-list-ver {
  flex-flow: column nowrap;
}
.pref-list-hor {
  flex-flow: row wrap;
  align-self: flex-start;
}

.preference-item {
  color: var(--secondary);
  font-size: var(--fs-sm);
  border: 1px dashed var(--secondary);
  margin: 3px;
  padding: 3px 5px;
  display: inline-block;
}
.preference-line {
  color: var(--secondary);
  font-size: var(--fs-sm);
}

.settings-account-list dl {
  width: 100%;
  overflow: hidden;
}
.settings-account-list dt {
  color: var(--primary);
  float: left;
  width: 55%;
  padding-bottom: 10px;
  margin: 0;
}
.settings-account-list dd {
  color: var(--secondary);
  font-size: var(--fs-sm);
  float: left;
  width: 45%;
  padding-bottom: 15px;
  margin: 0;
  overflow-wrap: break-word;
}

.settings-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  /* flex-wrap: wrap; */
  /* margin-left: 20px; */
}
.settings-buttons button {
  padding: 4px 10px;
  min-width: 90px;
  border-radius: 20px;
  border: 1px solid;
  background: transparent;
  color: var(--secondary);
  margin-left: 15px;
}
.settings-buttons button:not([disabled]):hover,
.settings-buttons button:focus {
  cursor: pointer;
  border: 1px solid #fff;
  background: var(--primary);
  color: #fff;
}
.settings-buttons button:disabled {
  opacity: 0.3;
}
.settings-buttons button svg {
  font-size: var(--icn-ins-btn);
  margin-left: 5px;
}
.loans-row-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 40px;
  overflow-x: auto;
  overflow-y: hidden;
}
.preference-warning {
  color: var(--primary);
  font-size: var(--fs-md);
  padding: 20px;
  background: var(--background-color);
  width: 100%;
  border-radius: 5px;
}

.books-row .slick-slider .slick-list {
  overflow: visible;
}
.mini-books-row .slick-slider .slick-list {
  overflow: visible;
}
.events-row .slick-slider .slick-list {
  overflow: visible;
}
.mini-books-row .slick-track, /* to align isolated jackets to the left instead of the middle */
.books-row .slick-track {
  margin-left: 3px;
}

@media only screen and (max-width: 992px) {
  .preference-item {
    font-size: var(--fs-xs);
  }
  .preference-list {
    padding: 5px;
  }
  dt {
    width: 55%;
    font-size: var(--fs-md);
  }
  dd {
    width: 45%;
    font-size: var(--fs-md);
  }
  .settings-account-list dl {
    padding: 10px;
  }
  .user-avatar-div {
    margin: 10px auto;
  }
  .settings-tabs .settings-tabs-item {
    font-size: var(--fs-md);
    font-weight: 600;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 1400px) {
  .settings-buttons {
    flex-wrap: wrap;
    width: 100%;
  }
  .settings-buttons button {
    margin: 5px 0;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .mini-books-row {
    height: 200px;
    overflow: hidden;
  }
  .user-avatar-div {
    width: 200px;
  }
  .loans-row-container {
    padding: 0 10px;
  }
  .settings-column {
    padding: 10px;
    margin: 10px;
  }

  .settings-tabs .settings-tabs-item {
    font-size: var(--fs-sm);
  }
  .settings-account-list {
    flex-direction: column;
    padding: 0;
  }
  .settings-account-list dd,
  .settings-account-list dt {
    font-size: var(--fs-sm);
    width: 100%;
    float: none;
    padding: 0;
  }
  .settings-account-list dt {
    padding-top: 20px;
  }
  .preference-list-item {
    padding: 0;
  }
}
