.user-nav-outer {
  position: relative;
  cursor: pointer;
}
.avatar-img {
  overflow: hidden;
  width: 45px;
  height: auto;
  margin-bottom: -15px;
}
.inner-menu-wrap {
  height: 0;
  z-index: -1;
  transition: height 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  filter: drop-shadow(2px 2px 3px var(--shadow-dark));
  position: absolute;
  top: 50px;
  left: -5px;
  width: 75px;
}
.inner-menu-wrap.active {
  height: 210px;
  z-index: 1;
}
.inner-menu {
  border-radius: 5px 5px 0 0;
  height: 100%;
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 50% 90%);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 50% 90%);
  /* width: 70px; */
}
.user-menu-outer {
  position: relative;
  color: var(--navbar-icons-color);
}
.inner-icons-container {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}
.inner-icons-container button,
.inner-icons-container a {
  flex: 1;
  /* min-height: 50px; */
  color: inherit;
  padding: 4px 0;
}
.inner-icons-container button:focus,
.inner-icons-container a:focus {
  outline-offset: -4px;
}
.inner-icons-container button:hover,
.inner-icons-container a:hover,
.user-name:hover {
  transform: scale(1.1);
  color: var(--primary);
  cursor: pointer;
}
p.user-icons-labels {
  font-size: var(--nav-fs-sm);
  line-height: 0.9rem;
  color: inherit;
  font-weight: bold;
}
.user-icons {
  font-size: var(--fs-xl);
  margin: 6px 10px 0;
  text-align: center;
  /* color: var(--sticky-navbar-icons-color); */
  /* filter: drop-shadow(1px 1px 2px var(--shadow-light)); */
}
.user-avatar {
  margin: 0;
  font-size: var(--nav-icns);
}
.user-nav-outer:hover {
  color: var(--primary);
}
.user-nav-outer:hover svg {
  color: var(--primary);
}

.user-name {
  font-size: var(--nav-fs-lg);
  padding-top: 10px;
  font-weight: bold;
}
.user-name.active {
  color: var(--primary);
}

.logout-waiting {
  position: absolute;
  left: 20px;
  bottom: 20px;
  opacity: 0.5;
}
