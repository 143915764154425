.preferences-outer-container {
  background: url(/dark-background-static.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: repeat;
  padding: 200px 0;
}
.preferences-background-overly {
  height: 100%;
  width: 100%;
  background: var(--background-color);
  opacity: 0.95;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.preferences-inner-container {
  height: 100%;
  width: 70%;
  margin: 0 auto;
}

.preferences-inner-container h3 {
  color: var(--primary);
  font-size: var(--fs-row-titles);
  font-weight: 100;
  z-index: 2;
  position: relative;
}
.preferences-buttons {
  width: 70%;
  margin: 0 auto;
}
.preferences-avatars-container,
.preferences-genres-container,
.preferences-formats-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 40px;
}
.justify-center {
  justify-content: center !important;
}

.avatar-outer {
  width: 120px;
  height: 120px;
  background-color: var(--secondary);
  margin: 10px;
  z-index: 2;
}

.genres-outer {
  width: 200px;
  min-height: 50px;
  color: var(--secondary);
  outline: 1px;
  outline-style: solid;
  outline-color: var(--secondary);
  text-align: center;
  padding: 10px;
  font-size: var(--fs-md);
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}
.avatar-outer:hover,
.avatar-outer.avatar-chosen {
  outline: 3px;
  outline-style: solid;
  outline-color: var(--primary);
  cursor: pointer;
}
.genres-outer:hover,
.genres-outer.genres-chosen {
  outline: 3px;
  outline-style: solid;
  outline-color: var(--primary);
  cursor: pointer;
}
.genres-outer.genres-chosen {
  color: var(--primary);
  font-weight: bold;
}
.formats-outer {
  width: 280px;
  height: 280px;
  background-color: white;
  margin: 30px 10px;
  z-index: 2;
}
.formats-outer p {
  width: 100%;
  display: block;
  text-align: center;
  font-size: var(--fs-xl);
  color: var(--secondary);
  z-index: 2;
}
.formats-outer:hover,
.formats-outer.formats-chosen {
  outline: 4px;
  outline-style: solid;
  outline-color: var(--primary);
  color: var(--primary);
  cursor: pointer;
}
.formats-outer.formats-chosen p {
  color: var(--primary);
  font-weight: bold;
}
.skip-link {
  width: 100%;
  text-align: center;
}
.skip-link a {
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}
.image-fit {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 992px) {
  .preferences-inner-container {
    width: 100%;
    padding: 0 20px;
  }

  .preferences-avatars-container,
  .preferences-genres-container,
  .preferences-formats-container {
    padding: 40px 0;
  }

  .preferences-inner-container h3 {
    font-size: var(--fs-xl);
  }
  .genres-outer {
    width: 150px;
    font-size: var(--fs-sm);
  }
}
@media screen and (max-width: 380px) {
  .genres-outer {
    width: 100%;
  }
  .preferences-buttons {
    width: 100%;
    margin: 0 auto;
  }
}
