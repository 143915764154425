.lsp-positioner-srv {
  width: 100%;
}
.services-container {
  padding-top: 150px;
}
.services-container a {
  color: var(--secondary);
  font-weight: 500;
  position: relative;
}
.services-container div p {
  /* padding-left: 40px; */
  /* font-weight: 100; */
  /* font-size: var(--fs-md); */
  color: var(--secondary);
}
.services-container table {
  width: 70%;
  text-align: center;
  margin: 0 auto;
  border-collapse: collapse;
  border-style: solid;
}
.services-container table td {
  padding: 10px;
  /* vertical-align: top; */
}
h2.cards-row-title {
  font-size: var(--fs-row-titles);
  color: var(--primary);
  font-weight: 300;
  padding: 60px 50px 0;
}
.upper-div {
  height: auto;
  max-height: none;
  opacity: 1;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
  overflow: hidden;
  color: var(--secondary);
  transition: all 0.5s ease;
}
.upper-div.folded {
  height: 0;
  max-height: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease;
}
.middle-div,
.lower-div {
  height: auto;
  max-height: none;
  /* padding-bottom: 20px; */
  width: 90%;
  opacity: 1;
  margin: 0 auto;
  color: var(--secondary);
  overflow: hidden;
  transition: all 0.5s ease;
}
.middle-div.folded,
.lower-div.folded {
  height: 0;
  max-height: 0;
  opacity: 0;
  margin-top: 0;
  transition: all 0.5s ease;
  padding: 0;
}
.middle-div h2,
.lower-div h2 {
  color: var(--secondary);
  padding: 20px 0;
  font-size: var(--fs-xl);
  font-weight: 900;
}
.upper-div a,
.middle-div a,
.lower-div a {
  color: var(--primary);
  text-decoration: underline;
  font-weight: bold;
  text-decoration-thickness: max(2px, 0.1em);
  text-underline-offset: 0.1em;
}
.collapsible-div {
  max-height: 0;
  transition: max-height 1s ease;
}

.collapsible-div.expanded {
  max-height: none;
  transition: max-height 1s ease;
}

.upper-div ul,
.middle-div ul,
.lower-div ul {
  font-size: var(--fs-md);
  padding: 40px;
  margin-left: 50px;
}

.first-row-outer {
  position: relative;
  color: var(--secondary);
  font-size: 1.4rem;
  width: 100%;
  /* text-transform: uppercase; */
  text-align: center;
  overflow: hidden;
  min-height: 270px;
  /* padding: 0 20px; */
}
.first-row-outer.collapsed {
  min-height: 105px;
  height: 105px;
  margin-bottom: 20px;
}
.first-row-outer.collapsed .service-name {
  min-height: 100%;
}
.second-row-outer {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 120px;
  /* padding: 0 20px; */
}
.first-row-items {
  width: 100%;
  cursor: pointer;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
  position: relative;
  display: block;
}
.lsp-slider-wrap .slick-slide,
.first-row-outer .slick-slide,
.second-row-outer .slick-slide {
  margin: 5px 3.5px 14px;
}
.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 40px rgb(0 0 0) inset, 0px 0px 5px 0px var(--shadow-dark);
}
.first-row-inner,
.second-row-items {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 224px;
  /* min-height: 120px;
  aspect-ratio: 1/0.77; */
  width: 100%;
  position: relative;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}

.arrow-container:hover {
  opacity: 1;
}
.arrow-container {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
}
.arrow-container svg {
  margin-bottom: -15px;
}
.first-row-items.collapsed {
  /* height: 80px; */
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}
.first-row-items.collapsed.the-one:hover {
  background: none;
  cursor: default;
}
.first-row-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.first-row-outer.collapsed .first-row-items.collapsed .first-row-inner {
  height: 78px;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}
.the-one .first-row-inner .service-name h3 {
  color: var(--primary);
}
.the-one .pointy {
  width: 35px;
  height: 15px;
  background-color: var(--primary);
  position: absolute;
  bottom: -15px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.service-name,
.sub-service-name {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--overlay-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 75px;
  padding: 5px;
  text-align: center;
  color: var(--secondary);
}
.cards-row {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0px 20px;
  transition: all 0.5s ease;
}

.second-row-items:hover {
  cursor: pointer;
  margin-bottom: 0;
}
.second-row-items.shorten {
  /* margin-bottom: -3px; */
  height: 84px;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}
.second-row-items.shorten.selected {
  border: 3px solid var(--primary);
  display: flex;
  justify-content: center;
  overflow: visible;
}
.second-row-items.shorten .sub-service-name {
  height: 100%;
}
.second-row-items.shorten.selected .sub-service-name h3 {
  color: var(--primary);
}

.hover-line {
  background-color: var(--secondary);
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: -4px;
  display: none;
}
.second-row-items:hover .hover-line {
  display: block;
}
.second-row-items.shorten.selected .hover-line {
  display: none;
}
.scroll-up-container {
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
.scroll-up-btn {
  font-size: var(--fs-lg);
  color: var(--primary);
  text-align: center;
  cursor: pointer;
  width: 100px;
  z-index: 10;
  padding: 20px 0;
  margin-bottom: 20px;
}
.events-container {
  padding-top: 150px;
}
.events-row {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
}
.lsp-positioner-evt {
  width: 100%;
}
.first-row-outer .slick-slider .slick-list {
  height: 250px;
}
.events-row .slick-slider .slick-list {
  height: 500px;
}
.services-container dl {
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.services-container dt {
  float: left;
  font-weight: bold;
  width: 40%;
  margin: 0;
  font-size: var(--fs-md);
}
.services-container dd {
  color: var(--secondary);
  font-size: var(--fs-sm);
  overflow-wrap: break-word;
  float: left;
  width: 60%;
  padding: 0 0 20px;
  margin: 0;
}
.lib-hours-list {
  padding: 20px !important;
  margin: 0 !important;
  list-style: none;
}
.channel-title {
  font-size: var(--fs-xl);
  color: var(--primary);
  text-align: center;
  font-weight: 100;
  margin-bottom: 20px;
}

@media only screen and (max-width: 960px) {
  .lsp-positioner-srv {
    top: 120px;
  }
  .services-container {
    padding-top: 240px;
  }
}
@media only screen and (max-width: 1200px) {
  .upper-div {
    width: auto;
    padding: 0 20px 50px;
  }
  .middle-div,
  .lower-div {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cards-row.events-cards-row {
    height: 540px;
  }
  /* .first-row-outer.collapsed {
    height: auto;
  } */
  .services-container table {
    width: 100%;
  }
  h2.cards-row-title {
    font-size: var(--fs-lg);
    padding: 20px 30px 0;
  }
  .middle-div h2 {
    padding: 10px 0;
  }
  .upper-div ul,
  .middle-div ul,
  .lower-div ul {
    font-size: 1.4rem;
    padding: 20px;
    margin-left: 20px;
  }
  .scroll-up {
    font-size: 1.4rem;
  }
  .first-row-inner,
  .second-row-items {
    height: 116px;
  }
  .service-name,
  .sub-service-name {
    font-size: 0.8rem;
    min-height: 60px;
    line-height: 1rem;
    font-weight: 100;
    overflow-wrap: anywhere;
    /* hyphens: auto;
    -webkit-hyphens: auto; */
  }
  .first-row-outer .slick-slider.slick-initialized,
  .second-row-outer .slick-slider.slick-initialized {
    padding-top: 0;
  }
  .arrow-container svg {
    margin-bottom: -10px;
  }
  .first-row-outer {
    min-height: 150px;
  }
}
