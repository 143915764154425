.event-card-wrap {
  min-width: 284px;
  width: 284px;
  height: 445px;
  margin: 8px;
  position: relative;
  background-color: var(--card-color);
}
.event-card-wrap:hover {
  outline-width: 3px;
  outline-color: var(--primary);
  outline-style: solid;
}
.event-image {
  width: 100%;
  height: 214px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.event-card-date,
.event-card-venue {
  color: var(--secondary);
  padding: 5px 15px;
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: flex;
}
.event-card-date p,
.event-card-venue p {
  float: left;
}
.event-card-date span {
  float: left;
  padding-left: 10px;
}
.event-titles {
  color: var(--primary);
  font-size: var(--fs-sm);
  padding: 15px;
}
.event-titles h1 {
  font-size: var(--fs-md);
}
.event-interaction-icons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  font-size: var(--fs-xl);
  color: var(--primary);
}

.event-interaction-icons div {
  margin: 10px auto;
  text-align: center;
  flex: 1;
  cursor: pointer;
}
.event-interaction-icons div:hover {
  font-size: 1.9rem;
  transition: none;
}

.event-interaction-icons a {
  color: var(--primary);
  padding: 10px;
}

.edge {
  border-right: 1px solid var(--primary);
}
.over {
  z-index: 100;
}
.event-below-icons {
  color: var(--secondary);
  position: absolute;
  bottom: -25px;
  right: 5px;
  opacity: 0.7;
}
.event-below-icons svg {
  font-size: var(--fs-md);
  margin-left: 8px;
}
.event-keywords {
  padding: 5px 15px;
  clear: left;
}
.event-keywords p {
  font-size: 0.8rem;
  display: inline-block;
  margin-right: 10px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}
.event-small-icons {
  margin-right: 6px;
  float: left;
}
.event-cat {
  background-color: var(--primary);
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: -3px;
  font-size: 0.8rem;
  box-shadow: 1px 1px 4px var(--shadow-dark);
}
