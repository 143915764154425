@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;

  /* Hide scrollbar everywhere for IE, Edge and Firefox */
  /* IE and Edge */
  /* -ms-overflow-style: none;  */
  /* Firefox */
  /* scrollbar-width: none;  */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--background-color);
  font-size: 16px;
}

.app {
  width: 100%;
  /* overflow-x: hidden; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
}

:root {
  --primary: ;
  --secondary: ;
  --orange: #e2682e;
  --background-color: #dadada;
  --footer-color: ;
  --card-color: ;
  --navbar-icons-color: ;
  --sticky-navbar-icons-color: ;
  --navbar-color: ;
  --mobile-navbar-color: ;
  --overlay-color: ;
  --success-green: limegreen;

  --left-arrow-bg: ;
  --right-arrow-bg: ;
  --promo-carousel-bg: ;
  --background-pattern: ;
  --logo-main: ;
  --logo-sticky: ;
  --book-shading: ;

  /* Toastify styles */
  --toastify-text-color-light: var(--secondary) !important;
  --toastify-text-color-dark: var(--secondary) !important;
  --toastify-color-light: var(--card-color) !important;
  --toastify-color-dark: var(--card-color) !important;

  /* Shadows */
  --shadow-dark: rgba(0, 0, 0, 0.75);
  --shadow-light: rgba(0, 0, 0, 0.3);

  /* Shadding */
  --transparent-primary: ;

  /* font sizes */
  --fs-row-titles: calc(var(--lsp-unit) * 2.6);
  --fs-xxl: calc(var(--lsp-unit) * 3);
  --fs-xl: calc(var(--lsp-unit) * 2);
  --fs-lg: calc(var(--lsp-unit) * 1.6);
  --fs-md: calc(var(--lsp-unit) * 1.2);
  --fs-sm: var(--lsp-unit);
  --fs-xs: calc(var(--lsp-unit) * 0.7);

  /* icons sizes */
  --icn-checkboxes-check: calc(var(--lsp-unit) * 1.4);
  --icn-ins-btn: calc(var(--lsp-unit) * 0.8);
  --icn-close-btn: var(--lsp-unit);
  --icn-desktop-arrows: calc(var(--lsp-unit) * 2.6);
  --icn-desktop-lg: calc(var(--lsp-unit) * 3);
  --icn-jackets-hor: calc(var(--lsp-unit) * 1.5);
  --icn-jackets-ver: calc(var(--lsp-unit) * 2);
  --icn-jackets-small-arrow: calc(var(--lsp-unit) * 1.4);
  --icn-formats: var(--lsp-unit);

  /* navbar */
  --nav-fs-sm: calc(var(--lsp-unit) * 0.7);
  --nav-fs-lg: calc(var(--lsp-unit) * 0.9);
  --nav-mob-fs: calc(var(--lsp-unit) * 1.5);
  --nav-icns: calc(var(--lsp-unit) * 2);

  /* main font variable */
  --lsp-unit: clamp(0.8rem, 1.5vw, 1rem);
}

.Toastify__close-button--light {
  color: var(--primary) !important;
  opacity: 1 !important;
}
.Toastify__close-button--dark {
  color: var(--primary) !important;
  opacity: 1 !important;
}
.Toastify__toast {
  /* border: 1px solid var(--primary) !important; */
}
a {
  text-decoration: none;
  color: var(--secondary);
}

:focus {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  z-index: 1;
}
.title-wrp {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-end;
  width: 100%;
}
.title-wrp.top-aligned {
  align-items: center;
  justify-content: space-between;
}
.lsp-skip-link {
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  text-decoration: underline;
  text-decoration-thickness: max(2px, 0.1em);
  font-size: var(--fs-md);
  line-height: var(--fs-md);
  display: inline-block;
}
.lsp-skip-link:active,
.lsp-skip-link:focus {
  padding: 10px 15px;
  color: #fff !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  white-space: inherit !important;
  background: var(--primary) !important;
}
.skip-link-div.search-results {
  position: absolute;
  top: 200px;
}
.reload-crsl-btn {
  height: 30px;
  width: 30px;
  background: transparent;
  color: var(--primary);
  margin: 0 20px;
  font-size: var(--icn-formats);
  border-radius: 50%;
}
.reload-crsl-btn:hover,
.reload-crsl-btn:focus {
  background: var(--primary);
  color: #fff;
  cursor: pointer;
  transform: scale(1.2);
}
.lsp-title-btn {
  background: var(--primary);
  border: 1px solid var(--primary);
  padding: 10px 20px;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  margin: 5px 40px 0 10px;
  font-weight: bold;
}
.lsp-title-btn:hover,
.lsp-title-btn:focus {
  cursor: pointer;
  background: var(--card-color);
  color: var(--primary);
}
button {
  background: none;
  border: none;
  cursor: pointer;
}

::placeholder {
  font-size: var(--fs-sm);
  color: #000;
  opacity: 0.5; /* Firefox */
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: #000;
  opacity: 0.5;
}

/* Microsoft Edge */
::-ms-input-placeholder {
  color: #000;
  opacity: 0.5;
}

.grecaptcha-badge {
  z-index: 9999;
}

.image-responsive {
  width: 100%;
  padding: 10px;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

/* Custom font to change the password input symbol */
@font-face {
  font-family: "fontello";
  src: url("/fonts/fontello.eot?2022");
  src: url("/fonts/fontello.eot?2022#iefix") format("embedded-opentype"),
    url("/fonts/fontello.woff2?2022") format("woff2"),
    url("/fonts/fontello.woff?2022") format("woff"),
    url("/fonts/fontello.ttf?2022") format("truetype"),
    url("/fonts/fontello.svg?2022#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media only screen and (max-width: 768px) {
  .lsp-title-btn {
    margin: 0;
    padding: 5px;
    height: unset;
  }
  .lsp-skip-link:focus {
    font-size: var(--fs-sm);
    line-height: 1rem;
    margin: 0 10px 10px;
    padding: 5px;
  }
}
@media only screen and (max-width: 1200px) {
  .title-wrp.top-aligned {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}
