@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"); /* don't remove this is important to show brand icons via old awesome */

.react-add-to-calendar {
  /* width: 40px; */
  float: left;
}
.react-add-to-calendar__wrapper {
  width: auto;
}
.react-add-to-calendar__dropdown ul {
  list-style: none;
  background-color: var(--card-color);
  top: 35px;
  left: 20px;
  padding: 20px 20px 0;
  color: var(--secondary) !important;
  position: absolute;
  border: 1px solid var(--primary);
  box-shadow: 2px 2px 4px 1px var(--shadow-dark);
  text-align: left;
  font-size: var(--fs-sm);
  border-radius: 5px;
}
.react-add-to-calendar__dropdown ul li a {
  color: var(--secondary) !important;
}
.react-add-to-calendar__dropdown ul li a:hover {
  color: var(--primary) !important;
}
.react-add-to-calendar__dropdown ul li {
  padding-bottom: 15px;
}
.react-add-to-calendar__dropdown ul li a i {
  margin-right: 7px;
}

.react-add-to-calendar__button--light {
  color: var(--primary) !important;
}
.react-add-to-calendar__button {
  padding: 10px;
  cursor: pointer;
}
