.campaignslider-wrapper {
  width: 100%;
  background-color: transparent;
  background-image: var(--promo-carousel-bg);
  position: relative;
  padding-bottom: 20px;
}
.promo-placeholder {
  min-height: 200px;
}
.promo-background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--background-color);
  width: 100%;
  height: 15%;
  z-index: 2;
  opacity: 1;
}
.promo-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  filter: blur(30px);
  -webkit-filter: blur(30px);
  z-index: -1;
}
.promo-btn-surround {
  color: var(--secondary);
  text-align: left;
  width: 100%;
}
.promo-btn-surround:hover,
.promo-link-surround:hover,
.promo-link-surround:focus {
  cursor: pointer;
  display: block;
}
.disabled-link {
  pointer-events: none;
}
.inner-promo {
  position: relative;
  color: var(--secondary);
  height: 100%;
  padding: 140px 10%;
}
.inner-promo p {
  font-size: var(--fs-md);
}
.inner-promo h1 {
  font-size: var(--fs-xxl);
}
.inner-promo h2 {
  font-size: var(--fs-lg);
  font-weight: 500;
  padding-bottom: 20px;
}
.ad-image-container {
  /* min-height: 300px; */
  width: 100%;
  aspect-ratio: 1/1.53;
}
.ad-full-height-img {
  height: 100%;
}
.ad-image {
  /* width: 100%;
  height: auto; */
  width: auto;
  max-height: 300px;
  max-width: 100%;
  margin: 15px auto;
}
.campaignslider-wrapper .slick-dots {
  bottom: 20px;
}
.carousel-control {
  cursor: pointer;
  color: var(--primary);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 70px;
  right: calc(50vw - 10px);
}

.slick-dots li button:before {
  font-size: var(--fs-sm);
  color: var(--primary);
}
.slick-dots li.slick-active button:before {
  color: var(--primary);
}
.slick-dots li button:focus {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
}
.promo-btns-wrapper {
  width: 100%;
}
.promo-btns-wrapper .promo-btn {
  width: 150px;
}
.promo-btn {
  border: 1px solid var(--primary);
  border-radius: 25px;
  padding: 2px 10px;
  font-size: var(--fs-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  cursor: pointer;
  margin: 30px auto 0;
}
.promo-btn:hover svg {
  color: #fff;
}
.promo-btn:hover,
.promo-btn:focus {
  color: #fff;
  background-color: var(--primary);
  border: 1px solid var(--secondary);
  color: #fff;
}

.promo-btn svg {
  margin-left: 20px;
  padding: 0;
  font-size: var(--fs-xl);
  color: var(--primary);
}

.promo-small-icons {
  margin-right: 6px;
  float: left;
}

.promo-features {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.promo-medium-icons {
  margin-right: 10px;
  font-size: var(--icn-jackets-hor);
  float: left;
}

.promo-keywords {
  margin-top: 10px;
}

.promo-keywords p {
  font-size: var(--fs-xs);
  display: inline-block;
  margin-right: 10px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}

.inner-promo-icons-container {
  display: flex;
  justify-content: space-between;
  width: 120px;
}
.inner-promo-icons-container button {
  background-color: transparent;
  border: none;
}

.inner-promo-icons-container svg {
  color: var(--primary);
}

.inner-promo-icons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .inner-promo {
    padding: 120px 10px 0;
  }
  .slick-dots li button:before {
    font-size: 0.5rem;
  }
  .slick-dots li {
    margin: 0;
  }
  .inner-promo p {
    font-size: var(--fs-sm);
  }
  .campaignslider-wrapper .slick-dots {
    position: unset;
  }
  .carousel-control {
    bottom: 42px;
  }
  .ad-image-container {
    max-height: 250px;
  }
}

@media only screen and (max-width: 992px) {
  .ad-image {
    width: auto;
    max-height: 350px;
  }
  .inner-promo h1 {
    font-size: var(--fs-xl);
  }
  .inner-promo h2 {
    font-size: var(--fs-md);
    padding-bottom: 20px;
  }
  .promo-btns-wrapper .promo-btn {
    margin: 25px auto;
    padding: 0px 10px;
    font-size: var(--fs-sm);
  }

  .inner-promo-btn svg {
    margin: 7px;
  }

  .promo-features {
    flex-flow: column;
  }

  .promo-features div p {
    height: auto;
    font-weight: 500;
    padding-top: 10px;
  }

  .promo-keywords p {
    height: auto;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .ad-image {
    width: auto;
    max-height: 200px;
    display: inline-flex !important;
  }
  .inner-promo h1 {
    font-size: var(--fs-xl);
  }

  .inner-promo h2 {
    font-size: var(--fs-md);
  }
  .inner-promo-left img {
    width: 70%;
  }
  .inner-promo-icons-wrapper {
    position: relative;
  }
  .inner-promo-icons-container {
    width: 70px;
    top: 20px;
  }
  .carousel-icons {
    font-size: var(--fs-lg);
    padding: 0;
    margin: 0;
  }
  .inner-promo-btn {
    width: 120px;
    border: 1px solid var(--primary);
  }
  .inner-promo-btn a {
    font-size: var(--fs-xs);
  }
  .inner-promo-btn svg {
    font-size: var(--fs-md);
    margin: 15px 5px;
  }
}
@media only screen and (max-width: 425px) {
  .carousel-control {
    font-size: var(--fs-xl);
    position: initial;
    display: block;
    margin: -5px auto -15px;
    width: 30px;
    height: 30px;
  }
}
