.lsp-slider-bar {
  position: relative;
  width: 100%;
}

.lsp-slider-wrap a,
.first-row-outer a,
.second-row-outer a {
  display: block;
}

.lsp-slider-wrap {
  /* display: flex;
  justify-content: space-between; */
  padding-left: 7px;
  color: var(--secondary);
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.lib-serv-bar .slick-slide a,
.lib-evn-bar .slick-slide a {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 84px;
  padding: 10px 5px 25px;
}

.lsp-links p,
.service-name p,
.sub-service-name p {
  font-size: var(--fs-sm);
  line-height: var(--fs-md);
  font-weight: bold;
}
li.lsp-links {
  height: 80px;
}
.tabs-loading {
  position: absolute;
  bottom: 8px;
  left: calc((100% - 36px) / 2);
  opacity: 0.6;
}
.subtabs-loading {
  position: absolute;
  bottom: 8px;
  left: calc((100% - 36px) / 2);
  opacity: 0.6;
}
.lsp-links.selected {
  color: var(--primary);
  border: 3px solid var(--primary);
  height: 80px;
  padding: 10px 0;
  /* line-height: 1.4rem; */
  background-color: var(--background-color);
}

.selected a {
  color: var(--primary);
}

.lsp-links.selected:hover {
  /* background-image: none;
  cursor: default; */
}

.lsp-links:hover {
  cursor: pointer;
}
.arrow-outer {
  display: flex;
  align-items: flex-end;
  opacity: 0;
  height: 84px;
  width: 100%;
  position: absolute;
  top: 0;
}
.arrow-outer:hover {
  opacity: 1;
}
.lsp-leftbtn-containter.ad-carousel-lft {
  top: 0;
  left: 0;
  background: var(--left-arrow-bg);
  height: 100%;
  width: 15%;
}
.lsp-rightbtn-containter.ad-carousel-rht {
  top: 0;
  right: 0;
  background: var(--right-arrow-bg);
  height: 100%;
  width: 15%;
}
.lsp-leftbtn-containter.ad-carousel-lft .carousel-left {
  opacity: 1;
  left: auto;
  right: 0;
}
.lsp-rightbtn-containter.ad-carousel-rht .carousel-right {
  opacity: 1;
  right: auto;
  left: 0;
}
.lsp-leftbtn-containter {
  background: var(--left-arrow-bg);
  width: 10%;
  height: 100%;
  position: absolute;
  left: -2px;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: var(--icn-desktop-lg);
}
.lsp-leftbtn-containter.hidden,
.lsp-rightbtn-containter.hidden {
  display: none;
}
.lsp-leftbtn-containter:hover:not([disabled]) .carousel-left,
.lsp-rightbtn-containter:hover:not([disabled]) .carousel-right {
  opacity: 1;
  transform: scale(1.2);
}
.lsp-leftbtn-containter:hover,
.lsp-rightbtn-containter:hover {
  cursor: pointer;
}

.lsp-rightbtn-containter {
  background: var(--right-arrow-bg);
  width: 10%;
  height: 100%;
  position: absolute;
  right: -1px;
  /* top: 0; */
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: var(--icn-desktop-lg);
}
.lsp-leftbtn-containter:focus,
.lsp-rightbtn-containter:focus {
  outline-offset: -3px;
}

button:disabled .carousel-left,
button:disabled .carousel-right {
  color: var(--secondary);
  cursor: default;
}
.carousel-left,
.carousel-right {
  position: absolute;
  height: 100%;
  color: var(--primary);
  z-index: 5;
  cursor: pointer;
  display: block;
  opacity: 0.5;
}
.carousel-right {
  right: 10px;
}
.carousel-left {
  left: 10px;
}
.selected .pointy {
  width: 35px;
  height: 15px;
  background-color: var(--primary);
  position: absolute;
  bottom: -15px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

@media only screen and (max-width: 768px) {
  .arrow-outer {
    height: 80px;
  }
  .lsp-links p,
  .service-name p,
  .sub-service-name p {
    font-size: var(--nav-fs-lg);
    line-height: 0.9rem;
  }
  .lib-serv-bar .slick-slide a,
  .lib-evn-bar .slick-slide a {
    align-items: center;
    padding: 10px 0;
  }
  .selected {
    height: auto;
    line-height: normal;
  }
  .lsp-links:hover {
    background-image: none;
  }
  .tabs-loading {
    position: absolute;
    bottom: 8px;
    left: calc((100% - 24px) / 2);
    opacity: 0.6;
  }
}
@media only screen and (max-width: 992px) {
  .lsp-leftbtn-containter,
  .lsp-rightbtn-containter {
    /* width: 10%; */
  }
}
