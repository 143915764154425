.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.85); */
  background-color: var(--background-color);
  opacity: 0.85;
  z-index: 110;
}

.modal {
  width: 60%;
  background: var(--card-color);
  color: var(--secondary);
  position: fixed;
  top: 25vh;
  left: calc((100% - 60%) / 2);
  z-index: 120;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  overflow: hidden;
}
.avatars-modal {
  top: calc((100vh - 680px) / 2);
}
.modal-title {
  padding: 10px;
}
.modal-msg {
  font-size: 1.2rem;
  color: var(--secondary);
}
.modal-header {
  padding: 1rem;
  border-bottom: 2px solid var(--primary);
  text-align: center;
}
.modal-header h1 {
  font-weight: 100;
}
.modal-header h2 {
  font-weight: 100;
  text-transform: uppercase;
}
.modal-content {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}
.modal-line {
  color: var(--secondary);
  padding-bottom: 15px;
}
.modal-line button {
  color: var(--primary);
  text-decoration: underline;
  font-size: var(--fs-sm);
  font-weight: bold;
}
.modal-actions {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.close-modal {
  margin: 10px 15px;
  float: right;
  cursor: pointer;
  color: var(--primary);
}

.login-modal {
  border: 2px solid var(--orange);
}

.library-login-modal {
  border: 2px solid var(--primary);
  background: var(--card-color);
  position: fixed;
  top: 20vh;
  left: calc((100% - 750px) / 2);
  z-index: 140;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  min-height: auto;
  width: 750px;
}
.btn-sml {
  position: relative;
  padding: 20px;
  font-size: var(--fs-xl);
  border: 1px solid var(--secondary);
  border-radius: 50px;
  background-color: transparent;
  color: var(--secondary);
  width: 48%;
  cursor: pointer;
  margin-bottom: 20px;
}
.btn-sml:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--primary);
  border: 1px solid var(--primary);
}
.close-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
}

.book-modal {
  background: var(--card-color);
  position: fixed;
  top: calc((100vh - 540px) / 2);
  left: calc((100% - 750px) / 2);
  z-index: 120;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  height: 540px;
  width: 750px;
}

.book-modal .desktop-card-container {
  max-width: 750px;
  margin: 0;
}

.event-modal {
  top: calc((100vh - 540px) / 2);
  left: calc((100% - 750px) / 2);
  height: 540px;
  width: 750px;
  overflow: hidden;
  box-shadow: 0 0 10px 0px var(--primary), 0 0 30px var(--primary);
}

.event-modal-close {
  color: var(--primary);
  top: 0;
  right: 0;
  margin: 5px 10px;
  font-size: 3rem;
}

.event-modal-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.event-modal-titles {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 20px;
  background-color: var(--overlay-color);
  width: 100%;
}

.event-modal-info {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-weight: 100;
}
.event-modal-tickets {
  position: absolute;
  top: 18px;
  width: 100%;
  margin: 0 auto;
  font-size: var(--fs-sm);
}
.event-modal-tickets.full-text {
  top: 14px;
  font-size: 0.7rem;
  opacity: 0.5;
  right: 1px;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.blog-modal-info {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  font-size: var(--fs-md);
  font-weight: 100;
}

.event-modal-content {
  position: relative;
  height: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}

.event-modal-content a {
  color: var(--primary);
}

.event-modal-titles h1 {
  color: var(--primary);
  font-size: var(--fs-xl);
}

.event-modal-titles h2 {
  color: var(--primary);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.event-modal-keywords {
  padding: 5px 15px;
  max-width: 75%;
}

.event-modal-keywords p {
  font-size: 0.8rem;
  display: inline-block;
  margin-right: 10px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}

.event-modal-icons {
  color: var(--secondary);
  position: absolute;
  top: 10px;
  right: -10px;
  margin-right: 20px;
  height: 25px;
  /* max-width: 25%; */
}

.event-modal-icons svg {
  font-size: 1.4rem;
  margin-left: 8px;
}

.event-modal-text {
  max-height: 65%;
  margin: 0 80px 40px 20px;
  padding-right: 20px;
  overflow-y: auto;
  min-height: 150px;
}

.event-modal-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 4px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.event-modal-text::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.event-modal-text::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--primary);
}

.event-modal-text p {
  font-size: var(--fs-md);
  font-weight: 100;
}

.event-modal-inter-icons {
  width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -1px;
  right: -1px;
}

.event-modal-inter-icons a {
  flex: 1;
  min-width: 50px;
  min-height: 50px;
  /* border: 1px solid var(--primary); */
  text-align: center;
  cursor: pointer;
  padding: 5px 0;
}

.event-modal-inter-icons a svg {
  color: var(--primary);
  font-size: var(--fs-xl);
  text-align: center;
}

.event-modal-inter-icons a svg {
  margin: 6px;
}
.video-title {
  padding: 20px;
  color: var(--secondary);
}
.blog-modal-inter-icons {
  max-width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 18px;
  right: -1px;
}

.blog-modal-inter-icons a {
  flex: 1;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  cursor: pointer;
  padding: 5px 0;
  /* border: 1px solid var(--primary); */
}

.blog-modal-inter-icons a svg {
  color: var(--primary);
  font-size: 2.5rem;
  margin: 6px;
  text-align: center;
}

.blog-modal-date {
  color: #fff;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.contact-modal,
.video-modal {
  background: var(--card-color);
  border: 2px solid var(--primary);
  position: fixed;
  top: 20vh;
  left: calc((100% - 750px) / 2);
  z-index: 1500;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  /* min-height: 600px; */
  width: 750px;
}
.contact-form-outer {
  display: flex;
  width: 100%;
  /* padding-top: 30px; */
}
.contact-form-left,
.contact-form-right {
  flex: 1;
}
.contact-form-outer div {
  text-align: center;
}
.contact-form-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
}
.login-dialogue-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.contact-btn {
  padding: 10px;
  font-size: var(--fs-sm);
  border: 1px solid var(--primary);
  border-radius: 50px;
  color: #fff;
  background: var(--primary);
  min-width: 0;
  max-width: none;
  width: 30%;
  margin: 0 20px;
  position: relative;
}

.contact-btn:hover:not([disabled]),
.contact-btn:focus {
  background: var(--background-color);
  cursor: pointer;
  color: var(--primary);
}
.contact-btn:hover:not([disabled]) svg,
.contact-btn:focus svg {
  color: var(--primary);
}

.contact-btn svg {
  color: #fff;
  margin: 3px 0 0;
  position: absolute;
  right: 10px;
  /* font-size: var(--fs-xl); */
}

.contact-btn:disabled,
.contact-btn:disabled svg {
  color: var(--primary);
  background: var(--secondary);
  border-color: var(--secondary);
  cursor: unset;
  opacity: 0.4;
}

.contact-btn.small {
  font-size: 0.9rem;
  width: 40%;
}

.renewall-wrp {
  padding: 50px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  justify-content: space-between;
  color: var(--secondary);
}
.renewall-list {
  list-style: none;
  color: var(--secondary);
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  width: 90%;
  margin: 0 auto 30px;
  padding: 30px;
  /* background: var(--background-color); */
}
.renewall-list li {
  border-bottom: 1px dashed var(--primary);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  margin: 5px;
}
.renewall-list li p {
  font-size: var(--fs-md);
}
.renewall-list li p span {
  font-size: 0.8rem;
}
.renewal-cover {
  width: auto;
  height: 100px;
  display: block;
  margin: 0 auto;
}
.renewal-cover-overlay {
  height: 100%;
  width: 100%;
  background-color: var(--overlay-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.loan-cover-wrp {
  position: relative;
}
.success-check {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 3rem;
  color: var(--success-green);
  z-index: 5;
}
.checkbox-wrapper.checkall-wrapper {
  margin-left: 65px;
}
.checkbox-wrapper.checkall-wrapper label {
  color: var(--primary);
}
.renew-waiting {
  top: 55px;
  position: absolute;
  left: calc((100% - 36px) / 2);
  z-index: 3;
}
.login-waiting {
  bottom: 12px;
  position: absolute;
  left: calc((100% - 36px) / 2);
  opacity: 1;
  z-index: 10;
}
.contact-error-msg {
  color: var(--primary);
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 10%;
}

.contact-input-field {
  padding: 10px 15px;
  font-size: var(--fs-sm);
  width: 90%;
  margin: 10px auto;
  border-radius: 50px;
  border: none;
  font-weight: 100;
  border: 3px solid var(--card-color);
  box-shadow: inset 0px 0px 4px 0px var(--secondary);
}

.contact-input-field:disabled {
  background-color: #fff;
  color: #8a8a8a;
  box-shadow: inset 0px 0px 4px 0px var(--shadow-light);
}

.message-area {
  border-radius: 25px;
}

.contact-input-label {
  color: var(--secondary);
  font-weight: normal;
  font-size: var(--fs-sm);
  float: left;
  padding-left: 45px;
  margin-bottom: -10px;
  font-weight: 500;
}

.contact-form-input {
  position: relative;
  padding: 10px 0;
}

.contact-form-input svg {
  color: var(--primary);
  position: absolute;
  top: 42px;
  right: 30px;
  font-size: var(--fs-md);
}

.ms-book-outer {
  display: flex;
  flex-flow: column wrap;
  position: relative;
  height: 100%;
}
.ms-book-bookcover {
  display: block;
  height: 270px;
  width: 175px;
  margin: 0 auto;
}
.ms-book-bookcover img {
  display: block;
  width: 100%;
  height: 100%;
  /* height: 160px; */
}
.ms-stars-inner-wrap {
  height: auto;
  width: 90%;
  top: 0;
  left: 5%;
  font-weight: 100;
  color: #fff;
  font-size: var(--fs-sm);
  display: inline-block;
  position: absolute;
  background-color: var(--primary);
  height: auto;
  text-align: center;
  box-shadow: 3px 3px 4px 0px var(--shadow-dark);
}
.ms-book-content {
  width: 100%;
  height: 35vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--secondary);
  padding: 0 20px 50px;
  font-size: var(--fs-md);
  font-weight: 100;
  margin-top: 10px;
}
.ms-inter-icons-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--card-color);
}
.ms-inter-icons-container .inter-icons-inner {
  flex: 1;
  min-width: calc(90vw / 6);
  min-height: calc(90vw / 6);
  border: none !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* padding: 7px 0; */
}
.ms-inter-icons-container .inter-icons-inner svg {
  font-size: var(--icn-jackets-small-arrow) !important;
}
.image-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.ms-horizontal-format-icons {
  margin-top: 10px;
  max-height: 70px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  width: 90vw;
  left: 0;
  background-color: var(--card-color);
}
.ms-horizontal-format-icons div {
  text-align: center;
  cursor: pointer;
  margin: 0 8px;
}
.ms-horizontal-format-icons div p {
  color: var(--primary);
  font-weight: 100;
}
/* ---------------------------  the flipper inside  ------------------------- */
.ms-jacket {
  width: 100%;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
  z-index: 100;
  position: relative;
}
.ms-back {
  background-color: var(--card-color);
  padding: 10px;
  font-size: 12px;
  color: var(--secondary);
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ms-back b {
  margin-top: 15px;
}
.ms-front {
  position: absolute;
  top: 0;
}
.ms-jacket.ms-back div::after {
  content: "";
  background-color: var(--background-color);
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
/* ---------------------------  end of the flipper inside  ---------------------------- */

/* start css from Signup.css file copied */
.inputs-container {
  width: 50%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
}
.form-add-info {
  color: var(--secondary);
  font-size: var(--fs-xs);
}
.input-field {
  padding-left: 20px;
  font-size: 1.4rem;
  width: 100%;
  margin: 5px 0;
  border-radius: 50px;
  border: none;
  border: 3px solid var(--card-color);
  box-shadow: inset 0px 0px 4px 0px var(--secondary);
  min-height: 75px;
}
.input-field.narrow {
  min-height: 40px;
  font-size: var(--fs-sm);
}
h2.form-title {
  color: var(--primary);
  font-size: var(--fs-xl);
  font-weight: 100;
  text-align: center;
  padding-block: 30px;
}

h3.form-title {
  color: var(--orange);
  font-weight: 100;
  font-size: var(--fs-xl);
  margin-bottom: 20px;
}

.input-label {
  color: var(--secondary);
  font-weight: 100;
  font-size: var(--fs-md);
  float: left; /* TEST for library login dialog */
}

.form-buttons {
  margin: 20px auto;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.next-btn {
  width: 100%;
  position: relative;
  padding: 20px;
  font-size: var(--fs-lg);
  border: 1px solid var(--secondary);
  border-radius: 50px;
  background-color: transparent;
  color: var(--secondary);
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
}
.next-btn:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--primary);
  border: 1px solid var(--primary);
}
.next-btn svg {
  color: var(--secondary);
}
.next-btn:hover svg {
  color: var(--primary);
}

.select-container {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
}
.select-container.half {
  width: 45%;
}
.signup-form .input-label {
  font-size: 1rem;
}
.signup-form .input-field {
  margin: 0;
}
.signup-form .input-field:focus {
  outline-offset: 0;
}
.signup-form .error-msg {
  margin: 0;
  padding: 0 0 10px 0;
  font-size: var(--fs-xs);
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.short-container {
  display: flex;
  justify-content: space-between;
}

.short {
  width: 60%;
  position: relative;
  z-index: 10;
}

.signup-form .arrow-icon {
  color: var(--primary);
  position: absolute;
  top: 27px;
  right: 10px;
  font-size: var(--nav-mob-fs);
}
.arrow-icon {
  color: grey;
  position: absolute;
  top: 55px;
  right: 30px;
  font-size: 2.5rem;
}

.next-icon {
  color: inherit;
  position: absolute;
  top: 20px;
  right: 30px;
  /* font-size: 2.5rem; */
}

.prev-icon {
  color: inherit;
  position: absolute;
  top: 20px;
  left: 30px;
  /* font-size: 2.5rem; */
}

.error-msg {
  color: red;
  padding: 0 25px;
  width: 100%;
  text-align: left !important;
  font-weight: bold;
}

.spacer {
  margin: 30px 0;
}

.dialogue-message {
  color: var(--secondary);
  font-size: var(--fs-md);
  padding: 40px;
}

.dialogue-message.centered {
  text-align: center;
}

.dialogue-message button {
  color: var(--primary);
  font-size: var(--fs-md);
  font-weight: bold;
  text-decoration: underline;
}

.signout-modal {
  background: var(--card-color);
  position: fixed;
  top: 20vh;
  left: calc((100% - 750px) / 2);
  z-index: 1500;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  width: 750px;
  height: auto;
}

/* To remove the small arrows inside numeric input -------- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* -------------------------------------------------------- */

.show-password {
  position: absolute;
  right: 40px;
  top: 55px;
  color: #000;
  opacity: 0.6;
}

/* hide the eye element to reveal passwords on MS Edge to add our own icon */
::-ms-reveal {
  display: none;
}

/* Use the css below to change the password input symbol */
input[type="password"] {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  letter-spacing: 8px;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.date-range {
  text-align: center;
}

.date-range input {
  margin-top: 5px;
  padding: 5px;
  text-align: center;
  border: 1px solid var(--primary);
  border-radius: 3px;
  box-shadow: 0px 1px 3px inset var(--shadow-dark);
}

.current-avatar {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.current-user-avatar {
  width: 80px !important;
  height: auto;
  margin: 10px;
}
.avatars-container {
  display: flex;
  max-height: 220px;
  width: 100%;
  align-items: flex-start;
  overflow-y: auto;
  flex-flow: row wrap;
  justify-content: center;
}
.avatar-inner {
  width: 100px;
  height: 100px;
  margin: 5px;
  border-radius: 50%;
}
.avatar-inner:hover,
.avatar-inner:focus,
.avatar-inner.avatar-chosen {
  outline-color: var(--primary);
  outline-width: 4px;
  outline-offset: 0;
  outline-style: dashed;
}
.avatar-image {
  width: 100px;
  height: auto;
  margin: 10px;
}
.avatar-text {
  padding: 20px 0;
  font-size: var(--fs-md);
}
.middle-arrow {
  color: var(--primary);
  width: 30px !important;
  height: auto;
  align-self: center;
}
@media screen and (max-width: 1200px) {
  .inputs-container {
    width: 80%;
    padding: 100px 20px 20px;
    display: block;
  }
  .next-btn {
    font-size: var(--fs-sm);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .next-btn svg {
    margin: 0px -10px 0px;
  }
}

@media screen and (max-width: 600px) {
  .inputs-container {
    width: 100%;
  }

  .form-buttons {
    width: 100%;
  }

  .input-field {
    font-size: var(--fs-lg);
  }

  .short-container {
    flex-flow: column-reverse;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .modal {
    width: 90vw;
    left: 5vw;
  }
  .ms-book-bookcover {
    top: -20px;
    position: relative;
  }
  .modal.ms-book-modal {
    /* border: 2px solid var(--primary); */
    height: 90vh;
    top: 5vh;
    box-shadow: none;
    border-radius: 10px 10px 0 0;
  }
  .short {
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .record-info.small ul li {
    margin-top: 10px;
  }
  .modal {
    width: 90%;
    left: calc((100% - 90%) / 2);
    overflow: visible;
  }
  .login-modal {
    top: 100px;
  }
  .modal-actions {
    flex-direction: column-reverse;
    padding: 0 2rem;
  }
  .wide-btn svg {
    font-size: 1rem !important;
  }
  .event-modal {
    /* border: 2px solid var(--primary); */
    height: 90vh;
    top: 5vh;
  }

  .event-modal-text p {
    font-size: var(--fs-md);
  }

  .blog-modal-info,
  .event-modal-info {
    flex-flow: column;
    padding: 0;
  }
  .flex-1 {
    width: 100%;
    padding: 10px 20px;
  }
  .react-add-to-calendar__button {
    padding: 0 5px 0 0 !important;
  }
  .event-modal-icons {
    top: 60px;
  }

  .event-modal-icons svg {
    font-size: var(--fs-sm);
  }

  .blog-modal-info div {
    padding: 5px 0;
  }

  .blog-modal-inter-icons a svg,
  .event-modal-inter-icons a svg {
    font-size: 1.5rem;
  }

  .blog-modal-inter-icons a,
  .event-modal-inter-icons a {
    min-width: 50px;
    min-height: 50px;
  }

  .signout-modal {
    left: 5vw !important;
    width: 90vw !important;
    top: calc(100vh / 4) !important;
    box-shadow: 0 0 5px var(--primary), 0 0 10px var(--primary);
  }

  .dialogue-message {
    font-size: 1.4rem !important;
    padding: 20px !important;
  }

  .contact-modal,
  .library-login-modal {
    top: 5vw;
    height: 80vh;
    left: 5vw;
    width: 90vw;
    box-shadow: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
  }
  .contact-input-field,
  .input-field {
    padding: 5px 10px;
    font-size: var(--fs-md);
  }
  .contact-input-field {
    padding: 5px 10px;
    font-size: var(--fs-md);
  }
  .checkbox-outer label {
    justify-content: center;
  }
  .contact-form-outer {
    flex-flow: column;
  }
  .login-form {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    height: 90%;
  }
  .contact-form-buttons,
  .login-dialogue-buttons {
    flex-flow: column;
    padding-top: 0;
    /* position: absolute;
    bottom: 0;
    left: 0; */
    /* background: var(--card-color); */
  }
  .login-form .contact-form-buttons {
    padding: 20px 0 20px;
  }
  .arrow-icon {
    top: 48px;
    right: 16px;
  }
  .contact-input-field,
  .message-area {
    border-radius: 20px;
  }
  button.contact-btn {
    width: 100%;
    text-align: center;
    margin: 10px 0px;
  }
  .btn-sml {
    width: 100%;
  }

  .event-modal-tickets {
    top: 18px;
    font-size: 0.8rem;
  }
  .event-modal-tickets.full-text {
    top: 22px;
    font-size: 0.4rem;
    opacity: 0.5;
  }
  .checkbox-wrapper.checkall-wrapper {
    margin-left: 15px;
  }
  .renewall-wrp {
    padding: 10px;
  }
  .renewall-list {
    width: 100%;
    margin: 0;
    padding: 10px;
    max-height: unset;
  }
  .close-modal {
    position: absolute;
    right: 0;
  }
  .renewal-cover {
    margin: 0 5px 10px;
  }
  .contact-btn.small {
    width: 100%;
  }
  .renew-waiting {
    right: 20px;
    left: unset;
  }
  .video-title {
    font-size: var(--fs-sm);
    padding: 10px;
  }
  video {
    height: 85%;
    position: absolute;
    bottom: 0;
  }

  .video-modal {
    top: 5vw;
    left: 5vw;
    width: 90vw;
    box-shadow: none;
    margin: 0;
    padding: 0;
    min-height: 60vw;
  }
  .contact-form-input svg {
    top: 40px;
    right: 40px;
  }
}
@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  video {
    height: 25%;
    position: relative;
    top: 0;
  }
  .video-modal {
    top: 5vw;
    left: 5vw;
    /* width: 90vw; */
    max-height: 50vw;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 992px) and (orientation: landscape) {
}
@media only screen and (max-width: 360px) {
  .ms-book-bookcover {
    height: 200px;
    width: 130px;
  }
}
