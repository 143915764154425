.wcard-outer-container {
  min-height: calc(70vh - 600px);
}

.wcard-container {
  width: 70vw;
  margin: 15% auto 0;
  color: var(--secondary);
}

.wcard-screen-title {
  font-size: var(--fs-row-titles);
  color: var(--primary);
  /* padding: 60px 50px 0; */
}

.wcard-message {
  color: var(--secondary);
  font-size: var(--fs-xl);
  padding: 30px 0;
  font-weight: 100;
}

.wcard-cards-row {
  display: flex;
  flex-flow: row wrap;
  margin: 0px 20px;
  transition: all 1s ease;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 992px) {
  .wcard-outer-container {
    padding-top: 100px;
  }
  .wcard-container {
    width: 90vw;
    margin: 5% auto 0;
  }

  .wcard-cards-row {
    justify-content: center;
  }

  .wcard-screen-title {
    font-size: var(--fs-lg);
  }
  .wcard-message {
    font-size: var(--fs-sm);
  }
}
