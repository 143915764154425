.card-container {
  /* min-width: 290px;
  height: 445px; */
  margin: 10px 2.5px 40px;
  display: flex;
  flex-direction: column;
  /* border: 3px solid transparent; */
  position: relative;
  /* outline: none; */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  /* background-color: var(--card-color); */
  box-shadow: 1px 2px 5px 0px var(--shadow-dark);
}
.card-container:focus,
.mini-outer-container:focus {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  z-index: 20;
}
.card-container:focus {
  outline: 4px solid var(--primary);
  outline-offset: 2px;
}
.mini-outer-container:focus {
  outline: 3px solid var(--primary);
  outline-offset: 1px;
}
.hidden-info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.desktop-card-container {
  display: flex;
  position: relative;
  background-color: var(--card-color);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  /* overflow: hidden; */
  cursor: auto;
  min-width: 750px;
  height: 540px;
  /* border: 3px solid var(--primary); */
  margin: -40px -20px -50px 0;
  z-index: 15;
  /* box-shadow: 0 0 30px var(--primary), 0 0 50px var(--primary); */
}
.mobile-card-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 15;
}

.outer-left-container img {
  /* width: 290px; */
  height: 100%;
}

.outer-right-container {
  height: 100%;
  width: 400px;
  position: relative;
  overflow: hidden;
}
.mobile-right-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  overflow: hidden;
}
.outer-left-container {
  min-height: 100%;
}

.active-outer-left-container {
  display: none;
}

.closecard-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
  color: var(--primary);
  min-width: auto;
  font-size: var(--fs-sm);
  margin: 10px;
  z-index: 999;
}

.closecard-icon {
  color: var(--primary);
}

.info-container {
  max-width: 320px;
  height: 75%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 30px;
  right: 70px;
  color: var(--secondary);
  padding: 20px;
  font-size: var(--fs-md);
  font-weight: 100;
  overscroll-behavior: contain;
}
.info-container::-webkit-scrollbar-track,
.chcp-wrp::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 5px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.info-container::-webkit-scrollbar,
.chcp-wrp::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.info-container::-webkit-scrollbar-thumb,
.chcp-wrp::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 10px var(--shadow-light);
  background-color: var(--primary);
}
.inter-icons-container {
  min-width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 110px;
  right: -1px;
}
.inter-icons-inner {
  flex: 1;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  padding: 5px 0;
}
.inter-icons-inner:disabled svg {
  opacity: 0.2;
  color: var(--secondary);
}
.inter-icons-inner:hover:not([disabled]) {
  cursor: pointer;
}
.inter-icons-inner:not([disabled]) svg:hover {
  transform: scale(1.2);
}
.inter-icons-inner svg {
  font-size: var(--fs-xl);
  text-align: center;
  color: var(--primary);
}
.inter-arrow {
  font-size: var(--icn-jackets-small-arrow) !important;
}
.inter-icons {
  font-size: var(--icn-jackets-hor);
  margin: 0 5px 2px;
  text-align: center;
}
.inter-icon-wrp {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inter-icon-wrp.fade {
  opacity: 0.1;
}
.horizontal-format-icons.opened .hor-icon.selected-icon .inter-icons,
.horizontal-format-icons.opened .hor-icon.selected-icon .inter-name {
  color: var(--primary);
}
.horizontal-format-icons {
  width: 90%;
  /* height: 80px; */
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: -2px;
  right: 5%;
  background: var(--card-color);
  border-radius: 7px 7px 0 0;
  /* border-radius: 15px 15px 0 0; */
  -webkit-transition: height 0.2s ease-out;
  -moz-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
  border: 1px solid var(--primary);
  border-bottom: none;
  box-shadow: 0px 0px 4px var(--shadow-light);
}
.horizontal-format-icons.opened {
  height: 410px !important;
  box-shadow: 0px -1px 7px var(--shadow-dark);
}
.mobile-right-container .info-container {
  position: relative;
  top: 260px;
  right: auto;
  max-width: none;
  margin-right: 70px;
  height: calc(100% - 370px);
  font-size: var(--fs-md);
}
.mobile-right-container .horizontal-format-icons {
  position: relative;
  right: auto;
  bottom: 0;
  width: 90%;
  z-index: 100;
}
.dimmed-before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.dimmed {
  opacity: 0.1;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.info-container p {
  padding-bottom: 70px;
}
.details-panel {
  flex: none;
  width: 90%;
  /* display: none; */
  position: absolute;
  top: 20px;
  left: 5%;
}
.close-btn-small {
  color: var(--primary);
  font-size: var(--icn-close-btn);
  flex: none;
  position: absolute;
  right: 7px;
  top: 0;
  cursor: pointer;
}
p.small-title {
  color: var(--primary);
  font-size: var(--fs-sm);
  position: absolute;
  top: -20px;
}
.hor-icon {
  flex: 1;
  text-align: center;
  margin-bottom: 10px;
  color: var(--secondary);
  opacity: 0.2;
}
.hor-icon.selected-icon {
  color: var(--primary);
  opacity: 1;
}
.hor-icon:hover:not([disabled]) {
  transform: scale(1.05);
  cursor: pointer;
}
.inter-name {
  line-height: 1rem;
  font-size: var(--fs-xs);
}
.inter-name.bold {
  font-weight: bold;
}
.details-panel-text {
  color: var(--secondary);
  font-size: var(--fs-md);
  padding-bottom: 10px;
  text-align: center;
}
.details-panel-text button {
  color: var(--primary);
  text-decoration: underline;
  font-weight: bold;
  font-size: var(--fs-md);
}
.details-panel-text button:hover {
  cursor: pointer;
}
.book-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: var(--book-shading);
}

.loans-info-div {
  width: 100%;
  height: 50%;
  position: absolute;
  font-weight: 100;
  top: 0;
  right: 0;
  color: var(--secondary);
  padding: 30px;
  font-size: var(--fs-sm);
}
.loans-info-div ul li {
  list-style: none;
  padding-bottom: 10px;
}
.loans-info-div ul li p {
  padding-left: 50px;
  max-height: 40px;
  overflow-y: scroll;
}
.loans-info-title {
  font-size: 1.5rem;
  color: var(--primary);
  text-align: center;
  padding-bottom: 30px;
}
.mobile-right-container .loans-info-div ul li {
  padding-bottom: 20px;
}
.mobile-right-container .loans-icons-div {
  padding: 5px;
}
.mobile-right-container .loans-icon {
  width: clamp(50px, 18vw, 80px);
  height: clamp(50px, 18vw, 80px);
}
.loans-icons-div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 10px 100px 10px 40px;
  position: absolute;
  bottom: 10px;
  background: var(--card-color);
}
.loans-icons-div button,
.loans-icons-div a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}
.loans-icons-div p {
  font-size: 0.9rem;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
}
.loans-icons-div svg {
  font-size: var(--fs-xl);
  margin-bottom: 10px;
}
.loans-icon {
  text-align: center;
  border: 1px solid var(--primary);
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  width: 80px;
  height: 80px;
}
.loans-icon:hover,
.loans-icon:focus {
  background: var(--primary);
  color: #fff;
}
.loans-icon.active {
  background: var(--primary);
  color: #fff;
  cursor: unset;
}
.reservation-icon {
  border: 1px solid var(--primary);
  padding: 10px;
  cursor: pointer;
  color: var(--primary);
  width: 70%;
  height: 50px;
  margin: 0 auto;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-sm);
  font-weight: bold;
}
.reservation-icon svg {
  color: var(--primary);
  font-size: var(--fs-xl);
  margin: 0.5rem;
}

.loan-format {
  width: 55px;
  margin: 0 auto;
}
.warning-orange {
  color: orange !important;
}
.warning-red {
  color: rgb(200, 55, 55) !important;
}
#pickupLocation {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: #fff;
  border: 1px solid var(--primary);
  color: #000;
  overflow: hidden;
  width: 100%;
  font-size: 0.8rem;
  padding: 5px;
  box-shadow: 0px 1px 3px inset var(--shadow-dark);
}
.pickuplocation-div {
  width: 100%;
  padding: 10px 0;
}
.pickuplocation-text {
  padding-top: 10px;
  color: var(--secondary);
}
.copies-list {
  padding: 5px 0 20px 20px;
  color: var(--secondary);
  list-style: none;
  font-size: 0.8rem;
  overflow-y: auto;
  height: 100%;
}
.copies-list li {
  padding: 10px 0;
  padding-left: 15px;
  position: relative;
  border-bottom: 1px dashed var(--primary);
  font-weight: bold;
}
.copies-list li p {
  font-weight: 100;
}
.copies-list li span {
  opacity: 0.4;
}
.copies-list li span.available {
  color: #1fa21f;
  opacity: 1;
  font-weight: bold;
}
.copies-div {
  overflow: hidden;
  padding: 0 10px;
  margin: 0 10px;
  height: 200px;
  position: relative;
}
.copies-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 5px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.copies-list::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.copies-list::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 10px var(--shadow-light);
  background-color: var(--primary);
}
.copies-overlay::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    var(--card-color) 100%
  );
  z-index: 1;
}
.details-inner {
  /* padding: 10px; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.details-panel-button {
  font-weight: bold;
  color: var(--primary);
  padding: 5px;
  cursor: pointer;
}
.copies-count {
  color: var(--primary);
  opacity: 0.4;
  position: absolute;
  left: -20px;
  font-size: 1.5rem;
  font-weight: 900;
  width: 30px;
  text-align: right;
}
.details-panel-msg {
  width: 100%;
  text-align: center;
  /* line-height: 8rem; */
}
.wide-btn {
  width: 60%;
  color: var(--primary);
  background-color: var(--background-color);
  display: flex;
  margin: 20px auto;
  padding: 10px;
  align-items: center;
  justify-content: space-around;
  border: 1px solid;
  /* border-radius: 30px; */
  z-index: 1;
}
.wide-btn:hover:not([disabled]) {
  color: var(--background-color);
  border-color: var(--background-color);
  background-color: var(--primary);
  cursor: pointer;
}
.wide-btn svg {
  float: right;
  width: 10%;
}
.wide-btn p {
  text-align: center;
  width: 100%;
  font-size: var(--fs-sm);
}
.placeholder-container {
  position: relative;
  color: var(--secondary);
  outline: 1px solid var(--secondary);
  background-color: var(--card-color);
  width: 100%;
  height: 100%;
  /* width: 290px;
  height: 445px; */
  margin: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.3;
  /* overflow-wrap: break-word; */
  padding: 15px;
  hyphens: auto;
}
.placeholder-container p {
  /* font-weight: 100; */
  font-size: var(--fs-sm);
  width: 100%;
}

.panel-tab.selected button {
  color: var(--primary);
}
.panel-tab button {
  color: var(--secondary);
  font-size: var(--fs-md);
  cursor: pointer;
  font-weight: 600;
}

.details-panel-wrapper {
  width: 100%;
  margin: 0 auto;
}
.details-panel-tabs {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 20px 0;
}
.panel-tab {
  text-align: center;
  padding-bottom: 5px;
  width: 50%;
}
.panel-tab:hover,
.panel-tab.selected {
  border-bottom: 3px solid var(--primary);
  cursor: pointer;
}

.chcp-wrp {
  width: 80%;
  position: absolute;
  top: 0;
  padding: 30px 40px 0 0;
  font-size: var(--fs-sm);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
}
.chcp-wrp h1,
.checkout-note h1 {
  font-size: 1.4rem;
  padding-bottom: 20px;
  font-weight: bold;
}
.chcp-wrp button {
  font-size: var(--fs-sm);
  padding: 10px;
  margin-top: 15px;
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: var(--background-color);
}
.chcp-wrp button:hover:not([disabled]),
.chcp-wrp button:focus {
  cursor: pointer;
  background-color: var(--primary);
  color: var(--background-color);
}
.chcp-wrp button:disabled,
.wide-btn:disabled {
  opacity: 0.3;
  color: var(--secondary);
  border-color: var(--secondary);
}
.chcp-btns {
  display: flex;
  flex-flow: row nowrap;
}
.chcp-btn {
  flex: 1;
  margin: 15px 5px;
}
.chcp-wrp button svg {
  float: right;
  font-size: 1.4rem;
}

.mini-card-container {
  min-width: 145px;
  height: 224px;
  margin: 3px 3px 45px;
  display: flex;
  position: relative;
  background-color: var(--card-color);
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  box-shadow: 1px 2px 3px 0 var(--shadow-dark);
}

.mini-card-container .mini-icons-beneath {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

img.mini-cover {
  width: 145px;
  max-width: 145px;
  height: 100%;
  filter: grayscale(0);
  -webkit-transition: filter 0.3s ease-out;
  -moz-transition: filter 0.3s ease-out;
  -o-transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
}
img.mini-cover.grayscale {
  filter: grayscale(1);
}

.mini-placeholder-container {
  color: var(--secondary);
  outline: 1px solid var(--secondary);
  background-color: var(--card-color);
  width: 145px;
  min-width: 145px;
  height: 224px;
  margin: 3px 3px 45px;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.3;
  padding: 5px;
}
.mini-placeholder-container p {
  font-weight: 100;
  font-size: var(--fs-md);
}
/* -----------------------------------  stars rating styling */
.stars-mini-container-wrap {
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: 10;
  width: 90%;
  /* margin-left: -2px; */
}
img.mini-stars-background {
  position: absolute;
  left: 0;
  bottom: -8px;
  z-index: -1;
  width: 100%;
  height: auto;
}
.stars-mini-inner-wrap {
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  position: absolute;
  left: 10%;
  top: 0px;
  background-color: var(--primary);
  width: 80%;
  height: 40px;
  text-align: center;
  -webkit-box-shadow: 3px 3px 4px 0px var(--shadow-dark);
  -moz-box-shadow: 3px 3px 4px 0px var(--shadow-dark);
  box-shadow: 3px 3px 4px 0px var(--shadow-dark);
}
.mini-book-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.3) 1%,
    rgba(255, 255, 255, 0.1) 4%,
    rgba(0, 0, 0, 0.3) 89%,
    rgba(255, 255, 255, 0.3) 94%,
    rgba(0, 0, 0, 0.2) 99%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
.stars-mini-container-wrap span {
  /* padding-left: 3px; */
}
.stars-mini-inner-wrap span {
  padding: 8px;
}
.stars-mini-container {
  position: relative;
  display: inline-block;
  color: transparent;
  font-size: var(--fs-md);
}
.stars-mini-container:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "☆☆☆☆☆";
  color: #fff;
}
.stars-mini-container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: #fff;
  overflow: hidden;
}
/* --------------------------------------- end of stars rating */

/* ----------------------------- icons underneath ------------------------- */
.mini-formats-outside {
  margin-left: 5px;
  /* width: 15px;
  height: 15px; */
}
.mini-formats-outside.active {
  background-color: var(--success-green);
  border-radius: 35px;
  margin-left: 6px;
}
.mini-formats-outside.active svg {
  color: #000;
  margin: 3px;
}
.mini-formats-outside.active p {
  color: #000;
  margin: 3px 7px;
}
.mini-outer-icons {
  color: var(--secondary);
  font-size: var(--fs-sm);
  margin: 4px;
  text-align: center;
}
.mini-icons-beneath {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -10%;
  left: 0;
  height: 10%;
  z-index: -1;
}
.pub-year.grayscale {
  filter: grayscale(1);
}
.mini-icons-beneath .pub-year {
  color: #fff;
  background-color: var(--primary);
  font-size: var(--fs-sm);
  overflow: hidden;
  -webkit-box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  -moz-box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
}
.mini-outer-formats-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.mini-outer-formats-container svg {
  font-size: var(--icn-formats);
}
/* ---------------------------  finish icons underneath  ---------------------------- */

.to-hide {
  display: none;
}

/* -----------------------------------  stars rating styling */
.stars-container-wrap {
  color: #fff;
  font-size: var(--fs-sm);
  display: inline-block;
  position: absolute;
  left: 2px;
  bottom: 30px;
  z-index: 10;
  width: 55%;
  margin-left: -2px;
}
img.stars-background {
  position: absolute;
  left: 0;
  bottom: -8px;
  z-index: -1;
  width: 100%;
  height: auto;
}
.stars-inner-wrap {
  color: #fff;
  font-size: var(--fs-sm);
  display: inline-block;
  position: absolute;
  left: 10%;
  top: 0px;
  background-color: var(--primary);
  width: 80%;
  height: 40px;
  text-align: center;
  -webkit-box-shadow: 3px 3px 4px 0px var(--shadow-dark);
  -moz-box-shadow: 3px 3px 4px 0px var(--shadow-dark);
  box-shadow: 3px 3px 4px 0px var(--shadow-dark);
}
.stars-container-wrap span {
  padding: 4px;
}
.stars-inner-wrap span {
  padding: 8px;
}
.stars-container {
  position: relative;
  display: inline-block;
  color: transparent;
  font-size: 1.5rem;
}
.stars-container:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "☆☆☆☆☆";
  color: #fff;
}
.stars-container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: #fff;
  overflow: hidden;
}
.stars-0:after {
  width: 0%;
}
.stars-1:after {
  width: 10%;
}
.stars-2:after {
  width: 20%;
}
.stars-3:after {
  width: 30%;
}
.stars-4:after {
  width: 40%;
}
.stars-5:after {
  width: 50%;
}
.stars-6:after {
  width: 60%;
}
.stars-7:after {
  width: 70%;
}
.stars-8:after {
  width: 80%;
}
.stars-9:after {
  width: 90%;
}
.stars-10:after {
  width: 100%;
}
/* --------------------------------------- end of stars rating */

/* ----------------------------- icons underneath ------------------------- */
.formats-outside {
  margin-left: 5px;
  width: 25px;
  height: 25px;
}
.formats-outside.active {
  background-color: var(--success-green);
  border-radius: 50px;
  margin-left: 5px;
}
.formats-outside.active svg {
  color: #000;
}
.outer-icons {
  color: var(--secondary);
  font-size: 2.5rem;
  margin: 4px;
  text-align: center;
}
.icons-beneath {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.icons-beneath.short {
  bottom: -20px;
}
.icons-beneath .pub-year {
  color: #fff;
  background-color: var(--primary);
  overflow: hidden;
  -webkit-box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  -moz-box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  padding: 1% 10% 1% 3%;
}
.outer-formats-container {
  display: flex;
  justify-content: flex-end;
}
.outer-formats-container svg {
  font-size: var(--icn-formats);
}
/* ---------------------------  end of icons underneath  ---------------------------- */

/* ---------------------------  the flipper inside  ------------------------- */
.jacket {
  width: 350px;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
  z-index: 100;
  position: relative;
}
.back {
  background-color: var(--card-color);
  font-size: large;
  color: var(--secondary);
}
.record-info {
  font-size: var(--fs-sm);
  /* overflow: hidden; */
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: left;
  padding: 30px;
}
.record-info ul {
  overflow-y: hidden;
}
.record-info ul li {
  margin-top: 15px;
  list-style: none;
}
.record-info.small {
  font-size: 0.7rem;
  padding: 5px;
}
.record-info.small ul li {
  margin-top: 5px;
}
.front {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.front img {
  width: 350px;
  height: 100%;
}
.jacket.back div::after {
  content: "";
  background-color: var(--background-color);
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
/* ---------------------------  end of the flipper inside  ---------------------------- */

/* ---------------------------  the peel corner effect  ------------------------- */
.jacket::before {
  background: rgba(0, 0, 0, 0)
    linear-gradient(
      315deg,
      var(--card-color) 45%,
      #aaaaaa 50%,
      #cccccc 56%,
      white 80%
    )
    repeat scroll 0 0; /*display smooth transitions between two or more specified colors */
  bottom: 0;
  box-shadow: -1px -1px 6px var(--shadow-light);
  content: "";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition-duration: 0.3s;
  transition-property: width, height;
  width: 0;
}
.jacket:hover::before {
  height: 70px;
  width: 70px;
}
.jacket-wrap {
  /* z-index: 1000; */
  height: 100%;
  width: 100%;
}
/* ---------------------------  end of the peel corner effect  ------------------------- */
.past-loan-wrp {
  position: absolute;
  color: var(--secondary);
  z-index: 1;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  padding: 10px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.past-loan-wrp p {
  opacity: 1;
  font-size: var(--fs-md);
  text-align: center;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.mini-card-container:hover .past-loan-wrp,
.mini-card-container:hover .past-loan-wrp p {
  opacity: 0;
}
.mini-card-container:hover .mini-outer-container {
  filter: brightness(1) !important;
  -webkit-transition: filter 0.3s ease-out;
  -moz-transition: filter 0.3s ease-out;
  -o-transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
}

.nice-dates-day:before {
  background-color: var(--primary) !important;
}
.nice-dates-navigation {
  color: var(--primary) !important;
}

.nice-dates-navigation_previous.-disabled:before,
.nice-dates-navigation_next.-disabled:before {
  opacity: 0.3;
}

.nice-dates-navigation_previous:not([disabled]):before,
.nice-dates-navigation_next:not([disabled]):before {
  border-color: var(--primary) !important;
}

.checkout-note {
  background-color: var(--primary);
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.checkout-note p {
  padding: 0;
}
.pickup-note {
  padding: 15px !important;
  background-color: var(--success-green);
  color: #fff;
  border-radius: 5px;
}
/* Extra Small Devices, Phones */
@media screen and (min-width: 769px) {
  .card-container:hover {
    transform: scale(1.2);
    cursor: pointer;
    z-index: 16;
    box-shadow: 2px 2px 8px 3px var(--shadow-dark);
  }
  .card-container .icons-beneath {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }
  .card-container:hover .icons-beneath {
    opacity: 0;
  }
  .mini-card-container:hover {
    cursor: pointer;
    z-index: 21;
    transform: scale(1.25) translate(0, 5px);
    box-shadow: 4px 2px 9px 1px var(--shadow-dark);
  }
  .mini-card-container:hover .mini-icons-beneath {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .loans-info-div {
    position: relative;
    top: 260px;
    width: 90%;
    left: 0;
    max-width: none;
    margin-right: 70px;
    height: calc(100% - 370px);
    font-size: var(--fs-md);
  }
  .loans-icons-div p {
    font-size: clamp(0.7rem, 2.5vw, 0.9rem);
    bottom: 0;
  }
  .loans-icons-div svg {
    font-size: clamp(1.4rem, 10vw, var(--fs-xl));
  }
  .chcp-wrp {
    padding: 30px 0;
    overflow: auto;
  }
  .inter-name {
    font-size: clamp(0.1rem, 2vw, 2rem);
  }
  .hor-icon svg {
    font-size: clamp(1rem, 4vw, 3rem);
  }
  .card-container:hover,
  .mini-outer-container:hover {
    outline: 3px solid var(--primary);
  }
  .mini-outer-container img {
    width: 100px;
  }
  .mini-card-container,
  .mini-placeholder-container {
    min-width: 100px;
    width: 100px;
    height: 160px;
    margin: 3px 3px 55px;
  }
  .mini-placeholder-container p {
    font-size: var(--fs-sm);
  }
  .icons-beneath,
  .mini-icons-beneath {
    display: block !important;
  }
  .mini-icons-beneath .pub-year {
    padding: 0;
    font-size: var(--fs-md);
    font-weight: 100;
    text-align: center;
    border-radius: 0 0 10px 10px;
    -webkit-clip-path: none;
    clip-path: none;
    width: 100px;
    min-height: 8px;
  }
  .mini-outer-formats-container {
    position: unset;
  }

  .mini-outer-formats-container svg {
    font-size: var(--icn-formats);
    margin: 3px;
  }
  .stars-mini-container {
    font-size: 0.8rem;
  }
  img.mini-stars-background {
    bottom: -6px !important;
    width: 96% !important;
  }
  .stars-mini-container-wrap span {
    font-size: 0.8rem;
    padding: 4px 0;
  }
  .placeholder-container {
    width: 100px;
    height: 160px;
    /* margin: 40px 3px; */
    padding: 40px 5px;
    /* text-align: left; */
  }
  .placeholder-container p {
    word-wrap: break-word;
    hyphens: auto;
  }
  .inter-icons-container {
    right: 0;
  }
  .wide-btn p {
    font-size: var(--fs-sm);
  }
  p.small-title {
    font-size: clamp(0.7rem, 3vw, 1.1rem);
    line-height: clamp(0.7rem, 3vw, 1.1rem);
    font-weight: 600;
  }
  .outer-left-container:before {
    display: none;
  }
  .outer-left-container img {
    width: 105px;
  }
  .card-container {
    /* min-width: 100px;
    height: 160px; */
    margin: 3px 3px 45px;
  }
  .icons-beneath .pub-year {
    width: auto;
    padding: 0;
    font-size: var(--fs-md);
    font-weight: 100;
    text-align: center;
    border-radius: 0 0 10px 10px;
    -webkit-clip-path: none;
    clip-path: none;
  }
  .outer-formats-container {
    top: 20px;
    right: unset;
    left: 0;
    width: 100%;
  }
  .formats-outside {
    width: 20px;
    height: 20px;
  }
  .outer-formats-container svg {
    font-size: var(--icn-formats);
    margin: 3px;
  }
  .stars-container {
    font-size: 0.8rem;
  }
  img.stars-background {
    bottom: -6px !important;
    width: 57% !important;
  }
  .stars-container-wrap span {
    font-size: 0.8rem;
    padding: 4px 0;
  }
  .panel-tab button,
  .details-panel-text {
    font-size: var(--fs-sm);
  }
  .horizontal-format-icons.opened {
    height: 900px !important;
  }
  .copies-div.opened {
    margin: -15px 70px 0;
  }
}

@media only screen and (max-width: 568px) {
  .copies-div.opened {
    margin: -15px 30px 0;
  }
  .inter-icons {
    font-size: var(--fs-lg);
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 480px) {
  .chcp-btns {
    flex-direction: column;
  }
  .copies-div.opened {
    margin: -15px 10px 0;
  }
  .wide-btn {
    width: 100%;
  }
}
