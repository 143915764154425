.search-outer-container {
  padding-top: 250px;
  position: relative;
}

.filters-panel {
  width: 0;
  color: #fff;
  background-color: var(--background-color);
  opacity: 0;
  left: -20vw;
  /* position: relative; */
  position: sticky;
  top: 160px;
  padding: 20px 30px 20px 20px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  height: fit-content;
}
.filters-panel.active {
  width: 20vw;
  min-width: 300px;
  max-width: 450px;
  left: 0;
  z-index: 10;
  /* box-shadow: 3px 3px 4px 0px var(--shadow-light); */
  opacity: 1;
}
.results-panel {
  width: 80vw;
  min-width: calc(100vw - 500px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin-top: -3px;
  flex: 1;
}
.search-wrapper {
  width: 100%;
  display: flex;
  /* padding-top: 15px; */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.search-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 30px 0;
}

.sorting-bar-wrp {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  z-index: 99;
  width: 100%;
  margin: 160px auto 20px;
}
.sorting-bar {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  padding: 5px 40px;
  margin: 0 auto;
  border-bottom: 1px solid var(--primary);
}
.sorting-bar-element {
  padding: 10px 0;
  display: flex;
}
.filters-search-form {
  display: flex;
  flex-flow: row wrap;
}
.filters-labels {
  color: var(--primary);
  font-weight: 500;
  font-size: var(--fs-sm);
}
.sorting-title,
.sorting-checkbox label {
  color: var(--primary);
  font-weight: 500;
  font-size: var(--fs-sm);
}

.sorting-title span {
  color: var(--secondary);
  /* padding-right: 40px; */
  font-weight: bold;
}

h4 span {
  color: var(--secondary);
  font-weight: 100;
}

.filters-search-container {
  padding: 10px 0;
  right: 0;
  position: relative;
  margin: 0 3px;
}

.filters-search-icon {
  position: absolute;
  left: 10px;
  top: 40px;
  color: rgb(180, 180, 180);
  font-size: var(--fs-xl);
}

.filters-panel #search-input {
  padding: 10px 10px 10px 50px;
  font-size: var(--fs-md);
  border-radius: 30px;
  width: 100%;
  margin: 5px 0;
  border: none;
  box-shadow: inset 0px 0px 4px 0px var(--shadow-dark);
}

.middle-title {
  color: var(--primary);
  font-weight: 500;
  text-align: center;
}

.filter-list {
  list-style: none;
  color: var(--secondary);
  /* height: 160px; */
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
  /* overscroll-behavior: contain; */
  margin-bottom: 20px;
}
.avatars-container::-webkit-scrollbar-track,
.preference-list::-webkit-scrollbar-track,
.filter-list::-webkit-scrollbar-track,
.renewall-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 5px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.avatars-container::-webkit-scrollbar,
.preference-list::-webkit-scrollbar,
.filter-list::-webkit-scrollbar,
.renewall-list::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.avatars-container::-webkit-scrollbar-thumb,
.preference-list::-webkit-scrollbar-thumb,
.filter-list::-webkit-scrollbar-thumb,
.renewall-list::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 10px var(--shadow-light);
  box-shadow: inset 0 0 10px var(--shadow-light);
  background-color: var(--primary);
}

.filters-cat-title {
  /* color: var(--primary); */
  font-size: var(--fs-sm);
  padding: 0 20px 5px 0;
}

.filters-categories {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
}

.filters-cat-col1,
.filters-cat-col2 {
  flex: 1;
}
.filters-cat-window {
  margin: 0 10px;
  position: relative;
}
.arrow-up:hover,
.arrow-down:hover {
  transform: scale(1.2);
}
.arrow-up,
.arrow-down {
  font-size: var(--icn-desktop-arrows);
  display: block;
  color: var(--primary);
  cursor: pointer;
  line-height: 1rem;
}
.arrow-up {
  margin: 0 auto -15px;
}
.arrow-down {
  margin: -15px auto 15px;
}
.sorting-checkbox {
  padding: 0 25px !important;
}
hr {
  border: 1px solid var(--primary);
}

/*------------------------ styling the checkboxes -----------------------*/
/*style wrapper to give some space*/
.checkbox-wrapper {
  position: relative;
  margin-left: 5px;
  min-height: 35px;
}

/*style label to give some more space*/
.checkbox-wrapper label {
  display: block;
  padding: 5px 0 5px 32px;
  color: var(--secondary);
  font-size: var(--fs-sm);
  width: 100%;
}
.checkbox-wrapper label:hover {
  cursor: pointer;
}
.checkbox-wrapper label:hover input {
  transform: scale(1.05);
}
.filter-list .checkbox-wrapper label:hover {
  background-color: var(--transparent-primary);
}
/*style and hide original checkbox*/
.checkbox-wrapper input {
  width: 24px;
  height: 24px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

/*position new box*/
.checkbox-wrapper input + label::before {
  border: 2px solid;
  content: "";
  width: 24px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  color: var(--primary);
}

/* the tick style */
.checkbox-wrapper input + label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  opacity: 0;
  position: absolute;
  left: 2px;
  top: 2px;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  font-size: var(--icn-checkboxes-check);
  color: var(--primary);
}

/*reveal check for 'on' state*/
.checkbox-wrapper input:checked + label::after {
  opacity: 1;
}

/*focus styles*/
.checkbox-wrapper input:focus + label::before {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  z-index: 1;
}
.checkbox-wrapper input:disabled + label::before,
.checkbox-wrapper input:disabled + label::after {
  color: var(--secondary);
}

/* ---------------------------------------------------------------------------------------------------------------------------------- */

.no-results {
  padding: 20px;
  font-size: var(--fs-md);
  margin: 0 auto;
  text-align: center;
  color: var(--secondary);
}
#sorting_by,
#search_within,
#search_limit {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  /* background-color: var(--card-color); */
  border: none;
  border-radius: 20px;
  color: #fff !important;
  background-color: var(--primary);
  font-size: var(--fs-xs);
  margin: 0 5px;
  padding: 5px 3px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 25%),
    -2px -2px 4px 0 rgb(255 255 255 / 50%);
  align-self: flex-end;
  width: 120px;
  max-width: 120px;
}
#sorting_by {
  width: 180px;
}
#search_within,
#search_limit {
  flex: 1;
}
option {
  background-color: var(--card-color);
  color: var(--secondary);
}
.refresh_results_btn,
.filters-panel-btn {
  cursor: pointer;
  color: #fff;
  margin: 0 5px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 25%),
    -2px -2px 4px 0 rgb(255 255 255 / 50%);
  border: 2px solid var(--transparent-primary);
  background-color: var(--primary);
}
.refresh_results_btn:hover,
.filters-panel-btn:hover,
#sorting_by:hover,
#search_within:hover,
#search_limit:hover {
  background-color: transparent;
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  color: var(--secondary) !important;
  border: 2px solid var(--primary);
}
.refresh_results_btn svg,
.filters-panel-btn svg {
  font-size: var(--fs-sm);
  margin-left: 10px;
}
.load-more-container {
  width: 145px;
  height: 224px;
  position: relative;
  cursor: pointer;
  color: var(--primary);
  font-size: var(--fs-lg);
  margin: 3px;
  padding: 60px 20px;
  text-align: center;
  border: 1px solid var(--primary);
}
.load-more-container:hover,
.load-more-container:focus {
  background-color: var(--primary);
  color: #fff;
}

.filters-container {
  display: flex;
  flex-flow: row wrap;
  height: auto;
  -webkit-transition: all 0.5s ease-in-out 0.1s;
  -moz-transition: all 0.5s ease-in-out 0.1s;
  -o-transition: all 0.5s ease-in-out 0.1s;
  transition: all 0.5s ease-in-out 0.1s;
  padding: 15px 0;
}

.filters-single {
  display: inline-flex;
  padding-left: 5px;
  line-height: 1.2rem;
  margin: 2px;
  border-radius: 30px;
  background-color: #fff;
  color: #404040;
  max-height: 22px;
  overflow: hidden;
  width: fit-content;
  border: 1px solid var(--primary);
}
.filters-single:hover {
  cursor: pointer;
  box-shadow: 1px 1px 4px 0 var(--shadow-dark);
}
.filters-single p {
  font-size: var(--fs-xs);
  text-align: left;
}
.filters-single svg {
  font-size: var(--fs-sm);
  margin: 2px;
  cursor: pointer;
  color: var(--primary);
  border-radius: 10px;
}
.filters-single span {
  font-weight: bold;
}
/* .filters-container-btn:after {
  animation: shine 1s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 170%;
  height: 200%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.7);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 77%,
    rgba(255, 255, 255, 0.7) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
.filters-container-btn:active:after {
  opacity: 0;
}
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
} */
.floating-div.active .filters-container-buttons .filters-container-btn {
  background-color: var(--primary);
  color: #fff;
  flex: 1;
  padding: 10px;
}
.floating-div.active
  .filters-container-buttons
  .filters-container-btn:hover:not([disabled]),
.floating-div.active .filters-container-buttons .filters-container-btn:focus {
  background-color: transparent;
  color: var(--primary);
}
.filters-container.active .filters-container-buttons {
  display: flex;
  flex-wrap: wrap;
}
.filters-container-buttons.inside {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.filters-container-btn svg {
  float: right;
  margin: 2px 2px 0 7px;
}
.floating-div {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 20vw;
  min-width: 300px;
  max-width: 450px;
  background-color: var(--background-color);
  padding: 20px;
  transform: translate(-450px, 0);
  -webkit-transition: transform 0.5s ease-in-out 0.1s;
  -moz-transition: transform 0.5s ease-in-out 0.1s;
  -o-transition: transform 0.5s ease-in-out 0.1s;
  transition: transform 0.5s ease-in-out 0.1s;
  z-index: 22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 4px 0 var(--shadow-dark);
  border-radius: 0 5px 0 0;
}
.floating-div.active {
  transform: translate(0, 0);
}
.filters-container-buttons {
  display: flex;
  width: 100%;
  align-items: center;
}
.filters-container-btn {
  position: relative;
  overflow: hidden;
  font-size: var(--fs-xs);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  margin: 5px;
  min-width: 45%;
  display: inline-block;
  font-weight: bold;
}
.filters-container-buttons.inside .filters-container-btn {
  min-width: 45%;
}
.filters-container-btn:hover:not([disabled]) {
  background-color: var(--overlay-color);
}
.filters-container-btn:disabled {
  color: var(--secondary);
  border-color: var(--secondary);
  opacity: 0.3;
  cursor: unset;
}
.filters-text {
  color: var(--secondary);
  text-align: center;
  width: 100%;
}
.checkbox.small {
  font-size: var(--fs-sm);
}

.internal-filtering {
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 7px 10px;
  margin-bottom: 15px;
  /* border: 2px solid transparent; */
  box-shadow: inset 0px 0px 3px 0px var(--shadow-dark);
}
.clear-inter-filter {
  position: absolute;
  right: 7px;
  top: 7px;
  color: var(--primary);
  cursor: pointer;
}
p.mini-filter-count {
  position: absolute;
  right: 35px;
  top: 9px;
  text-align: right;
  color: var(--primary);
  font-size: var(--fs-xs);
}
/* ------------------------- Start of rc-collapse styling --------------------------- */
.rc-collapse-motion {
  transition: height 0.3s, opacity 0.3s;
}
.rc-collapse-content-hidden {
  display: none;
}
.rc-collapse {
  background-color: var(--card-color);
  border-radius: 5px;
  border: 1px solid var(--primary);
}
.rc-collapse > .rc-collapse-item:first-child {
  border-top: none;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 10px 16px;
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 4px;
  position: relative;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header:focus {
  outline-width: 4px;
  outline-offset: 0px;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
  display: inline-block;
  content: "\20";
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
  vertical-align: middle;
  margin-right: 8px;
  right: 4px;
  position: absolute;
  transform: rotate(90deg);
  transition: transform 0.1s ease-in-out;
}
.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
  position: absolute;
  top: 8px;
  right: 4px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  margin-right: 6px;
  transform: rotate(180deg);
  transition: transform 0.1s ease-in-out;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 16px 0 auto;
}
.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only {
  cursor: default;
}
.rc-collapse
  > .rc-collapse-item
  .rc-collapse-header-collapsible-only
  .rc-collapse-header-text {
  cursor: pointer;
}
.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: var(--secondary);
  /* background-color: #f3f3f3; */
}
.rc-collapse-content {
  overflow: hidden;
  color: var(--secondary);
  padding: 0;
  background-color: var(--card-color);
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 16px;
  min-height: 100px;
}
.rc-collapse-item:last-child > .rc-collapse-content {
  border-radius: 0 0 3px 3px;
}
.rc-collapse-item.rc-collapse-item-active {
  transition: all 1s ease-in-out;
}
.no-items {
  padding-top: 30px;
  text-align: center;
}
.searchfield-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
}

/* ------------------------- end of rc-collapse styling --------------------------- */

@media screen and (max-width: 768px) {
  .search-row {
    justify-content: center !important;
    padding: 20px 5px;
  }
  .filters-panel.active,
  .floating-div.active {
    width: 40vw;
  }
  .results-panel {
    width: 60vw;
  }
  .load-more-container {
    width: 100px;
    height: 160px;
    font-size: var(--fs-md);
    margin: 3px 3px 30px 3px;
    padding: 40px 20px;
  }
  .sorting-bar {
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 20px;
  }
  .search-outer-container {
    padding-top: 170px;
  }
}

@media screen and (max-width: 560px) {
  .filters-panel.active,
  .floating-div.active {
    width: 100vw;
    max-width: none;
  }
  .results-panel {
    width: 0;
  }
}
@media screen and (max-width: 480px) {
  .filters-categories {
    flex-direction: column;
  }
  .filter-list {
    padding-left: 40px;
  }
  .searchfield-wrapper {
    flex-direction: column;
  }
  .sorting-bar {
    position: relative;
    top: auto;
  }
  .sorting-bar-element {
    width: 100% !important;
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 960px) {
  .filters-container-btn {
    width: 100%;
  }
  .filters-container-buttons {
    flex-direction: column;
  }
  .floating-div .filters-container-buttons {
    height: auto;
  }
  .sorting-bar-wrp {
    margin: 100px 20px 0;
  }
  .sorting-bar-element {
    padding: 10px 5px;
    width: 50%;
    justify-content: center;
    align-items: flex-end;
  }
}
