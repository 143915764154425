.footer {
  position: relative;
  width: 100%;
  background-color: var(--footer-color);
  margin-top: 100px;
  color: var(--secondary);
  -webkit-box-shadow: inset 0px 1px 5px 0px var(--shadow-dark);
  -moz-box-shadow: inset 0px 1px 5px 0px var(--shadow-dark);
  box-shadow: inset 0px 1px 5px 0px var(--shadow-dark);
}
.footer.block {
  display: block;
}
.footer a {
  color: var(--primary);
}

.footer iframe {
  border: 0;
  position: absolute;
}

.footer-container {
  flex: 1;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer-text-container {
  font-size: var(--fs-md);
  padding-left: 30px;
  text-align: left;
}

.footer .footer-titles {
  color: var(--primary);
  font-size: var(--fs-xl);
  font-weight: 900;
}
.left-aligned {
  text-align: left;
  padding-left: 30px;
}
.hours-container {
  font-size: calc(var(--lsp-unit) * 1.5);
  line-height: calc(var(--lsp-unit) * 2);
}

.hours-container ul {
  list-style: none;
  padding: 30px;
}

.footer .address li {
  font-size: calc(var(--lsp-unit) * 1.5);
  font-weight: 300;
  list-style: none;
  padding-bottom: 10px;
}

.address {
  padding: 30px;
}

.footer .copyright {
  font-weight: 300;
  font-size: var(--fs-xs);
  /* position: absolute;
  bottom: 0; */
  text-align: center;
  width: 100%;
  padding: 5px 0;
  background: var(--overlay-color);
}

.social-media {
  color: var(--primary);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: clamp(280px, 30vw, 400px);
  margin: 0 auto;
}
.social-media a {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
}
.social-media a:hover,
.social-media a:focus {
  background: var(--primary);
  color: #fff;
  border-radius: 10px;
}
.social-media svg {
  font-size: var(--icn-desktop-lg);
}

.joinin-outer {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: var(--background-color);
  justify-content: space-between;
  margin: 80px 0 -100px;
  overflow: hidden;
  background-color: var(--background-color);
}
.joinin-right {
  width: 50%;
  height: 100%;
  z-index: 1;
  position: relative;
}
.joinin-right:before {
  content: "";
  position: absolute;
  width: 6000px;
  height: 4000px;
  top: -1940px;
  left: -2000px;
  opacity: 0.4;
  z-index: -1;
  transform: rotate(30deg) scale(0.5);
  background: url("/dark_background.jpg") 0 0 repeat;
}
.joinin-left {
  background-color: var(--primary);
  color: #fff;
  width: 50%;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%);
  padding: 50px 0;
  z-index: 2;
}
.joinin-left-content {
  display: flex;
  width: 70%;
  margin: 0 auto;
  align-items: center;
  font-size: var(--fs-lg);
}
.button-join {
  min-width: 10rem;
  color: var(--primary);
  border-radius: 30px;
  padding: 5px;
  font-size: var(--fs-md);
  background: #fff;
  border: 2px solid var(--primary);
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
}
.button-join svg {
  float: right;
  font-size: var(--icn-jackets-hor);
}
.button-join:hover {
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}
.button-join:focus {
  outline-color: #fff;
}
@media screen and (max-width: 1200px) {
  .joinin-left {
    padding: 15px 0;
  }
  .desktop-only {
    display: none;
  }
  .address .phone-number {
    padding: 10px 0;
  }
  .address .phone-number a {
    font-size: calc(var(--lsp-unit) * 1.8);
  }
  .social-media a {
    margin: 5px;
  }
}
@media screen and (max-width: 768px) {
  .joinin-left {
    width: 80%;
  }
  .joinin-right {
    width: 20%;
  }
  .social-media {
    justify-content: center;
  }
  .social-media a {
    height: 50px;
    width: 50px;
  }
  .social-media svg {
    font-size: var(--fs-xl);
  }
  .social-media a:hover,
  .social-media a:focus {
    border-radius: 5px;
  }
}
@media screen and (max-width: 480px) {
  .joinin-left-content {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    text-align: center;
  }
  .button-join {
    width: 80%;
    padding: 10px 0;
    margin-left: 0;
    font-size: var(--fs-md);
    margin-top: 20px;
    height: auto;
  }
  .button-join svg {
    font-size: var(--icn-jackets-ver);
    margin-right: 10px;
  }
  .joinin-outer {
    margin: 50px 0 0;
  }
  .joinin-right {
    display: none;
  }
  .joinin-left {
    width: 100%;
    clip-path: none;
  }
  .footer-titles {
    font-size: calc(var(--lsp-unit) * 1.4);
    margin-top: 20px;
  }
  .footer .address li {
    font-size: var(--fs-md);
  }
  .footer {
    min-height: auto;
    margin-top: 0;
  }
}
