@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;

  /* Hide scrollbar everywhere for IE, Edge and Firefox */
  /* IE and Edge */
  /* -ms-overflow-style: none;  */
  /* Firefox */
  /* scrollbar-width: none;  */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: #dadada;
  background-color: var(--background-color);
  font-size: 16px;
}

.app {
  width: 100%;
  /* overflow-x: hidden; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
}

:root {
  --primary: ;
  --secondary: ;
  --orange: #e2682e;
  --background-color: #dadada;
  --footer-color: ;
  --card-color: ;
  --navbar-icons-color: ;
  --sticky-navbar-icons-color: ;
  --navbar-color: ;
  --mobile-navbar-color: ;
  --overlay-color: ;
  --success-green: limegreen;

  --left-arrow-bg: ;
  --right-arrow-bg: ;
  --promo-carousel-bg: ;
  --background-pattern: ;
  --logo-main: ;
  --logo-sticky: ;
  --book-shading: ;

  /* Toastify styles */
  --toastify-text-color-light: var(--secondary) !important;
  --toastify-text-color-dark: var(--secondary) !important;
  --toastify-color-light: var(--card-color) !important;
  --toastify-color-dark: var(--card-color) !important;

  /* Shadows */
  --shadow-dark: rgba(0, 0, 0, 0.75);
  --shadow-light: rgba(0, 0, 0, 0.3);

  /* Shadding */
  --transparent-primary: ;

  /* font sizes */
  --fs-row-titles: calc(var(--lsp-unit) * 2.6);
  --fs-xxl: calc(var(--lsp-unit) * 3);
  --fs-xl: calc(var(--lsp-unit) * 2);
  --fs-lg: calc(var(--lsp-unit) * 1.6);
  --fs-md: calc(var(--lsp-unit) * 1.2);
  --fs-sm: var(--lsp-unit);
  --fs-xs: calc(var(--lsp-unit) * 0.7);

  /* icons sizes */
  --icn-checkboxes-check: calc(var(--lsp-unit) * 1.4);
  --icn-ins-btn: calc(var(--lsp-unit) * 0.8);
  --icn-close-btn: var(--lsp-unit);
  --icn-desktop-arrows: calc(var(--lsp-unit) * 2.6);
  --icn-desktop-lg: calc(var(--lsp-unit) * 3);
  --icn-jackets-hor: calc(var(--lsp-unit) * 1.5);
  --icn-jackets-ver: calc(var(--lsp-unit) * 2);
  --icn-jackets-small-arrow: calc(var(--lsp-unit) * 1.4);
  --icn-formats: var(--lsp-unit);

  /* navbar */
  --nav-fs-sm: calc(var(--lsp-unit) * 0.7);
  --nav-fs-lg: calc(var(--lsp-unit) * 0.9);
  --nav-mob-fs: calc(var(--lsp-unit) * 1.5);
  --nav-icns: calc(var(--lsp-unit) * 2);

  /* main font variable */
  --lsp-unit: clamp(0.8rem, 1.5vw, 1rem);
}

.Toastify__close-button--light {
  color:  !important;
  color: var(--primary) !important;
  opacity: 1 !important;
}
.Toastify__close-button--dark {
  color:  !important;
  color: var(--primary) !important;
  opacity: 1 !important;
}
.Toastify__toast {
  /* border: 1px solid var(--primary) !important; */
}
a {
  text-decoration: none;
  color: ;
  color: var(--secondary);
}

:focus {
  outline: 2px dotted ;
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  z-index: 1;
}
.title-wrp {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-end;
  width: 100%;
}
.title-wrp.top-aligned {
  align-items: center;
  justify-content: space-between;
}
.lsp-skip-link {
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  text-decoration: underline;
  text-decoration-thickness: max(2px, 0.1em);
  font-size: calc(clamp(0.8rem, 1.5vw, 1rem) * 1.2);
  font-size: var(--fs-md);
  line-height: calc(clamp(0.8rem, 1.5vw, 1rem) * 1.2);
  line-height: var(--fs-md);
  display: inline-block;
}
.lsp-skip-link:active,
.lsp-skip-link:focus {
  padding: 10px 15px;
  color: #fff !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  white-space: inherit !important;
  background:  !important;
  background: var(--primary) !important;
}
.skip-link-div.search-results {
  position: absolute;
  top: 200px;
}
.reload-crsl-btn {
  height: 30px;
  width: 30px;
  background: transparent;
  color: ;
  color: var(--primary);
  margin: 0 20px;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-size: var(--icn-formats);
  border-radius: 50%;
}
.reload-crsl-btn:hover,
.reload-crsl-btn:focus {
  background: ;
  background: var(--primary);
  color: #fff;
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.lsp-title-btn {
  background: ;
  background: var(--primary);
  border: 1px solid ;
  border: 1px solid var(--primary);
  padding: 10px 20px;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  margin: 5px 40px 0 10px;
  font-weight: bold;
}
.lsp-title-btn:hover,
.lsp-title-btn:focus {
  cursor: pointer;
  background: ;
  background: var(--card-color);
  color: ;
  color: var(--primary);
}
button {
  background: none;
  border: none;
  cursor: pointer;
}

::-webkit-input-placeholder {
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-size: var(--fs-sm);
  color: #000;
  opacity: 0.5; /* Firefox */
}

::placeholder {
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-size: var(--fs-sm);
  color: #000;
  opacity: 0.5; /* Firefox */
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: #000;
  opacity: 0.5;
}

/* Microsoft Edge */
::-ms-input-placeholder {
  color: #000;
  opacity: 0.5;
}

.grecaptcha-badge {
  z-index: 9999;
}

.image-responsive {
  width: 100%;
  padding: 10px;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

/* Custom font to change the password input symbol */
@font-face {
  font-family: "fontello";
  src: url("/fonts/fontello.eot?2022");
  src: url("/fonts/fontello.eot?2022#iefix") format("embedded-opentype"),
    url("/fonts/fontello.woff2?2022") format("woff2"),
    url("/fonts/fontello.woff?2022") format("woff"),
    url("/fonts/fontello.ttf?2022") format("truetype"),
    url("/fonts/fontello.svg?2022#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media only screen and (max-width: 768px) {
  .lsp-title-btn {
    margin: 0;
    padding: 5px;
    height: unset;
  }
  .lsp-skip-link:focus {
    font-size: clamp(0.8rem, 1.5vw, 1rem);
    font-size: var(--fs-sm);
    line-height: 1rem;
    margin: 0 10px 10px;
    padding: 5px;
  }
}
@media only screen and (max-width: 1200px) {
  .title-wrp.top-aligned {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.85); */
  background-color: var(--background-color);
  opacity: 0.85;
  z-index: 110;
}

.modal {
  width: 60%;
  background: var(--card-color);
  color: var(--secondary);
  position: fixed;
  top: 25vh;
  left: calc((100% - 60%) / 2);
  z-index: 120;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  overflow: hidden;
}
.avatars-modal {
  top: calc((100vh - 680px) / 2);
}
.modal-title {
  padding: 10px;
}
.modal-msg {
  font-size: 1.2rem;
  color: var(--secondary);
}
.modal-header {
  padding: 1rem;
  border-bottom: 2px solid var(--primary);
  text-align: center;
}
.modal-header h1 {
  font-weight: 100;
}
.modal-header h2 {
  font-weight: 100;
  text-transform: uppercase;
}
.modal-content {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}
.modal-line {
  color: var(--secondary);
  padding-bottom: 15px;
}
.modal-line button {
  color: var(--primary);
  text-decoration: underline;
  font-size: var(--fs-sm);
  font-weight: bold;
}
.modal-actions {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.close-modal {
  margin: 10px 15px;
  float: right;
  cursor: pointer;
  color: var(--primary);
}

.login-modal {
  border: 2px solid var(--orange);
}

.library-login-modal {
  border: 2px solid var(--primary);
  background: var(--card-color);
  position: fixed;
  top: 20vh;
  left: calc((100% - 750px) / 2);
  z-index: 140;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  min-height: auto;
  width: 750px;
}
.btn-sml {
  position: relative;
  padding: 20px;
  font-size: var(--fs-xl);
  border: 1px solid var(--secondary);
  border-radius: 50px;
  background-color: transparent;
  color: var(--secondary);
  width: 48%;
  cursor: pointer;
  margin-bottom: 20px;
}
.btn-sml:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--primary);
  border: 1px solid var(--primary);
}
.close-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
}

.book-modal {
  background: var(--card-color);
  position: fixed;
  top: calc((100vh - 540px) / 2);
  left: calc((100% - 750px) / 2);
  z-index: 120;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  height: 540px;
  width: 750px;
}

.book-modal .desktop-card-container {
  max-width: 750px;
  margin: 0;
}

.event-modal {
  top: calc((100vh - 540px) / 2);
  left: calc((100% - 750px) / 2);
  height: 540px;
  width: 750px;
  overflow: hidden;
  box-shadow: 0 0 10px 0px var(--primary), 0 0 30px var(--primary);
}

.event-modal-close {
  color: var(--primary);
  top: 0;
  right: 0;
  margin: 5px 10px;
  font-size: 3rem;
}

.event-modal-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.event-modal-titles {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 20px;
  background-color: var(--overlay-color);
  width: 100%;
}

.event-modal-info {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-weight: 100;
}
.event-modal-tickets {
  position: absolute;
  top: 18px;
  width: 100%;
  margin: 0 auto;
  font-size: var(--fs-sm);
}
.event-modal-tickets.full-text {
  top: 14px;
  font-size: 0.7rem;
  opacity: 0.5;
  right: 1px;
}

.flex-1 {
  flex: 1 1 !important;
}

.flex-2 {
  flex: 2 1 !important;
}

.blog-modal-info {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  font-size: var(--fs-md);
  font-weight: 100;
}

.event-modal-content {
  position: relative;
  height: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}

.event-modal-content a {
  color: var(--primary);
}

.event-modal-titles h1 {
  color: var(--primary);
  font-size: var(--fs-xl);
}

.event-modal-titles h2 {
  color: var(--primary);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.event-modal-keywords {
  padding: 5px 15px;
  max-width: 75%;
}

.event-modal-keywords p {
  font-size: 0.8rem;
  display: inline-block;
  margin-right: 10px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}

.event-modal-icons {
  color: var(--secondary);
  position: absolute;
  top: 10px;
  right: -10px;
  margin-right: 20px;
  height: 25px;
  /* max-width: 25%; */
}

.event-modal-icons svg {
  font-size: 1.4rem;
  margin-left: 8px;
}

.event-modal-text {
  max-height: 65%;
  margin: 0 80px 40px 20px;
  padding-right: 20px;
  overflow-y: auto;
  min-height: 150px;
}

.event-modal-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 4px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.event-modal-text::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.event-modal-text::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--primary);
}

.event-modal-text p {
  font-size: var(--fs-md);
  font-weight: 100;
}

.event-modal-inter-icons {
  width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -1px;
  right: -1px;
}

.event-modal-inter-icons a {
  flex: 1 1;
  min-width: 50px;
  min-height: 50px;
  /* border: 1px solid var(--primary); */
  text-align: center;
  cursor: pointer;
  padding: 5px 0;
}

.event-modal-inter-icons a svg {
  color: var(--primary);
  font-size: var(--fs-xl);
  text-align: center;
}

.event-modal-inter-icons a svg {
  margin: 6px;
}
.video-title {
  padding: 20px;
  color: var(--secondary);
}
.blog-modal-inter-icons {
  max-width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 18px;
  right: -1px;
}

.blog-modal-inter-icons a {
  flex: 1 1;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  cursor: pointer;
  padding: 5px 0;
  /* border: 1px solid var(--primary); */
}

.blog-modal-inter-icons a svg {
  color: var(--primary);
  font-size: 2.5rem;
  margin: 6px;
  text-align: center;
}

.blog-modal-date {
  color: #fff;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.contact-modal,
.video-modal {
  background: var(--card-color);
  border: 2px solid var(--primary);
  position: fixed;
  top: 20vh;
  left: calc((100% - 750px) / 2);
  z-index: 1500;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  /* min-height: 600px; */
  width: 750px;
}
.contact-form-outer {
  display: flex;
  width: 100%;
  /* padding-top: 30px; */
}
.contact-form-left,
.contact-form-right {
  flex: 1 1;
}
.contact-form-outer div {
  text-align: center;
}
.contact-form-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
}
.login-dialogue-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.contact-btn {
  padding: 10px;
  font-size: var(--fs-sm);
  border: 1px solid var(--primary);
  border-radius: 50px;
  color: #fff;
  background: var(--primary);
  min-width: 0;
  max-width: none;
  width: 30%;
  margin: 0 20px;
  position: relative;
}

.contact-btn:hover:not([disabled]),
.contact-btn:focus {
  background: var(--background-color);
  cursor: pointer;
  color: var(--primary);
}
.contact-btn:hover:not([disabled]) svg,
.contact-btn:focus svg {
  color: var(--primary);
}

.contact-btn svg {
  color: #fff;
  margin: 3px 0 0;
  position: absolute;
  right: 10px;
  /* font-size: var(--fs-xl); */
}

.contact-btn:disabled,
.contact-btn:disabled svg {
  color: var(--primary);
  background: var(--secondary);
  border-color: var(--secondary);
  cursor: unset;
  opacity: 0.4;
}

.contact-btn.small {
  font-size: 0.9rem;
  width: 40%;
}

.renewall-wrp {
  padding: 50px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  justify-content: space-between;
  color: var(--secondary);
}
.renewall-list {
  list-style: none;
  color: var(--secondary);
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  width: 90%;
  margin: 0 auto 30px;
  padding: 30px;
  /* background: var(--background-color); */
}
.renewall-list li {
  border-bottom: 1px dashed var(--primary);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  margin: 5px;
}
.renewall-list li p {
  font-size: var(--fs-md);
}
.renewall-list li p span {
  font-size: 0.8rem;
}
.renewal-cover {
  width: auto;
  height: 100px;
  display: block;
  margin: 0 auto;
}
.renewal-cover-overlay {
  height: 100%;
  width: 100%;
  background-color: var(--overlay-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.loan-cover-wrp {
  position: relative;
}
.success-check {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 3rem;
  color: var(--success-green);
  z-index: 5;
}
.checkbox-wrapper.checkall-wrapper {
  margin-left: 65px;
}
.checkbox-wrapper.checkall-wrapper label {
  color: var(--primary);
}
.renew-waiting {
  top: 55px;
  position: absolute;
  left: calc((100% - 36px) / 2);
  z-index: 3;
}
.login-waiting {
  bottom: 12px;
  position: absolute;
  left: calc((100% - 36px) / 2);
  opacity: 1;
  z-index: 10;
}
.contact-error-msg {
  color: var(--primary);
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 10%;
}

.contact-input-field {
  padding: 10px 15px;
  font-size: var(--fs-sm);
  width: 90%;
  margin: 10px auto;
  border-radius: 50px;
  border: none;
  font-weight: 100;
  border: 3px solid var(--card-color);
  box-shadow: inset 0px 0px 4px 0px var(--secondary);
}

.contact-input-field:disabled {
  background-color: #fff;
  color: #8a8a8a;
  box-shadow: inset 0px 0px 4px 0px var(--shadow-light);
}

.message-area {
  border-radius: 25px;
}

.contact-input-label {
  color: var(--secondary);
  font-weight: normal;
  font-size: var(--fs-sm);
  float: left;
  padding-left: 45px;
  margin-bottom: -10px;
  font-weight: 500;
}

.contact-form-input {
  position: relative;
  padding: 10px 0;
}

.contact-form-input svg {
  color: var(--primary);
  position: absolute;
  top: 42px;
  right: 30px;
  font-size: var(--fs-md);
}

.ms-book-outer {
  display: flex;
  flex-flow: column wrap;
  position: relative;
  height: 100%;
}
.ms-book-bookcover {
  display: block;
  height: 270px;
  width: 175px;
  margin: 0 auto;
}
.ms-book-bookcover img {
  display: block;
  width: 100%;
  height: 100%;
  /* height: 160px; */
}
.ms-stars-inner-wrap {
  height: auto;
  width: 90%;
  top: 0;
  left: 5%;
  font-weight: 100;
  color: #fff;
  font-size: var(--fs-sm);
  display: inline-block;
  position: absolute;
  background-color: var(--primary);
  height: auto;
  text-align: center;
  box-shadow: 3px 3px 4px 0px var(--shadow-dark);
}
.ms-book-content {
  width: 100%;
  height: 35vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--secondary);
  padding: 0 20px 50px;
  font-size: var(--fs-md);
  font-weight: 100;
  margin-top: 10px;
}
.ms-inter-icons-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--card-color);
}
.ms-inter-icons-container .inter-icons-inner {
  flex: 1 1;
  min-width: calc(90vw / 6);
  min-height: calc(90vw / 6);
  border: none !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* padding: 7px 0; */
}
.ms-inter-icons-container .inter-icons-inner svg {
  font-size: var(--icn-jackets-small-arrow) !important;
}
.image-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.ms-horizontal-format-icons {
  margin-top: 10px;
  max-height: 70px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  width: 90vw;
  left: 0;
  background-color: var(--card-color);
}
.ms-horizontal-format-icons div {
  text-align: center;
  cursor: pointer;
  margin: 0 8px;
}
.ms-horizontal-format-icons div p {
  color: var(--primary);
  font-weight: 100;
}
/* ---------------------------  the flipper inside  ------------------------- */
.ms-jacket {
  width: 100%;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
  z-index: 100;
  position: relative;
}
.ms-back {
  background-color: var(--card-color);
  padding: 10px;
  font-size: 12px;
  color: var(--secondary);
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ms-back b {
  margin-top: 15px;
}
.ms-front {
  position: absolute;
  top: 0;
}
.ms-jacket.ms-back div::after {
  content: "";
  background-color: var(--background-color);
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
/* ---------------------------  end of the flipper inside  ---------------------------- */

/* start css from Signup.css file copied */
.inputs-container {
  width: 50%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
}
.form-add-info {
  color: var(--secondary);
  font-size: var(--fs-xs);
}
.input-field {
  padding-left: 20px;
  font-size: 1.4rem;
  width: 100%;
  margin: 5px 0;
  border-radius: 50px;
  border: none;
  border: 3px solid var(--card-color);
  box-shadow: inset 0px 0px 4px 0px var(--secondary);
  min-height: 75px;
}
.input-field.narrow {
  min-height: 40px;
  font-size: var(--fs-sm);
}
h2.form-title {
  color: var(--primary);
  font-size: var(--fs-xl);
  font-weight: 100;
  text-align: center;
  padding-block: 30px;
}

h3.form-title {
  color: var(--orange);
  font-weight: 100;
  font-size: var(--fs-xl);
  margin-bottom: 20px;
}

.input-label {
  color: var(--secondary);
  font-weight: 100;
  font-size: var(--fs-md);
  float: left; /* TEST for library login dialog */
}

.form-buttons {
  margin: 20px auto;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.next-btn {
  width: 100%;
  position: relative;
  padding: 20px;
  font-size: var(--fs-lg);
  border: 1px solid var(--secondary);
  border-radius: 50px;
  background-color: transparent;
  color: var(--secondary);
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
}
.next-btn:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--primary);
  border: 1px solid var(--primary);
}
.next-btn svg {
  color: var(--secondary);
}
.next-btn:hover svg {
  color: var(--primary);
}

.select-container {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
}
.select-container.half {
  width: 45%;
}
.signup-form .input-label {
  font-size: 1rem;
}
.signup-form .input-field {
  margin: 0;
}
.signup-form .input-field:focus {
  outline-offset: 0;
}
.signup-form .error-msg {
  margin: 0;
  padding: 0 0 10px 0;
  font-size: var(--fs-xs);
}
select {
  -webkit-appearance: none;
  appearance: none;
}

.short-container {
  display: flex;
  justify-content: space-between;
}

.short {
  width: 60%;
  position: relative;
  z-index: 10;
}

.signup-form .arrow-icon {
  color: var(--primary);
  position: absolute;
  top: 27px;
  right: 10px;
  font-size: var(--nav-mob-fs);
}
.arrow-icon {
  color: grey;
  position: absolute;
  top: 55px;
  right: 30px;
  font-size: 2.5rem;
}

.next-icon {
  color: inherit;
  position: absolute;
  top: 20px;
  right: 30px;
  /* font-size: 2.5rem; */
}

.prev-icon {
  color: inherit;
  position: absolute;
  top: 20px;
  left: 30px;
  /* font-size: 2.5rem; */
}

.error-msg {
  color: red;
  padding: 0 25px;
  width: 100%;
  text-align: left !important;
  font-weight: bold;
}

.spacer {
  margin: 30px 0;
}

.dialogue-message {
  color: var(--secondary);
  font-size: var(--fs-md);
  padding: 40px;
}

.dialogue-message.centered {
  text-align: center;
}

.dialogue-message button {
  color: var(--primary);
  font-size: var(--fs-md);
  font-weight: bold;
  text-decoration: underline;
}

.signout-modal {
  background: var(--card-color);
  position: fixed;
  top: 20vh;
  left: calc((100% - 750px) / 2);
  z-index: 1500;
  box-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary);
  width: 750px;
  height: auto;
}

/* To remove the small arrows inside numeric input -------- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* -------------------------------------------------------- */

.show-password {
  position: absolute;
  right: 40px;
  top: 55px;
  color: #000;
  opacity: 0.6;
}

/* hide the eye element to reveal passwords on MS Edge to add our own icon */
::-ms-reveal {
  display: none;
}

/* Use the css below to change the password input symbol */
input[type="password"] {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  letter-spacing: 8px;

  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.date-range {
  text-align: center;
}

.date-range input {
  margin-top: 5px;
  padding: 5px;
  text-align: center;
  border: 1px solid var(--primary);
  border-radius: 3px;
  box-shadow: 0px 1px 3px inset var(--shadow-dark);
}

.current-avatar {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.current-user-avatar {
  width: 80px !important;
  height: auto;
  margin: 10px;
}
.avatars-container {
  display: flex;
  max-height: 220px;
  width: 100%;
  align-items: flex-start;
  overflow-y: auto;
  flex-flow: row wrap;
  justify-content: center;
}
.avatar-inner {
  width: 100px;
  height: 100px;
  margin: 5px;
  border-radius: 50%;
}
.avatar-inner:hover,
.avatar-inner:focus,
.avatar-inner.avatar-chosen {
  outline-color: var(--primary);
  outline-width: 4px;
  outline-offset: 0;
  outline-style: dashed;
}
.avatar-image {
  width: 100px;
  height: auto;
  margin: 10px;
}
.avatar-text {
  padding: 20px 0;
  font-size: var(--fs-md);
}
.middle-arrow {
  color: var(--primary);
  width: 30px !important;
  height: auto;
  align-self: center;
}
@media screen and (max-width: 1200px) {
  .inputs-container {
    width: 80%;
    padding: 100px 20px 20px;
    display: block;
  }
  .next-btn {
    font-size: var(--fs-sm);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .next-btn svg {
    margin: 0px -10px 0px;
  }
}

@media screen and (max-width: 600px) {
  .inputs-container {
    width: 100%;
  }

  .form-buttons {
    width: 100%;
  }

  .input-field {
    font-size: var(--fs-lg);
  }

  .short-container {
    flex-flow: column-reverse;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .modal {
    width: 90vw;
    left: 5vw;
  }
  .ms-book-bookcover {
    top: -20px;
    position: relative;
  }
  .modal.ms-book-modal {
    /* border: 2px solid var(--primary); */
    height: 90vh;
    top: 5vh;
    box-shadow: none;
    border-radius: 10px 10px 0 0;
  }
  .short {
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .record-info.small ul li {
    margin-top: 10px;
  }
  .modal {
    width: 90%;
    left: calc((100% - 90%) / 2);
    overflow: visible;
  }
  .login-modal {
    top: 100px;
  }
  .modal-actions {
    flex-direction: column-reverse;
    padding: 0 2rem;
  }
  .wide-btn svg {
    font-size: 1rem !important;
  }
  .event-modal {
    /* border: 2px solid var(--primary); */
    height: 90vh;
    top: 5vh;
  }

  .event-modal-text p {
    font-size: var(--fs-md);
  }

  .blog-modal-info,
  .event-modal-info {
    flex-flow: column;
    padding: 0;
  }
  .flex-1 {
    width: 100%;
    padding: 10px 20px;
  }
  .react-add-to-calendar__button {
    padding: 0 5px 0 0 !important;
  }
  .event-modal-icons {
    top: 60px;
  }

  .event-modal-icons svg {
    font-size: var(--fs-sm);
  }

  .blog-modal-info div {
    padding: 5px 0;
  }

  .blog-modal-inter-icons a svg,
  .event-modal-inter-icons a svg {
    font-size: 1.5rem;
  }

  .blog-modal-inter-icons a,
  .event-modal-inter-icons a {
    min-width: 50px;
    min-height: 50px;
  }

  .signout-modal {
    left: 5vw !important;
    width: 90vw !important;
    top: calc(100vh / 4) !important;
    box-shadow: 0 0 5px var(--primary), 0 0 10px var(--primary);
  }

  .dialogue-message {
    font-size: 1.4rem !important;
    padding: 20px !important;
  }

  .contact-modal,
  .library-login-modal {
    top: 5vw;
    height: 80vh;
    left: 5vw;
    width: 90vw;
    box-shadow: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
  }
  .contact-input-field,
  .input-field {
    padding: 5px 10px;
    font-size: var(--fs-md);
  }
  .contact-input-field {
    padding: 5px 10px;
    font-size: var(--fs-md);
  }
  .checkbox-outer label {
    justify-content: center;
  }
  .contact-form-outer {
    flex-flow: column;
  }
  .login-form {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    height: 90%;
  }
  .contact-form-buttons,
  .login-dialogue-buttons {
    flex-flow: column;
    padding-top: 0;
    /* position: absolute;
    bottom: 0;
    left: 0; */
    /* background: var(--card-color); */
  }
  .login-form .contact-form-buttons {
    padding: 20px 0 20px;
  }
  .arrow-icon {
    top: 48px;
    right: 16px;
  }
  .contact-input-field,
  .message-area {
    border-radius: 20px;
  }
  button.contact-btn {
    width: 100%;
    text-align: center;
    margin: 10px 0px;
  }
  .btn-sml {
    width: 100%;
  }

  .event-modal-tickets {
    top: 18px;
    font-size: 0.8rem;
  }
  .event-modal-tickets.full-text {
    top: 22px;
    font-size: 0.4rem;
    opacity: 0.5;
  }
  .checkbox-wrapper.checkall-wrapper {
    margin-left: 15px;
  }
  .renewall-wrp {
    padding: 10px;
  }
  .renewall-list {
    width: 100%;
    margin: 0;
    padding: 10px;
    max-height: unset;
  }
  .close-modal {
    position: absolute;
    right: 0;
  }
  .renewal-cover {
    margin: 0 5px 10px;
  }
  .contact-btn.small {
    width: 100%;
  }
  .renew-waiting {
    right: 20px;
    left: unset;
  }
  .video-title {
    font-size: var(--fs-sm);
    padding: 10px;
  }
  video {
    height: 85%;
    position: absolute;
    bottom: 0;
  }

  .video-modal {
    top: 5vw;
    left: 5vw;
    width: 90vw;
    box-shadow: none;
    margin: 0;
    padding: 0;
    min-height: 60vw;
  }
  .contact-form-input svg {
    top: 40px;
    right: 40px;
  }
}
@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  video {
    height: 25%;
    position: relative;
    top: 0;
  }
  .video-modal {
    top: 5vw;
    left: 5vw;
    /* width: 90vw; */
    max-height: 50vw;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 992px) and (orientation: landscape) {
}
@media only screen and (max-width: 360px) {
  .ms-book-bookcover {
    height: 200px;
    width: 130px;
  }
}

/* don't remove this is important to show brand icons via old awesome */

.react-add-to-calendar {
  /* width: 40px; */
  float: left;
}
.react-add-to-calendar__wrapper {
  width: auto;
}
.react-add-to-calendar__dropdown ul {
  list-style: none;
  background-color: var(--card-color);
  top: 35px;
  left: 20px;
  padding: 20px 20px 0;
  color: var(--secondary) !important;
  position: absolute;
  border: 1px solid var(--primary);
  box-shadow: 2px 2px 4px 1px var(--shadow-dark);
  text-align: left;
  font-size: var(--fs-sm);
  border-radius: 5px;
}
.react-add-to-calendar__dropdown ul li a {
  color: var(--secondary) !important;
}
.react-add-to-calendar__dropdown ul li a:hover {
  color: var(--primary) !important;
}
.react-add-to-calendar__dropdown ul li {
  padding-bottom: 15px;
}
.react-add-to-calendar__dropdown ul li a i {
  margin-right: 7px;
}

.react-add-to-calendar__button--light {
  color: var(--primary) !important;
}
.react-add-to-calendar__button {
  padding: 10px;
  cursor: pointer;
}

.shareButton-center {
  position: absolute;
  bottom: -35px;
  left: -5px;
  /* transform: translate(-50%, -50%); */
}
.share-menu {
  position: relative;
  width: 220px;
  height: 100px;
}
.share-menu .share-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  /* background: #000; */
  color: var(--primary);
  /* font-size: 30px; */
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}
.share-menu .share-btn-close {
  display: none;
}
.share-menu.active .share-btn-share {
  display: none;
}
.share-menu.active .share-btn-close {
  display: inline;
}
.share-menu .social-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: var(--card-color);
  color: var(--primary);
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  font-size: 1.7rem;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* Clipboard */
.share-menu.active div:nth-child(2) {
  top: 105%;
  left: 85%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* EMails */
.share-menu.active div:nth-child(3) {
  top: 125%;
  left: 68%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* WhatsApp */
.share-menu.active div:nth-child(4) {
  top: 120%;
  left: 51%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* Twitter */
.share-menu.active div:nth-child(5) {
  top: 125%;
  left: 36%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
/* Facebook */
.share-menu.active div:nth-child(6) {
  top: 105%;
  left: 20%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: transparent;
  transition: all 300ms cubic-bezier(0.41, 0.9, 0.53, 1.32);
}
button.react-share__ShareButton:focus,
.shareButton-outer {
  position: relative;
}
.share-menu-linear {
  display: none;
  justify-content: flex-start;
  align-items: center;
  width: 315px;
  height: 48px;
  background-color: var(--card-color);
}
.share-menu-linear.active {
  position: absolute;
  top: -7px;
  right: 8px;
  background: var(--card-color);
  z-index: 1;
  box-shadow: 0 0 5px 1px var(--shadow-dark);
  transition: right 0.3s ease-out;
  display: flex;
}
.share-btn svg {
  vertical-align: auto;
  height: 50px;
}
.shareButton-outer .share-btn {
  position: absolute;
  font-size: var(--fs-xl);
  right: 0;
  color: var(--primary);
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  /* z-index: 2; */
}
.share-menu-linear .social-btn {
  width: 50px;
  font-size: var(--fs-xl);
  color: var(--primary);
  border-right: 1px solid;
  cursor: pointer;
}
.shareButton-outer.active .share-btn-close {
  display: inline;
}
.shareButton-outer .share-btn-close {
  display: none;
}
.shareButton-outer.active .share-btn-share {
  display: none;
}

@media only screen and (max-width: 480px) {
  .share-menu-linear .social-btn {
    width: 15vw;
  }
  .shareButton-outer .share-btn {
    height: 15vw;
    line-height: 15vw;
  }
  .share-menu-linear {
    width: 90vw;
    height: 15vw;
  }
}
@media only screen and (max-width: 768px) {
  .share-menu-linear.active {
    right: 7px;
  }
}

.card-container {
  /* min-width: 290px;
  height: 445px; */
  margin: 10px 2.5px 40px;
  display: flex;
  flex-direction: column;
  /* border: 3px solid transparent; */
  position: relative;
  /* outline: none; */
  transition: all 0.3s ease-out;
  /* background-color: var(--card-color); */
  box-shadow: 1px 2px 5px 0px var(--shadow-dark);
}
.card-container:focus,
.mini-outer-container:focus {
  transition: none;
  z-index: 20;
}
.card-container:focus {
  outline: 4px solid var(--primary);
  outline-offset: 2px;
}
.mini-outer-container:focus {
  outline: 3px solid var(--primary);
  outline-offset: 1px;
}
.hidden-info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.desktop-card-container {
  display: flex;
  position: relative;
  background-color: var(--card-color);
  transition: all 0.2s ease-out;
  /* overflow: hidden; */
  cursor: auto;
  min-width: 750px;
  height: 540px;
  /* border: 3px solid var(--primary); */
  margin: -40px -20px -50px 0;
  z-index: 15;
  /* box-shadow: 0 0 30px var(--primary), 0 0 50px var(--primary); */
}
.mobile-card-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 15;
}

.outer-left-container img {
  /* width: 290px; */
  height: 100%;
}

.outer-right-container {
  height: 100%;
  width: 400px;
  position: relative;
  overflow: hidden;
}
.mobile-right-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  overflow: hidden;
}
.outer-left-container {
  min-height: 100%;
}

.active-outer-left-container {
  display: none;
}

.closecard-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
  color: var(--primary);
  min-width: auto;
  font-size: var(--fs-sm);
  margin: 10px;
  z-index: 999;
}

.closecard-icon {
  color: var(--primary);
}

.info-container {
  max-width: 320px;
  height: 75%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 30px;
  right: 70px;
  color: var(--secondary);
  padding: 20px;
  font-size: var(--fs-md);
  font-weight: 100;
  overscroll-behavior: contain;
}
.info-container::-webkit-scrollbar-track,
.chcp-wrp::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 5px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.info-container::-webkit-scrollbar,
.chcp-wrp::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.info-container::-webkit-scrollbar-thumb,
.chcp-wrp::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 10px var(--shadow-light);
  background-color: var(--primary);
}
.inter-icons-container {
  min-width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 110px;
  right: -1px;
}
.inter-icons-inner {
  flex: 1 1;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  padding: 5px 0;
}
.inter-icons-inner:disabled svg {
  opacity: 0.2;
  color: var(--secondary);
}
.inter-icons-inner:hover:not([disabled]) {
  cursor: pointer;
}
.inter-icons-inner:not([disabled]) svg:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.inter-icons-inner svg {
  font-size: var(--fs-xl);
  text-align: center;
  color: var(--primary);
}
.inter-arrow {
  font-size: var(--icn-jackets-small-arrow) !important;
}
.inter-icons {
  font-size: var(--icn-jackets-hor);
  margin: 0 5px 2px;
  text-align: center;
}
.inter-icon-wrp {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inter-icon-wrp.fade {
  opacity: 0.1;
}
.horizontal-format-icons.opened .hor-icon.selected-icon .inter-icons,
.horizontal-format-icons.opened .hor-icon.selected-icon .inter-name {
  color: var(--primary);
}
.horizontal-format-icons {
  width: 90%;
  /* height: 80px; */
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: -2px;
  right: 5%;
  background: var(--card-color);
  border-radius: 7px 7px 0 0;
  /* border-radius: 15px 15px 0 0; */
  transition: height 0.2s ease-out;
  border: 1px solid var(--primary);
  border-bottom: none;
  box-shadow: 0px 0px 4px var(--shadow-light);
}
.horizontal-format-icons.opened {
  height: 410px !important;
  box-shadow: 0px -1px 7px var(--shadow-dark);
}
.mobile-right-container .info-container {
  position: relative;
  top: 260px;
  right: auto;
  max-width: none;
  margin-right: 70px;
  height: calc(100% - 370px);
  font-size: var(--fs-md);
}
.mobile-right-container .horizontal-format-icons {
  position: relative;
  right: auto;
  bottom: 0;
  width: 90%;
  z-index: 100;
}
.dimmed-before {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
.dimmed {
  opacity: 0.1;
  transition: opacity 0.3s ease-out;
}
.info-container p {
  padding-bottom: 70px;
}
.details-panel {
  flex: none;
  width: 90%;
  /* display: none; */
  position: absolute;
  top: 20px;
  left: 5%;
}
.close-btn-small {
  color: var(--primary);
  font-size: var(--icn-close-btn);
  flex: none;
  position: absolute;
  right: 7px;
  top: 0;
  cursor: pointer;
}
p.small-title {
  color: var(--primary);
  font-size: var(--fs-sm);
  position: absolute;
  top: -20px;
}
.hor-icon {
  flex: 1 1;
  text-align: center;
  margin-bottom: 10px;
  color: var(--secondary);
  opacity: 0.2;
}
.hor-icon.selected-icon {
  color: var(--primary);
  opacity: 1;
}
.hor-icon:hover:not([disabled]) {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  cursor: pointer;
}
.inter-name {
  line-height: 1rem;
  font-size: var(--fs-xs);
}
.inter-name.bold {
  font-weight: bold;
}
.details-panel-text {
  color: var(--secondary);
  font-size: var(--fs-md);
  padding-bottom: 10px;
  text-align: center;
}
.details-panel-text button {
  color: var(--primary);
  text-decoration: underline;
  font-weight: bold;
  font-size: var(--fs-md);
}
.details-panel-text button:hover {
  cursor: pointer;
}
.book-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: var(--book-shading);
}

.loans-info-div {
  width: 100%;
  height: 50%;
  position: absolute;
  font-weight: 100;
  top: 0;
  right: 0;
  color: var(--secondary);
  padding: 30px;
  font-size: var(--fs-sm);
}
.loans-info-div ul li {
  list-style: none;
  padding-bottom: 10px;
}
.loans-info-div ul li p {
  padding-left: 50px;
  max-height: 40px;
  overflow-y: scroll;
}
.loans-info-title {
  font-size: 1.5rem;
  color: var(--primary);
  text-align: center;
  padding-bottom: 30px;
}
.mobile-right-container .loans-info-div ul li {
  padding-bottom: 20px;
}
.mobile-right-container .loans-icons-div {
  padding: 5px;
}
.mobile-right-container .loans-icon {
  width: clamp(50px, 18vw, 80px);
  height: clamp(50px, 18vw, 80px);
}
.loans-icons-div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 10px 100px 10px 40px;
  position: absolute;
  bottom: 10px;
  background: var(--card-color);
}
.loans-icons-div button,
.loans-icons-div a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}
.loans-icons-div p {
  font-size: 0.9rem;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
}
.loans-icons-div svg {
  font-size: var(--fs-xl);
  margin-bottom: 10px;
}
.loans-icon {
  text-align: center;
  border: 1px solid var(--primary);
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  width: 80px;
  height: 80px;
}
.loans-icon:hover,
.loans-icon:focus {
  background: var(--primary);
  color: #fff;
}
.loans-icon.active {
  background: var(--primary);
  color: #fff;
  cursor: unset;
}
.reservation-icon {
  border: 1px solid var(--primary);
  padding: 10px;
  cursor: pointer;
  color: var(--primary);
  width: 70%;
  height: 50px;
  margin: 0 auto;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-sm);
  font-weight: bold;
}
.reservation-icon svg {
  color: var(--primary);
  font-size: var(--fs-xl);
  margin: 0.5rem;
}

.loan-format {
  width: 55px;
  margin: 0 auto;
}
.warning-orange {
  color: orange !important;
}
.warning-red {
  color: rgb(200, 55, 55) !important;
}
#pickupLocation {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: #fff;
  border: 1px solid var(--primary);
  color: #000;
  overflow: hidden;
  width: 100%;
  font-size: 0.8rem;
  padding: 5px;
  box-shadow: 0px 1px 3px inset var(--shadow-dark);
}
.pickuplocation-div {
  width: 100%;
  padding: 10px 0;
}
.pickuplocation-text {
  padding-top: 10px;
  color: var(--secondary);
}
.copies-list {
  padding: 5px 0 20px 20px;
  color: var(--secondary);
  list-style: none;
  font-size: 0.8rem;
  overflow-y: auto;
  height: 100%;
}
.copies-list li {
  padding: 10px 0;
  padding-left: 15px;
  position: relative;
  border-bottom: 1px dashed var(--primary);
  font-weight: bold;
}
.copies-list li p {
  font-weight: 100;
}
.copies-list li span {
  opacity: 0.4;
}
.copies-list li span.available {
  color: #1fa21f;
  opacity: 1;
  font-weight: bold;
}
.copies-div {
  overflow: hidden;
  padding: 0 10px;
  margin: 0 10px;
  height: 200px;
  position: relative;
}
.copies-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 5px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.copies-list::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.copies-list::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 10px var(--shadow-light);
  background-color: var(--primary);
}
.copies-overlay::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    var(--card-color) 100%
  );
  z-index: 1;
}
.details-inner {
  /* padding: 10px; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.details-panel-button {
  font-weight: bold;
  color: var(--primary);
  padding: 5px;
  cursor: pointer;
}
.copies-count {
  color: var(--primary);
  opacity: 0.4;
  position: absolute;
  left: -20px;
  font-size: 1.5rem;
  font-weight: 900;
  width: 30px;
  text-align: right;
}
.details-panel-msg {
  width: 100%;
  text-align: center;
  /* line-height: 8rem; */
}
.wide-btn {
  width: 60%;
  color: var(--primary);
  background-color: var(--background-color);
  display: flex;
  margin: 20px auto;
  padding: 10px;
  align-items: center;
  justify-content: space-around;
  border: 1px solid;
  /* border-radius: 30px; */
  z-index: 1;
}
.wide-btn:hover:not([disabled]) {
  color: var(--background-color);
  border-color: var(--background-color);
  background-color: var(--primary);
  cursor: pointer;
}
.wide-btn svg {
  float: right;
  width: 10%;
}
.wide-btn p {
  text-align: center;
  width: 100%;
  font-size: var(--fs-sm);
}
.placeholder-container {
  position: relative;
  color: var(--secondary);
  outline: 1px solid var(--secondary);
  background-color: var(--card-color);
  width: 100%;
  height: 100%;
  /* width: 290px;
  height: 445px; */
  margin: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.3;
  /* overflow-wrap: break-word; */
  padding: 15px;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.placeholder-container p {
  /* font-weight: 100; */
  font-size: var(--fs-sm);
  width: 100%;
}

.panel-tab.selected button {
  color: var(--primary);
}
.panel-tab button {
  color: var(--secondary);
  font-size: var(--fs-md);
  cursor: pointer;
  font-weight: 600;
}

.details-panel-wrapper {
  width: 100%;
  margin: 0 auto;
}
.details-panel-tabs {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 20px 0;
}
.panel-tab {
  text-align: center;
  padding-bottom: 5px;
  width: 50%;
}
.panel-tab:hover,
.panel-tab.selected {
  border-bottom: 3px solid var(--primary);
  cursor: pointer;
}

.chcp-wrp {
  width: 80%;
  position: absolute;
  top: 0;
  padding: 30px 40px 0 0;
  font-size: var(--fs-sm);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
}
.chcp-wrp h1,
.checkout-note h1 {
  font-size: 1.4rem;
  padding-bottom: 20px;
  font-weight: bold;
}
.chcp-wrp button {
  font-size: var(--fs-sm);
  padding: 10px;
  margin-top: 15px;
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: var(--background-color);
}
.chcp-wrp button:hover:not([disabled]),
.chcp-wrp button:focus {
  cursor: pointer;
  background-color: var(--primary);
  color: var(--background-color);
}
.chcp-wrp button:disabled,
.wide-btn:disabled {
  opacity: 0.3;
  color: var(--secondary);
  border-color: var(--secondary);
}
.chcp-btns {
  display: flex;
  flex-flow: row nowrap;
}
.chcp-btn {
  flex: 1 1;
  margin: 15px 5px;
}
.chcp-wrp button svg {
  float: right;
  font-size: 1.4rem;
}

.mini-card-container {
  min-width: 145px;
  height: 224px;
  margin: 3px 3px 45px;
  display: flex;
  position: relative;
  background-color: var(--card-color);
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  box-shadow: 1px 2px 3px 0 var(--shadow-dark);
}

.mini-card-container .mini-icons-beneath {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

img.mini-cover {
  width: 145px;
  max-width: 145px;
  height: 100%;
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}
img.mini-cover.grayscale {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.mini-placeholder-container {
  color: var(--secondary);
  outline: 1px solid var(--secondary);
  background-color: var(--card-color);
  width: 145px;
  min-width: 145px;
  height: 224px;
  margin: 3px 3px 45px;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.3;
  padding: 5px;
}
.mini-placeholder-container p {
  font-weight: 100;
  font-size: var(--fs-md);
}
/* -----------------------------------  stars rating styling */
.stars-mini-container-wrap {
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: 10;
  width: 90%;
  /* margin-left: -2px; */
}
img.mini-stars-background {
  position: absolute;
  left: 0;
  bottom: -8px;
  z-index: -1;
  width: 100%;
  height: auto;
}
.stars-mini-inner-wrap {
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  position: absolute;
  left: 10%;
  top: 0px;
  background-color: var(--primary);
  width: 80%;
  height: 40px;
  text-align: center;
  box-shadow: 3px 3px 4px 0px var(--shadow-dark);
}
.mini-book-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.3) 1%,
    rgba(255, 255, 255, 0.1) 4%,
    rgba(0, 0, 0, 0.3) 89%,
    rgba(255, 255, 255, 0.3) 94%,
    rgba(0, 0, 0, 0.2) 99%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
.stars-mini-container-wrap span {
  /* padding-left: 3px; */
}
.stars-mini-inner-wrap span {
  padding: 8px;
}
.stars-mini-container {
  position: relative;
  display: inline-block;
  color: transparent;
  font-size: var(--fs-md);
}
.stars-mini-container:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "☆☆☆☆☆";
  color: #fff;
}
.stars-mini-container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: #fff;
  overflow: hidden;
}
/* --------------------------------------- end of stars rating */

/* ----------------------------- icons underneath ------------------------- */
.mini-formats-outside {
  margin-left: 5px;
  /* width: 15px;
  height: 15px; */
}
.mini-formats-outside.active {
  background-color: var(--success-green);
  border-radius: 35px;
  margin-left: 6px;
}
.mini-formats-outside.active svg {
  color: #000;
  margin: 3px;
}
.mini-formats-outside.active p {
  color: #000;
  margin: 3px 7px;
}
.mini-outer-icons {
  color: var(--secondary);
  font-size: var(--fs-sm);
  margin: 4px;
  text-align: center;
}
.mini-icons-beneath {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -10%;
  left: 0;
  height: 10%;
  z-index: -1;
}
.pub-year.grayscale {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.mini-icons-beneath .pub-year {
  color: #fff;
  background-color: var(--primary);
  font-size: var(--fs-sm);
  overflow: hidden;
  box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  -webkit-clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
}
.mini-outer-formats-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.mini-outer-formats-container svg {
  font-size: var(--icn-formats);
}
/* ---------------------------  finish icons underneath  ---------------------------- */

.to-hide {
  display: none;
}

/* -----------------------------------  stars rating styling */
.stars-container-wrap {
  color: #fff;
  font-size: var(--fs-sm);
  display: inline-block;
  position: absolute;
  left: 2px;
  bottom: 30px;
  z-index: 10;
  width: 55%;
  margin-left: -2px;
}
img.stars-background {
  position: absolute;
  left: 0;
  bottom: -8px;
  z-index: -1;
  width: 100%;
  height: auto;
}
.stars-inner-wrap {
  color: #fff;
  font-size: var(--fs-sm);
  display: inline-block;
  position: absolute;
  left: 10%;
  top: 0px;
  background-color: var(--primary);
  width: 80%;
  height: 40px;
  text-align: center;
  box-shadow: 3px 3px 4px 0px var(--shadow-dark);
}
.stars-container-wrap span {
  padding: 4px;
}
.stars-inner-wrap span {
  padding: 8px;
}
.stars-container {
  position: relative;
  display: inline-block;
  color: transparent;
  font-size: 1.5rem;
}
.stars-container:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "☆☆☆☆☆";
  color: #fff;
}
.stars-container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: #fff;
  overflow: hidden;
}
.stars-0:after {
  width: 0%;
}
.stars-1:after {
  width: 10%;
}
.stars-2:after {
  width: 20%;
}
.stars-3:after {
  width: 30%;
}
.stars-4:after {
  width: 40%;
}
.stars-5:after {
  width: 50%;
}
.stars-6:after {
  width: 60%;
}
.stars-7:after {
  width: 70%;
}
.stars-8:after {
  width: 80%;
}
.stars-9:after {
  width: 90%;
}
.stars-10:after {
  width: 100%;
}
/* --------------------------------------- end of stars rating */

/* ----------------------------- icons underneath ------------------------- */
.formats-outside {
  margin-left: 5px;
  width: 25px;
  height: 25px;
}
.formats-outside.active {
  background-color: var(--success-green);
  border-radius: 50px;
  margin-left: 5px;
}
.formats-outside.active svg {
  color: #000;
}
.outer-icons {
  color: var(--secondary);
  font-size: 2.5rem;
  margin: 4px;
  text-align: center;
}
.icons-beneath {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.icons-beneath.short {
  bottom: -20px;
}
.icons-beneath .pub-year {
  color: #fff;
  background-color: var(--primary);
  overflow: hidden;
  box-shadow: inset 0px 3px 5px 0px var(--shadow-dark);
  -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  padding: 1% 10% 1% 3%;
}
.outer-formats-container {
  display: flex;
  justify-content: flex-end;
}
.outer-formats-container svg {
  font-size: var(--icn-formats);
}
/* ---------------------------  end of icons underneath  ---------------------------- */

/* ---------------------------  the flipper inside  ------------------------- */
.jacket {
  width: 350px;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
  z-index: 100;
  position: relative;
}
.back {
  background-color: var(--card-color);
  font-size: large;
  color: var(--secondary);
}
.record-info {
  font-size: var(--fs-sm);
  /* overflow: hidden; */
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: left;
  padding: 30px;
}
.record-info ul {
  overflow-y: hidden;
}
.record-info ul li {
  margin-top: 15px;
  list-style: none;
}
.record-info.small {
  font-size: 0.7rem;
  padding: 5px;
}
.record-info.small ul li {
  margin-top: 5px;
}
.front {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.front img {
  width: 350px;
  height: 100%;
}
.jacket.back div::after {
  content: "";
  background-color: var(--background-color);
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
/* ---------------------------  end of the flipper inside  ---------------------------- */

/* ---------------------------  the peel corner effect  ------------------------- */
.jacket::before {
  background: rgba(0, 0, 0, 0)
    linear-gradient(
      315deg,
      var(--card-color) 45%,
      #aaaaaa 50%,
      #cccccc 56%,
      white 80%
    )
    repeat scroll 0 0; /*display smooth transitions between two or more specified colors */
  bottom: 0;
  box-shadow: -1px -1px 6px var(--shadow-light);
  content: "";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition-duration: 0.3s;
  transition-property: width, height;
  width: 0;
}
.jacket:hover::before {
  height: 70px;
  width: 70px;
}
.jacket-wrap {
  /* z-index: 1000; */
  height: 100%;
  width: 100%;
}
/* ---------------------------  end of the peel corner effect  ------------------------- */
.past-loan-wrp {
  position: absolute;
  color: var(--secondary);
  z-index: 1;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  padding: 10px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
.past-loan-wrp p {
  opacity: 1;
  font-size: var(--fs-md);
  text-align: center;
  transition: opacity 0.3s ease-out;
}
.mini-card-container:hover .past-loan-wrp,
.mini-card-container:hover .past-loan-wrp p {
  opacity: 0;
}
.mini-card-container:hover .mini-outer-container {
  -webkit-filter: brightness(1) !important;
          filter: brightness(1) !important;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.nice-dates-day:before {
  background-color: var(--primary) !important;
}
.nice-dates-navigation {
  color: var(--primary) !important;
}

.nice-dates-navigation_previous.-disabled:before,
.nice-dates-navigation_next.-disabled:before {
  opacity: 0.3;
}

.nice-dates-navigation_previous:not([disabled]):before,
.nice-dates-navigation_next:not([disabled]):before {
  border-color: var(--primary) !important;
}

.checkout-note {
  background-color: var(--primary);
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.checkout-note p {
  padding: 0;
}
.pickup-note {
  padding: 15px !important;
  background-color: var(--success-green);
  color: #fff;
  border-radius: 5px;
}
/* Extra Small Devices, Phones */
@media screen and (min-width: 769px) {
  .card-container:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
    z-index: 16;
    box-shadow: 2px 2px 8px 3px var(--shadow-dark);
  }
  .card-container .icons-beneath {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
  .card-container:hover .icons-beneath {
    opacity: 0;
  }
  .mini-card-container:hover {
    cursor: pointer;
    z-index: 21;
    -webkit-transform: scale(1.25) translate(0, 5px);
            transform: scale(1.25) translate(0, 5px);
    box-shadow: 4px 2px 9px 1px var(--shadow-dark);
  }
  .mini-card-container:hover .mini-icons-beneath {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .loans-info-div {
    position: relative;
    top: 260px;
    width: 90%;
    left: 0;
    max-width: none;
    margin-right: 70px;
    height: calc(100% - 370px);
    font-size: var(--fs-md);
  }
  .loans-icons-div p {
    font-size: clamp(0.7rem, 2.5vw, 0.9rem);
    bottom: 0;
  }
  .loans-icons-div svg {
    font-size: clamp(1.4rem, 10vw, var(--fs-xl));
  }
  .chcp-wrp {
    padding: 30px 0;
    overflow: auto;
  }
  .inter-name {
    font-size: clamp(0.1rem, 2vw, 2rem);
  }
  .hor-icon svg {
    font-size: clamp(1rem, 4vw, 3rem);
  }
  .card-container:hover,
  .mini-outer-container:hover {
    outline: 3px solid var(--primary);
  }
  .mini-outer-container img {
    width: 100px;
  }
  .mini-card-container,
  .mini-placeholder-container {
    min-width: 100px;
    width: 100px;
    height: 160px;
    margin: 3px 3px 55px;
  }
  .mini-placeholder-container p {
    font-size: var(--fs-sm);
  }
  .icons-beneath,
  .mini-icons-beneath {
    display: block !important;
  }
  .mini-icons-beneath .pub-year {
    padding: 0;
    font-size: var(--fs-md);
    font-weight: 100;
    text-align: center;
    border-radius: 0 0 10px 10px;
    -webkit-clip-path: none;
    clip-path: none;
    width: 100px;
    min-height: 8px;
  }
  .mini-outer-formats-container {
    position: unset;
  }

  .mini-outer-formats-container svg {
    font-size: var(--icn-formats);
    margin: 3px;
  }
  .stars-mini-container {
    font-size: 0.8rem;
  }
  img.mini-stars-background {
    bottom: -6px !important;
    width: 96% !important;
  }
  .stars-mini-container-wrap span {
    font-size: 0.8rem;
    padding: 4px 0;
  }
  .placeholder-container {
    width: 100px;
    height: 160px;
    /* margin: 40px 3px; */
    padding: 40px 5px;
    /* text-align: left; */
  }
  .placeholder-container p {
    word-wrap: break-word;
    -webkit-hyphens: auto;
            hyphens: auto;
  }
  .inter-icons-container {
    right: 0;
  }
  .wide-btn p {
    font-size: var(--fs-sm);
  }
  p.small-title {
    font-size: clamp(0.7rem, 3vw, 1.1rem);
    line-height: clamp(0.7rem, 3vw, 1.1rem);
    font-weight: 600;
  }
  .outer-left-container:before {
    display: none;
  }
  .outer-left-container img {
    width: 105px;
  }
  .card-container {
    /* min-width: 100px;
    height: 160px; */
    margin: 3px 3px 45px;
  }
  .icons-beneath .pub-year {
    width: auto;
    padding: 0;
    font-size: var(--fs-md);
    font-weight: 100;
    text-align: center;
    border-radius: 0 0 10px 10px;
    -webkit-clip-path: none;
    clip-path: none;
  }
  .outer-formats-container {
    top: 20px;
    right: unset;
    left: 0;
    width: 100%;
  }
  .formats-outside {
    width: 20px;
    height: 20px;
  }
  .outer-formats-container svg {
    font-size: var(--icn-formats);
    margin: 3px;
  }
  .stars-container {
    font-size: 0.8rem;
  }
  img.stars-background {
    bottom: -6px !important;
    width: 57% !important;
  }
  .stars-container-wrap span {
    font-size: 0.8rem;
    padding: 4px 0;
  }
  .panel-tab button,
  .details-panel-text {
    font-size: var(--fs-sm);
  }
  .horizontal-format-icons.opened {
    height: 900px !important;
  }
  .copies-div.opened {
    margin: -15px 70px 0;
  }
}

@media only screen and (max-width: 568px) {
  .copies-div.opened {
    margin: -15px 30px 0;
  }
  .inter-icons {
    font-size: var(--fs-lg);
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 480px) {
  .chcp-btns {
    flex-direction: column;
  }
  .copies-div.opened {
    margin: -15px 10px 0;
  }
  .wide-btn {
    width: 100%;
  }
}

.books-row {
  position: relative;
  margin-bottom: 60px;
}
.skip-link-wrp {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: absolute;
  top: 0;
  width: 100%;
}
.skip-link-div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.empty-row {
  display: flex;
}
.mini-books-row {
  position: relative;
  height: 230px;
}
.rows-wrapper {
  /* margin-top: 540px; */
  position: relative;
  /* top: -100px; */
  /* margin-top: auto; */
  overflow: hidden;
}
h2.row-title {
  font-size: var(--fs-row-titles);
  color: var(--primary);
  font-weight: 300;
  padding: 0 10px 0 30px;
}
h2.row-title.mini-row-title {
  font-size: var(--fs-xl);
}
.row-title span {
  font-size: var(--fs-sm);
  font-weight: bold;
}
.rows-container {
  width: 100%;
  overflow: hidden;
}

.lsp-positioner-bs {
  width: 100%;
  z-index: 1;
}

.history-icon {
  color: var(--primary);
  /* min-width: 290px;
  min-height: 450px; */
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.history-icon svg {
  font-size: var(--icn-desktop-lg);
}
.history-icon p {
  font-size: var(--fs-md);
  text-align: center;
}

.loader-container {
  position: relative;
  top: 37%;
  left: 40%;
}

@media only screen and (max-width: 992px) {
  .history-icon {
    min-width: 100px;
    min-height: 160px;
  }
  .history-icon svg {
    font-size: var(--icn-desktop-lg);
  }
  .history-icon p {
    font-size: var(--fs-sm);
  }
}
@media only screen and (max-width: 768px) {
  .books-row {
    margin-bottom: 30px;
    min-height: 160px;
  }
  .books-row .slick-slider.slick-initialized {
    height: 210px;
  }
  .loader-container {
    top: 80px;
    left: 35px;
  }
  h2.row-title {
    font-size: var(--fs-lg);
    padding: 0 10px;
  }
  h2.row-title.mini-row-title {
    font-size: var(--fs-lg);
  }
}

.lsp-slider-bar {
  position: relative;
  width: 100%;
}

.lsp-slider-wrap a,
.first-row-outer a,
.second-row-outer a {
  display: block;
}

.lsp-slider-wrap {
  /* display: flex;
  justify-content: space-between; */
  padding-left: 7px;
  color: var(--secondary);
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.lib-serv-bar .slick-slide a,
.lib-evn-bar .slick-slide a {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 84px;
  padding: 10px 5px 25px;
}

.lsp-links p,
.service-name p,
.sub-service-name p {
  font-size: var(--fs-sm);
  line-height: var(--fs-md);
  font-weight: bold;
}
li.lsp-links {
  height: 80px;
}
.tabs-loading {
  position: absolute;
  bottom: 8px;
  left: calc((100% - 36px) / 2);
  opacity: 0.6;
}
.subtabs-loading {
  position: absolute;
  bottom: 8px;
  left: calc((100% - 36px) / 2);
  opacity: 0.6;
}
.lsp-links.selected {
  color: var(--primary);
  border: 3px solid var(--primary);
  height: 80px;
  padding: 10px 0;
  /* line-height: 1.4rem; */
  background-color: var(--background-color);
}

.selected a {
  color: var(--primary);
}

.lsp-links.selected:hover {
  /* background-image: none;
  cursor: default; */
}

.lsp-links:hover {
  cursor: pointer;
}
.arrow-outer {
  display: flex;
  align-items: flex-end;
  opacity: 0;
  height: 84px;
  width: 100%;
  position: absolute;
  top: 0;
}
.arrow-outer:hover {
  opacity: 1;
}
.lsp-leftbtn-containter.ad-carousel-lft {
  top: 0;
  left: 0;
  background: var(--left-arrow-bg);
  height: 100%;
  width: 15%;
}
.lsp-rightbtn-containter.ad-carousel-rht {
  top: 0;
  right: 0;
  background: var(--right-arrow-bg);
  height: 100%;
  width: 15%;
}
.lsp-leftbtn-containter.ad-carousel-lft .carousel-left {
  opacity: 1;
  left: auto;
  right: 0;
}
.lsp-rightbtn-containter.ad-carousel-rht .carousel-right {
  opacity: 1;
  right: auto;
  left: 0;
}
.lsp-leftbtn-containter {
  background: var(--left-arrow-bg);
  width: 10%;
  height: 100%;
  position: absolute;
  left: -2px;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: var(--icn-desktop-lg);
}
.lsp-leftbtn-containter.hidden,
.lsp-rightbtn-containter.hidden {
  display: none;
}
.lsp-leftbtn-containter:hover:not([disabled]) .carousel-left,
.lsp-rightbtn-containter:hover:not([disabled]) .carousel-right {
  opacity: 1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.lsp-leftbtn-containter:hover,
.lsp-rightbtn-containter:hover {
  cursor: pointer;
}

.lsp-rightbtn-containter {
  background: var(--right-arrow-bg);
  width: 10%;
  height: 100%;
  position: absolute;
  right: -1px;
  /* top: 0; */
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: var(--icn-desktop-lg);
}
.lsp-leftbtn-containter:focus,
.lsp-rightbtn-containter:focus {
  outline-offset: -3px;
}

button:disabled .carousel-left,
button:disabled .carousel-right {
  color: var(--secondary);
  cursor: default;
}
.carousel-left,
.carousel-right {
  position: absolute;
  height: 100%;
  color: var(--primary);
  z-index: 5;
  cursor: pointer;
  display: block;
  opacity: 0.5;
}
.carousel-right {
  right: 10px;
}
.carousel-left {
  left: 10px;
}
.selected .pointy {
  width: 35px;
  height: 15px;
  background-color: var(--primary);
  position: absolute;
  bottom: -15px;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
          clip-path: polygon(50% 100%, 0 0, 100% 0);
}

@media only screen and (max-width: 768px) {
  .arrow-outer {
    height: 80px;
  }
  .lsp-links p,
  .service-name p,
  .sub-service-name p {
    font-size: var(--nav-fs-lg);
    line-height: 0.9rem;
  }
  .lib-serv-bar .slick-slide a,
  .lib-evn-bar .slick-slide a {
    align-items: center;
    padding: 10px 0;
  }
  .selected {
    height: auto;
    line-height: normal;
  }
  .lsp-links:hover {
    background-image: none;
  }
  .tabs-loading {
    position: absolute;
    bottom: 8px;
    left: calc((100% - 24px) / 2);
    opacity: 0.6;
  }
}
@media only screen and (max-width: 992px) {
  .lsp-leftbtn-containter,
  .lsp-rightbtn-containter {
    /* width: 10%; */
  }
}

.campaignslider-wrapper {
  width: 100%;
  background-color: transparent;
  background-image: var(--promo-carousel-bg);
  position: relative;
  padding-bottom: 20px;
}
.promo-placeholder {
  min-height: 200px;
}
.promo-background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--background-color);
  width: 100%;
  height: 15%;
  z-index: 2;
  opacity: 1;
}
.promo-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  filter: blur(30px);
  -webkit-filter: blur(30px);
  z-index: -1;
}
.promo-btn-surround {
  color: var(--secondary);
  text-align: left;
  width: 100%;
}
.promo-btn-surround:hover,
.promo-link-surround:hover,
.promo-link-surround:focus {
  cursor: pointer;
  display: block;
}
.disabled-link {
  pointer-events: none;
}
.inner-promo {
  position: relative;
  color: var(--secondary);
  height: 100%;
  padding: 140px 10%;
}
.inner-promo p {
  font-size: var(--fs-md);
}
.inner-promo h1 {
  font-size: var(--fs-xxl);
}
.inner-promo h2 {
  font-size: var(--fs-lg);
  font-weight: 500;
  padding-bottom: 20px;
}
.ad-image-container {
  /* min-height: 300px; */
  width: 100%;
  aspect-ratio: 1/1.53;
}
.ad-full-height-img {
  height: 100%;
}
.ad-image {
  /* width: 100%;
  height: auto; */
  width: auto;
  max-height: 300px;
  max-width: 100%;
  margin: 15px auto;
}
.campaignslider-wrapper .slick-dots {
  bottom: 20px;
}
.carousel-control {
  cursor: pointer;
  color: var(--primary);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 70px;
  right: calc(50vw - 10px);
}

.slick-dots li button:before {
  font-size: var(--fs-sm);
  color: var(--primary);
}
.slick-dots li.slick-active button:before {
  color: var(--primary);
}
.slick-dots li button:focus {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
}
.promo-btns-wrapper {
  width: 100%;
}
.promo-btns-wrapper .promo-btn {
  width: 150px;
}
.promo-btn {
  border: 1px solid var(--primary);
  border-radius: 25px;
  padding: 2px 10px;
  font-size: var(--fs-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  cursor: pointer;
  margin: 30px auto 0;
}
.promo-btn:hover svg {
  color: #fff;
}
.promo-btn:hover,
.promo-btn:focus {
  color: #fff;
  background-color: var(--primary);
  border: 1px solid var(--secondary);
  color: #fff;
}

.promo-btn svg {
  margin-left: 20px;
  padding: 0;
  font-size: var(--fs-xl);
  color: var(--primary);
}

.promo-small-icons {
  margin-right: 6px;
  float: left;
}

.promo-features {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.promo-medium-icons {
  margin-right: 10px;
  font-size: var(--icn-jackets-hor);
  float: left;
}

.promo-keywords {
  margin-top: 10px;
}

.promo-keywords p {
  font-size: var(--fs-xs);
  display: inline-block;
  margin-right: 10px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}

.inner-promo-icons-container {
  display: flex;
  justify-content: space-between;
  width: 120px;
}
.inner-promo-icons-container button {
  background-color: transparent;
  border: none;
}

.inner-promo-icons-container svg {
  color: var(--primary);
}

.inner-promo-icons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .inner-promo {
    padding: 120px 10px 0;
  }
  .slick-dots li button:before {
    font-size: 0.5rem;
  }
  .slick-dots li {
    margin: 0;
  }
  .inner-promo p {
    font-size: var(--fs-sm);
  }
  .campaignslider-wrapper .slick-dots {
    position: unset;
  }
  .carousel-control {
    bottom: 42px;
  }
  .ad-image-container {
    max-height: 250px;
  }
}

@media only screen and (max-width: 992px) {
  .ad-image {
    width: auto;
    max-height: 350px;
  }
  .inner-promo h1 {
    font-size: var(--fs-xl);
  }
  .inner-promo h2 {
    font-size: var(--fs-md);
    padding-bottom: 20px;
  }
  .promo-btns-wrapper .promo-btn {
    margin: 25px auto;
    padding: 0px 10px;
    font-size: var(--fs-sm);
  }

  .inner-promo-btn svg {
    margin: 7px;
  }

  .promo-features {
    flex-flow: column;
  }

  .promo-features div p {
    height: auto;
    font-weight: 500;
    padding-top: 10px;
  }

  .promo-keywords p {
    height: auto;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .ad-image {
    width: auto;
    max-height: 200px;
    display: inline-flex !important;
  }
  .inner-promo h1 {
    font-size: var(--fs-xl);
  }

  .inner-promo h2 {
    font-size: var(--fs-md);
  }
  .inner-promo-left img {
    width: 70%;
  }
  .inner-promo-icons-wrapper {
    position: relative;
  }
  .inner-promo-icons-container {
    width: 70px;
    top: 20px;
  }
  .carousel-icons {
    font-size: var(--fs-lg);
    padding: 0;
    margin: 0;
  }
  .inner-promo-btn {
    width: 120px;
    border: 1px solid var(--primary);
  }
  .inner-promo-btn a {
    font-size: var(--fs-xs);
  }
  .inner-promo-btn svg {
    font-size: var(--fs-md);
    margin: 15px 5px;
  }
}
@media only screen and (max-width: 425px) {
  .carousel-control {
    font-size: var(--fs-xl);
    position: initial;
    display: block;
    margin: -5px auto -15px;
    width: 30px;
    height: 30px;
  }
}

.search-outer-container {
  padding-top: 250px;
  position: relative;
}

.filters-panel {
  width: 0;
  color: #fff;
  background-color: var(--background-color);
  opacity: 0;
  left: -20vw;
  /* position: relative; */
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  padding: 20px 30px 20px 20px;
  transition: all 0.5s ease-out;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.filters-panel.active {
  width: 20vw;
  min-width: 300px;
  max-width: 450px;
  left: 0;
  z-index: 10;
  /* box-shadow: 3px 3px 4px 0px var(--shadow-light); */
  opacity: 1;
}
.results-panel {
  width: 80vw;
  min-width: calc(100vw - 500px);
  transition: all 0.5s ease-out;
  margin-top: -3px;
  flex: 1 1;
}
.search-wrapper {
  width: 100%;
  display: flex;
  /* padding-top: 15px; */
  transition: all 0.3s ease-out;
}

.search-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 30px 0;
}

.sorting-bar-wrp {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  z-index: 99;
  width: 100%;
  margin: 160px auto 20px;
}
.sorting-bar {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  padding: 5px 40px;
  margin: 0 auto;
  border-bottom: 1px solid var(--primary);
}
.sorting-bar-element {
  padding: 10px 0;
  display: flex;
}
.filters-search-form {
  display: flex;
  flex-flow: row wrap;
}
.filters-labels {
  color: var(--primary);
  font-weight: 500;
  font-size: var(--fs-sm);
}
.sorting-title,
.sorting-checkbox label {
  color: var(--primary);
  font-weight: 500;
  font-size: var(--fs-sm);
}

.sorting-title span {
  color: var(--secondary);
  /* padding-right: 40px; */
  font-weight: bold;
}

h4 span {
  color: var(--secondary);
  font-weight: 100;
}

.filters-search-container {
  padding: 10px 0;
  right: 0;
  position: relative;
  margin: 0 3px;
}

.filters-search-icon {
  position: absolute;
  left: 10px;
  top: 40px;
  color: rgb(180, 180, 180);
  font-size: var(--fs-xl);
}

.filters-panel #search-input {
  padding: 10px 10px 10px 50px;
  font-size: var(--fs-md);
  border-radius: 30px;
  width: 100%;
  margin: 5px 0;
  border: none;
  box-shadow: inset 0px 0px 4px 0px var(--shadow-dark);
}

.middle-title {
  color: var(--primary);
  font-weight: 500;
  text-align: center;
}

.filter-list {
  list-style: none;
  color: var(--secondary);
  /* height: 160px; */
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
  /* overscroll-behavior: contain; */
  margin-bottom: 20px;
}
.avatars-container::-webkit-scrollbar-track,
.preference-list::-webkit-scrollbar-track,
.filter-list::-webkit-scrollbar-track,
.renewall-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--shadow-light);
  background-color: var(--secondary);
  border-left: 5px solid var(--card-color);
  border-right: 4px solid var(--card-color);
}
.avatars-container::-webkit-scrollbar,
.preference-list::-webkit-scrollbar,
.filter-list::-webkit-scrollbar,
.renewall-list::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: var(--secondary);
}
.avatars-container::-webkit-scrollbar-thumb,
.preference-list::-webkit-scrollbar-thumb,
.filter-list::-webkit-scrollbar-thumb,
.renewall-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px var(--shadow-light);
  background-color: var(--primary);
}

.filters-cat-title {
  /* color: var(--primary); */
  font-size: var(--fs-sm);
  padding: 0 20px 5px 0;
}

.filters-categories {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
}

.filters-cat-col1,
.filters-cat-col2 {
  flex: 1 1;
}
.filters-cat-window {
  margin: 0 10px;
  position: relative;
}
.arrow-up:hover,
.arrow-down:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.arrow-up,
.arrow-down {
  font-size: var(--icn-desktop-arrows);
  display: block;
  color: var(--primary);
  cursor: pointer;
  line-height: 1rem;
}
.arrow-up {
  margin: 0 auto -15px;
}
.arrow-down {
  margin: -15px auto 15px;
}
.sorting-checkbox {
  padding: 0 25px !important;
}
hr {
  border: 1px solid var(--primary);
}

/*------------------------ styling the checkboxes -----------------------*/
/*style wrapper to give some space*/
.checkbox-wrapper {
  position: relative;
  margin-left: 5px;
  min-height: 35px;
}

/*style label to give some more space*/
.checkbox-wrapper label {
  display: block;
  padding: 5px 0 5px 32px;
  color: var(--secondary);
  font-size: var(--fs-sm);
  width: 100%;
}
.checkbox-wrapper label:hover {
  cursor: pointer;
}
.checkbox-wrapper label:hover input {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.filter-list .checkbox-wrapper label:hover {
  background-color: var(--transparent-primary);
}
/*style and hide original checkbox*/
.checkbox-wrapper input {
  width: 24px;
  height: 24px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

/*position new box*/
.checkbox-wrapper input + label::before {
  border: 2px solid;
  content: "";
  width: 24px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  color: var(--primary);
}

/* the tick style */
.checkbox-wrapper input + label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  opacity: 0;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: opacity 0.2s ease-out;
  font-size: var(--icn-checkboxes-check);
  color: var(--primary);
}

/*reveal check for 'on' state*/
.checkbox-wrapper input:checked + label::after {
  opacity: 1;
}

/*focus styles*/
.checkbox-wrapper input:focus + label::before {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  z-index: 1;
}
.checkbox-wrapper input:disabled + label::before,
.checkbox-wrapper input:disabled + label::after {
  color: var(--secondary);
}

/* ---------------------------------------------------------------------------------------------------------------------------------- */

.no-results {
  padding: 20px;
  font-size: var(--fs-md);
  margin: 0 auto;
  text-align: center;
  color: var(--secondary);
}
#sorting_by,
#search_within,
#search_limit {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  user-select: none;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  /* background-color: var(--card-color); */
  border: none;
  border-radius: 20px;
  color: #fff !important;
  background-color: var(--primary);
  font-size: var(--fs-xs);
  margin: 0 5px;
  padding: 5px 3px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 25%),
    -2px -2px 4px 0 rgb(255 255 255 / 50%);
  align-self: flex-end;
  width: 120px;
  max-width: 120px;
}
#sorting_by {
  width: 180px;
}
#search_within,
#search_limit {
  flex: 1 1;
}
option {
  background-color: var(--card-color);
  color: var(--secondary);
}
.refresh_results_btn,
.filters-panel-btn {
  cursor: pointer;
  color: #fff;
  margin: 0 5px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 25%),
    -2px -2px 4px 0 rgb(255 255 255 / 50%);
  border: 2px solid var(--transparent-primary);
  background-color: var(--primary);
}
.refresh_results_btn:hover,
.filters-panel-btn:hover,
#sorting_by:hover,
#search_within:hover,
#search_limit:hover {
  background-color: transparent;
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  color: var(--secondary) !important;
  border: 2px solid var(--primary);
}
.refresh_results_btn svg,
.filters-panel-btn svg {
  font-size: var(--fs-sm);
  margin-left: 10px;
}
.load-more-container {
  width: 145px;
  height: 224px;
  position: relative;
  cursor: pointer;
  color: var(--primary);
  font-size: var(--fs-lg);
  margin: 3px;
  padding: 60px 20px;
  text-align: center;
  border: 1px solid var(--primary);
}
.load-more-container:hover,
.load-more-container:focus {
  background-color: var(--primary);
  color: #fff;
}

.filters-container {
  display: flex;
  flex-flow: row wrap;
  height: auto;
  transition: all 0.5s ease-in-out 0.1s;
  padding: 15px 0;
}

.filters-single {
  display: inline-flex;
  padding-left: 5px;
  line-height: 1.2rem;
  margin: 2px;
  border-radius: 30px;
  background-color: #fff;
  color: #404040;
  max-height: 22px;
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--primary);
}
.filters-single:hover {
  cursor: pointer;
  box-shadow: 1px 1px 4px 0 var(--shadow-dark);
}
.filters-single p {
  font-size: var(--fs-xs);
  text-align: left;
}
.filters-single svg {
  font-size: var(--fs-sm);
  margin: 2px;
  cursor: pointer;
  color: var(--primary);
  border-radius: 10px;
}
.filters-single span {
  font-weight: bold;
}
/* .filters-container-btn:after {
  animation: shine 1s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 170%;
  height: 200%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.7);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 77%,
    rgba(255, 255, 255, 0.7) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
.filters-container-btn:active:after {
  opacity: 0;
}
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
} */
.floating-div.active .filters-container-buttons .filters-container-btn {
  background-color: var(--primary);
  color: #fff;
  flex: 1 1;
  padding: 10px;
}
.floating-div.active
  .filters-container-buttons
  .filters-container-btn:hover:not([disabled]),
.floating-div.active .filters-container-buttons .filters-container-btn:focus {
  background-color: transparent;
  color: var(--primary);
}
.filters-container.active .filters-container-buttons {
  display: flex;
  flex-wrap: wrap;
}
.filters-container-buttons.inside {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.filters-container-btn svg {
  float: right;
  margin: 2px 2px 0 7px;
}
.floating-div {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 20vw;
  min-width: 300px;
  max-width: 450px;
  background-color: var(--background-color);
  padding: 20px;
  -webkit-transform: translate(-450px, 0);
          transform: translate(-450px, 0);
  transition: -webkit-transform 0.5s ease-in-out 0.1s;
  transition: transform 0.5s ease-in-out 0.1s;
  transition: transform 0.5s ease-in-out 0.1s, -webkit-transform 0.5s ease-in-out 0.1s;
  z-index: 22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 4px 0 var(--shadow-dark);
  border-radius: 0 5px 0 0;
}
.floating-div.active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.filters-container-buttons {
  display: flex;
  width: 100%;
  align-items: center;
}
.filters-container-btn {
  position: relative;
  overflow: hidden;
  font-size: var(--fs-xs);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  margin: 5px;
  min-width: 45%;
  display: inline-block;
  font-weight: bold;
}
.filters-container-buttons.inside .filters-container-btn {
  min-width: 45%;
}
.filters-container-btn:hover:not([disabled]) {
  background-color: var(--overlay-color);
}
.filters-container-btn:disabled {
  color: var(--secondary);
  border-color: var(--secondary);
  opacity: 0.3;
  cursor: unset;
}
.filters-text {
  color: var(--secondary);
  text-align: center;
  width: 100%;
}
.checkbox.small {
  font-size: var(--fs-sm);
}

.internal-filtering {
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 7px 10px;
  margin-bottom: 15px;
  /* border: 2px solid transparent; */
  box-shadow: inset 0px 0px 3px 0px var(--shadow-dark);
}
.clear-inter-filter {
  position: absolute;
  right: 7px;
  top: 7px;
  color: var(--primary);
  cursor: pointer;
}
p.mini-filter-count {
  position: absolute;
  right: 35px;
  top: 9px;
  text-align: right;
  color: var(--primary);
  font-size: var(--fs-xs);
}
/* ------------------------- Start of rc-collapse styling --------------------------- */
.rc-collapse-motion {
  transition: height 0.3s, opacity 0.3s;
}
.rc-collapse-content-hidden {
  display: none;
}
.rc-collapse {
  background-color: var(--card-color);
  border-radius: 5px;
  border: 1px solid var(--primary);
}
.rc-collapse > .rc-collapse-item:first-child {
  border-top: none;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 10px 16px;
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 4px;
  position: relative;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header:focus {
  outline-width: 4px;
  outline-offset: 0px;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
  display: inline-block;
  content: "\20";
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
  vertical-align: middle;
  margin-right: 8px;
  right: 4px;
  position: absolute;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
  position: absolute;
  top: 8px;
  right: 4px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  margin-right: 6px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 16px 0 auto;
}
.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only {
  cursor: default;
}
.rc-collapse
  > .rc-collapse-item
  .rc-collapse-header-collapsible-only
  .rc-collapse-header-text {
  cursor: pointer;
}
.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: var(--secondary);
  /* background-color: #f3f3f3; */
}
.rc-collapse-content {
  overflow: hidden;
  color: var(--secondary);
  padding: 0;
  background-color: var(--card-color);
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 16px;
  min-height: 100px;
}
.rc-collapse-item:last-child > .rc-collapse-content {
  border-radius: 0 0 3px 3px;
}
.rc-collapse-item.rc-collapse-item-active {
  transition: all 1s ease-in-out;
}
.no-items {
  padding-top: 30px;
  text-align: center;
}
.searchfield-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
}

/* ------------------------- end of rc-collapse styling --------------------------- */

@media screen and (max-width: 768px) {
  .search-row {
    justify-content: center !important;
    padding: 20px 5px;
  }
  .filters-panel.active,
  .floating-div.active {
    width: 40vw;
  }
  .results-panel {
    width: 60vw;
  }
  .load-more-container {
    width: 100px;
    height: 160px;
    font-size: var(--fs-md);
    margin: 3px 3px 30px 3px;
    padding: 40px 20px;
  }
  .sorting-bar {
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 20px;
  }
  .search-outer-container {
    padding-top: 170px;
  }
}

@media screen and (max-width: 560px) {
  .filters-panel.active,
  .floating-div.active {
    width: 100vw;
    max-width: none;
  }
  .results-panel {
    width: 0;
  }
}
@media screen and (max-width: 480px) {
  .filters-categories {
    flex-direction: column;
  }
  .filter-list {
    padding-left: 40px;
  }
  .searchfield-wrapper {
    flex-direction: column;
  }
  .sorting-bar {
    position: relative;
    top: auto;
  }
  .sorting-bar-element {
    width: 100% !important;
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 960px) {
  .filters-container-btn {
    width: 100%;
  }
  .filters-container-buttons {
    flex-direction: column;
  }
  .floating-div .filters-container-buttons {
    height: auto;
  }
  .sorting-bar-wrp {
    margin: 100px 20px 0;
  }
  .sorting-bar-element {
    padding: 10px 5px;
    width: 50%;
    justify-content: center;
    align-items: flex-end;
  }
}

.event-card-wrap {
  min-width: 284px;
  width: 284px;
  height: 445px;
  margin: 8px;
  position: relative;
  background-color: var(--card-color);
}
.event-card-wrap:hover {
  outline-width: 3px;
  outline-color: var(--primary);
  outline-style: solid;
}
.event-image {
  width: 100%;
  height: 214px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.event-card-date,
.event-card-venue {
  color: var(--secondary);
  padding: 5px 15px;
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: flex;
}
.event-card-date p,
.event-card-venue p {
  float: left;
}
.event-card-date span {
  float: left;
  padding-left: 10px;
}
.event-titles {
  color: var(--primary);
  font-size: var(--fs-sm);
  padding: 15px;
}
.event-titles h1 {
  font-size: var(--fs-md);
}
.event-interaction-icons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  font-size: var(--fs-xl);
  color: var(--primary);
}

.event-interaction-icons div {
  margin: 10px auto;
  text-align: center;
  flex: 1 1;
  cursor: pointer;
}
.event-interaction-icons div:hover {
  font-size: 1.9rem;
  transition: none;
}

.event-interaction-icons a {
  color: var(--primary);
  padding: 10px;
}

.edge {
  border-right: 1px solid var(--primary);
}
.over {
  z-index: 100;
}
.event-below-icons {
  color: var(--secondary);
  position: absolute;
  bottom: -25px;
  right: 5px;
  opacity: 0.7;
}
.event-below-icons svg {
  font-size: var(--fs-md);
  margin-left: 8px;
}
.event-keywords {
  padding: 5px 15px;
  clear: left;
}
.event-keywords p {
  font-size: 0.8rem;
  display: inline-block;
  margin-right: 10px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}
.event-small-icons {
  margin-right: 6px;
  float: left;
}
.event-cat {
  background-color: var(--primary);
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: -3px;
  font-size: 0.8rem;
  box-shadow: 1px 1px 4px var(--shadow-dark);
}

.wcard-outer-container {
  min-height: calc(70vh - 600px);
}

.wcard-container {
  width: 70vw;
  margin: 15% auto 0;
  color: var(--secondary);
}

.wcard-screen-title {
  font-size: var(--fs-row-titles);
  color: var(--primary);
  /* padding: 60px 50px 0; */
}

.wcard-message {
  color: var(--secondary);
  font-size: var(--fs-xl);
  padding: 30px 0;
  font-weight: 100;
}

.wcard-cards-row {
  display: flex;
  flex-flow: row wrap;
  margin: 0px 20px;
  transition: all 1s ease;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 992px) {
  .wcard-outer-container {
    padding-top: 100px;
  }
  .wcard-container {
    width: 90vw;
    margin: 5% auto 0;
  }

  .wcard-cards-row {
    justify-content: center;
  }

  .wcard-screen-title {
    font-size: var(--fs-lg);
  }
  .wcard-message {
    font-size: var(--fs-sm);
  }
}

.lsp-positioner-srv {
  width: 100%;
}
.services-container {
  padding-top: 150px;
}
.services-container a {
  color: var(--secondary);
  font-weight: 500;
  position: relative;
}
.services-container div p {
  /* padding-left: 40px; */
  /* font-weight: 100; */
  /* font-size: var(--fs-md); */
  color: var(--secondary);
}
.services-container table {
  width: 70%;
  text-align: center;
  margin: 0 auto;
  border-collapse: collapse;
  border-style: solid;
}
.services-container table td {
  padding: 10px;
  /* vertical-align: top; */
}
h2.cards-row-title {
  font-size: var(--fs-row-titles);
  color: var(--primary);
  font-weight: 300;
  padding: 60px 50px 0;
}
.upper-div {
  height: auto;
  max-height: none;
  opacity: 1;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
  overflow: hidden;
  color: var(--secondary);
  transition: all 0.5s ease;
}
.upper-div.folded {
  height: 0;
  max-height: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease;
}
.middle-div,
.lower-div {
  height: auto;
  max-height: none;
  /* padding-bottom: 20px; */
  width: 90%;
  opacity: 1;
  margin: 0 auto;
  color: var(--secondary);
  overflow: hidden;
  transition: all 0.5s ease;
}
.middle-div.folded,
.lower-div.folded {
  height: 0;
  max-height: 0;
  opacity: 0;
  margin-top: 0;
  transition: all 0.5s ease;
  padding: 0;
}
.middle-div h2,
.lower-div h2 {
  color: var(--secondary);
  padding: 20px 0;
  font-size: var(--fs-xl);
  font-weight: 900;
}
.upper-div a,
.middle-div a,
.lower-div a {
  color: var(--primary);
  text-decoration: underline;
  font-weight: bold;
  text-decoration-thickness: max(2px, 0.1em);
  text-underline-offset: 0.1em;
}
.collapsible-div {
  max-height: 0;
  transition: max-height 1s ease;
}

.collapsible-div.expanded {
  max-height: none;
  transition: max-height 1s ease;
}

.upper-div ul,
.middle-div ul,
.lower-div ul {
  font-size: var(--fs-md);
  padding: 40px;
  margin-left: 50px;
}

.first-row-outer {
  position: relative;
  color: var(--secondary);
  font-size: 1.4rem;
  width: 100%;
  /* text-transform: uppercase; */
  text-align: center;
  overflow: hidden;
  min-height: 270px;
  /* padding: 0 20px; */
}
.first-row-outer.collapsed {
  min-height: 105px;
  height: 105px;
  margin-bottom: 20px;
}
.first-row-outer.collapsed .service-name {
  min-height: 100%;
}
.second-row-outer {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 120px;
  /* padding: 0 20px; */
}
.first-row-items {
  width: 100%;
  cursor: pointer;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
  position: relative;
  display: block;
}
.lsp-slider-wrap .slick-slide,
.first-row-outer .slick-slide,
.second-row-outer .slick-slide {
  margin: 5px 3.5px 14px;
}
.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 40px rgb(0 0 0) inset, 0px 0px 5px 0px var(--shadow-dark);
}
.first-row-inner,
.second-row-items {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 224px;
  /* min-height: 120px;
  aspect-ratio: 1/0.77; */
  width: 100%;
  position: relative;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}

.arrow-container:hover {
  opacity: 1;
}
.arrow-container {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
}
.arrow-container svg {
  margin-bottom: -15px;
}
.first-row-items.collapsed {
  /* height: 80px; */
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}
.first-row-items.collapsed.the-one:hover {
  background: none;
  cursor: default;
}
.first-row-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.first-row-outer.collapsed .first-row-items.collapsed .first-row-inner {
  height: 78px;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}
.the-one .first-row-inner .service-name h3 {
  color: var(--primary);
}
.the-one .pointy {
  width: 35px;
  height: 15px;
  background-color: var(--primary);
  position: absolute;
  bottom: -15px;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
          clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.service-name,
.sub-service-name {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--overlay-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 75px;
  padding: 5px;
  text-align: center;
  color: var(--secondary);
}
.cards-row {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0px 20px;
  transition: all 0.5s ease;
}

.second-row-items:hover {
  cursor: pointer;
  margin-bottom: 0;
}
.second-row-items.shorten {
  /* margin-bottom: -3px; */
  height: 84px;
  transition: height 0.3s ease-out;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
}
.second-row-items.shorten.selected {
  border: 3px solid var(--primary);
  display: flex;
  justify-content: center;
  overflow: visible;
}
.second-row-items.shorten .sub-service-name {
  height: 100%;
}
.second-row-items.shorten.selected .sub-service-name h3 {
  color: var(--primary);
}

.hover-line {
  background-color: var(--secondary);
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: -4px;
  display: none;
}
.second-row-items:hover .hover-line {
  display: block;
}
.second-row-items.shorten.selected .hover-line {
  display: none;
}
.scroll-up-container {
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
.scroll-up-btn {
  font-size: var(--fs-lg);
  color: var(--primary);
  text-align: center;
  cursor: pointer;
  width: 100px;
  z-index: 10;
  padding: 20px 0;
  margin-bottom: 20px;
}
.events-container {
  padding-top: 150px;
}
.events-row {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
}
.lsp-positioner-evt {
  width: 100%;
}
.first-row-outer .slick-slider .slick-list {
  height: 250px;
}
.events-row .slick-slider .slick-list {
  height: 500px;
}
.services-container dl {
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.services-container dt {
  float: left;
  font-weight: bold;
  width: 40%;
  margin: 0;
  font-size: var(--fs-md);
}
.services-container dd {
  color: var(--secondary);
  font-size: var(--fs-sm);
  overflow-wrap: break-word;
  float: left;
  width: 60%;
  padding: 0 0 20px;
  margin: 0;
}
.lib-hours-list {
  padding: 20px !important;
  margin: 0 !important;
  list-style: none;
}
.channel-title {
  font-size: var(--fs-xl);
  color: var(--primary);
  text-align: center;
  font-weight: 100;
  margin-bottom: 20px;
}

@media only screen and (max-width: 960px) {
  .lsp-positioner-srv {
    top: 120px;
  }
  .services-container {
    padding-top: 240px;
  }
}
@media only screen and (max-width: 1200px) {
  .upper-div {
    width: auto;
    padding: 0 20px 50px;
  }
  .middle-div,
  .lower-div {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cards-row.events-cards-row {
    height: 540px;
  }
  /* .first-row-outer.collapsed {
    height: auto;
  } */
  .services-container table {
    width: 100%;
  }
  h2.cards-row-title {
    font-size: var(--fs-lg);
    padding: 20px 30px 0;
  }
  .middle-div h2 {
    padding: 10px 0;
  }
  .upper-div ul,
  .middle-div ul,
  .lower-div ul {
    font-size: 1.4rem;
    padding: 20px;
    margin-left: 20px;
  }
  .scroll-up {
    font-size: 1.4rem;
  }
  .first-row-inner,
  .second-row-items {
    height: 116px;
  }
  .service-name,
  .sub-service-name {
    font-size: 0.8rem;
    min-height: 60px;
    line-height: 1rem;
    font-weight: 100;
    overflow-wrap: anywhere;
    /* hyphens: auto;
    -webkit-hyphens: auto; */
  }
  .first-row-outer .slick-slider.slick-initialized,
  .second-row-outer .slick-slider.slick-initialized {
    padding-top: 0;
  }
  .arrow-container svg {
    margin-bottom: -10px;
  }
  .first-row-outer {
    min-height: 150px;
  }
}

.settings-outer {
  /* margin: 0 10vw; */
  overflow: hidden;
}
.settings-tabs {
  padding: 200px 5vw 0;
  width: 100%;
}
.settings-tabs ul {
  list-style: none;
  display: flex;
  text-align: center;
}
.settings-tabs .settings-tabs-item {
  color: var(--secondary);
  display: inline-block;
  font-size: var(--fs-lg);
  font-weight: 900;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  width: 100%;
}
.settings-tabs .settings-tabs-item:hover,
.settings-tabs-item.activeTab {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
}
.settings-tabs ul li {
  flex: 1 1;
}
.settings-middle {
  padding: auto 10vw;
  display: flex;
  flex-flow: row wrap;
}
.settings-middle > * {
  flex: 1 1 200px;
}
.settings-column {
  background-color: var(--card-color);
  padding: 10px 20px;
  margin: 15px 5px;
  min-height: 360px;
  position: relative;
  border-bottom: 1px solid var(--primary);
}
.settings-column.active {
  outline: 3px solid var(--primary);
}
.settings-column .centered-form {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.settings-long {
  min-width: 90%;
  overflow: hidden;
}
.settings-free {
  width: 100%;
  /* overflow: hidden; */
}

.preference-list-item {
  color: var(--primary);
  font-size: var(--fs-lg);
  /* width: 250px; */
  padding: 10px 20px;
}
.preference-list-item svg {
  margin: 0 20px;
}
.settings-text {
  color: var(--secondary);
  font-size: var(--fs-md);
  padding: 20px 20px 0 0;
}

.settings-column h2,
.settings-long h2 {
  font-size: var(--fs-lg);
  color: var(--primary);
  padding: 10px;
}

button.edit-btn {
  color: var(--secondary);
  border: 1px solid;
  padding: 4px 10px;
  border-radius: 30px;
  opacity: 0.5;
  cursor: pointer;
  height: 25px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
button.edit-btn:hover,
button.edit-btn:focus {
  opacity: 1;
  background: var(--primary);
  color: #fff;
}
button.edit-btn svg {
  font-size: var(--icn-ins-btn);
  margin-left: 10px;
}
button.edit-btn.wide-edit-btn {
  margin: 5px auto;
}
.user-avatar-div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.user-avatar-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.avatar-sqr-img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.user-avatar-icon {
  font-size: 150px;
  display: block;
  margin: 0 auto;
  color: rgb(192, 192, 192);
}
.settings-container {
  margin: 30px;
}
/*------------------------ styling the Radio Buttons -----------------------*/
.settings-radio-group {
  position: relative;
  color: var(--secondary);
  font-size: var(--fs-md);
}
.settings-radio-group label {
  display: block;
  padding: 12px 0 12px 48px;
}
/*style and hide original checkbox*/
.settings-radio-group input {
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

/*position new box*/
.settings-radio-group input + label::before {
  border: 2px solid;
  content: "";
  height: 30px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 30px;
  border-radius: 50%;
  color: var(--primary);
}

/*radio pseudo element styles*/
.settings-radio-group input + label::after {
  content: "";
  opacity: 0;
  border: 10px solid;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: opacity 0.2s ease-in-out;
  color: var(--primary);
}

/*reveal check for 'on' state*/
.settings-radio-group input:checked + label::after {
  opacity: 1;
}

/*focus styles*/
.settings-radio-group input:focus + label::before {
  outline: 2px dotted var(--primary);
  outline-offset: 3px;
  z-index: 1;
}

.settings-radio-group input:disabled + label::before,
.settings-radio-group input:disabled + label::after {
  color: var(--secondary);
}
/* ----------------------------------------------------------- END Radio Buttons ----------------------------------------------------------- */

.settings-account-list {
  padding: 0 30px;
  font-size: var(--fs-md);
  display: flex;
}

.preference-list {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  overflow-y: auto;
  max-height: 250px;
}
.preference-list.limited {
  max-height: 135px;
  overflow-y: scroll;
  margin: 10px auto 20px;
}
.pref-list-ver {
  flex-flow: column nowrap;
}
.pref-list-hor {
  flex-flow: row wrap;
  align-self: flex-start;
}

.preference-item {
  color: var(--secondary);
  font-size: var(--fs-sm);
  border: 1px dashed var(--secondary);
  margin: 3px;
  padding: 3px 5px;
  display: inline-block;
}
.preference-line {
  color: var(--secondary);
  font-size: var(--fs-sm);
}

.settings-account-list dl {
  width: 100%;
  overflow: hidden;
}
.settings-account-list dt {
  color: var(--primary);
  float: left;
  width: 55%;
  padding-bottom: 10px;
  margin: 0;
}
.settings-account-list dd {
  color: var(--secondary);
  font-size: var(--fs-sm);
  float: left;
  width: 45%;
  padding-bottom: 15px;
  margin: 0;
  overflow-wrap: break-word;
}

.settings-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  /* flex-wrap: wrap; */
  /* margin-left: 20px; */
}
.settings-buttons button {
  padding: 4px 10px;
  min-width: 90px;
  border-radius: 20px;
  border: 1px solid;
  background: transparent;
  color: var(--secondary);
  margin-left: 15px;
}
.settings-buttons button:not([disabled]):hover,
.settings-buttons button:focus {
  cursor: pointer;
  border: 1px solid #fff;
  background: var(--primary);
  color: #fff;
}
.settings-buttons button:disabled {
  opacity: 0.3;
}
.settings-buttons button svg {
  font-size: var(--icn-ins-btn);
  margin-left: 5px;
}
.loans-row-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 40px;
  overflow-x: auto;
  overflow-y: hidden;
}
.preference-warning {
  color: var(--primary);
  font-size: var(--fs-md);
  padding: 20px;
  background: var(--background-color);
  width: 100%;
  border-radius: 5px;
}

.books-row .slick-slider .slick-list {
  overflow: visible;
}
.mini-books-row .slick-slider .slick-list {
  overflow: visible;
}
.events-row .slick-slider .slick-list {
  overflow: visible;
}
.mini-books-row .slick-track, /* to align isolated jackets to the left instead of the middle */
.books-row .slick-track {
  margin-left: 3px;
}

@media only screen and (max-width: 992px) {
  .preference-item {
    font-size: var(--fs-xs);
  }
  .preference-list {
    padding: 5px;
  }
  dt {
    width: 55%;
    font-size: var(--fs-md);
  }
  dd {
    width: 45%;
    font-size: var(--fs-md);
  }
  .settings-account-list dl {
    padding: 10px;
  }
  .user-avatar-div {
    margin: 10px auto;
  }
  .settings-tabs .settings-tabs-item {
    font-size: var(--fs-md);
    font-weight: 600;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 1400px) {
  .settings-buttons {
    flex-wrap: wrap;
    width: 100%;
  }
  .settings-buttons button {
    margin: 5px 0;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .mini-books-row {
    height: 200px;
    overflow: hidden;
  }
  .user-avatar-div {
    width: 200px;
  }
  .loans-row-container {
    padding: 0 10px;
  }
  .settings-column {
    padding: 10px;
    margin: 10px;
  }

  .settings-tabs .settings-tabs-item {
    font-size: var(--fs-sm);
  }
  .settings-account-list {
    flex-direction: column;
    padding: 0;
  }
  .settings-account-list dd,
  .settings-account-list dt {
    font-size: var(--fs-sm);
    width: 100%;
    float: none;
    padding: 0;
  }
  .settings-account-list dt {
    padding-top: 20px;
  }
  .preference-list-item {
    padding: 0;
  }
}

.preferences-outer-container {
  background: url(/dark-background-static.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: repeat;
  padding: 200px 0;
}
.preferences-background-overly {
  height: 100%;
  width: 100%;
  background: var(--background-color);
  opacity: 0.95;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.preferences-inner-container {
  height: 100%;
  width: 70%;
  margin: 0 auto;
}

.preferences-inner-container h3 {
  color: var(--primary);
  font-size: var(--fs-row-titles);
  font-weight: 100;
  z-index: 2;
  position: relative;
}
.preferences-buttons {
  width: 70%;
  margin: 0 auto;
}
.preferences-avatars-container,
.preferences-genres-container,
.preferences-formats-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 40px;
}
.justify-center {
  justify-content: center !important;
}

.avatar-outer {
  width: 120px;
  height: 120px;
  background-color: var(--secondary);
  margin: 10px;
  z-index: 2;
}

.genres-outer {
  width: 200px;
  min-height: 50px;
  color: var(--secondary);
  outline: 1px;
  outline-style: solid;
  outline-color: var(--secondary);
  text-align: center;
  padding: 10px;
  font-size: var(--fs-md);
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}
.avatar-outer:hover,
.avatar-outer.avatar-chosen {
  outline: 3px;
  outline-style: solid;
  outline-color: var(--primary);
  cursor: pointer;
}
.genres-outer:hover,
.genres-outer.genres-chosen {
  outline: 3px;
  outline-style: solid;
  outline-color: var(--primary);
  cursor: pointer;
}
.genres-outer.genres-chosen {
  color: var(--primary);
  font-weight: bold;
}
.formats-outer {
  width: 280px;
  height: 280px;
  background-color: white;
  margin: 30px 10px;
  z-index: 2;
}
.formats-outer p {
  width: 100%;
  display: block;
  text-align: center;
  font-size: var(--fs-xl);
  color: var(--secondary);
  z-index: 2;
}
.formats-outer:hover,
.formats-outer.formats-chosen {
  outline: 4px;
  outline-style: solid;
  outline-color: var(--primary);
  color: var(--primary);
  cursor: pointer;
}
.formats-outer.formats-chosen p {
  color: var(--primary);
  font-weight: bold;
}
.skip-link {
  width: 100%;
  text-align: center;
}
.skip-link a {
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}
.image-fit {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 992px) {
  .preferences-inner-container {
    width: 100%;
    padding: 0 20px;
  }

  .preferences-avatars-container,
  .preferences-genres-container,
  .preferences-formats-container {
    padding: 40px 0;
  }

  .preferences-inner-container h3 {
    font-size: var(--fs-xl);
  }
  .genres-outer {
    width: 150px;
    font-size: var(--fs-sm);
  }
}
@media screen and (max-width: 380px) {
  .genres-outer {
    width: 100%;
  }
  .preferences-buttons {
    width: 100%;
    margin: 0 auto;
  }
}

.footer {
  position: relative;
  width: 100%;
  background-color: var(--footer-color);
  margin-top: 100px;
  color: var(--secondary);
  box-shadow: inset 0px 1px 5px 0px var(--shadow-dark);
}
.footer.block {
  display: block;
}
.footer a {
  color: var(--primary);
}

.footer iframe {
  border: 0;
  position: absolute;
}

.footer-container {
  flex: 1 1;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer-text-container {
  font-size: var(--fs-md);
  padding-left: 30px;
  text-align: left;
}

.footer .footer-titles {
  color: var(--primary);
  font-size: var(--fs-xl);
  font-weight: 900;
}
.left-aligned {
  text-align: left;
  padding-left: 30px;
}
.hours-container {
  font-size: calc(var(--lsp-unit) * 1.5);
  line-height: calc(var(--lsp-unit) * 2);
}

.hours-container ul {
  list-style: none;
  padding: 30px;
}

.footer .address li {
  font-size: calc(var(--lsp-unit) * 1.5);
  font-weight: 300;
  list-style: none;
  padding-bottom: 10px;
}

.address {
  padding: 30px;
}

.footer .copyright {
  font-weight: 300;
  font-size: var(--fs-xs);
  /* position: absolute;
  bottom: 0; */
  text-align: center;
  width: 100%;
  padding: 5px 0;
  background: var(--overlay-color);
}

.social-media {
  color: var(--primary);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: clamp(280px, 30vw, 400px);
  margin: 0 auto;
}
.social-media a {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
}
.social-media a:hover,
.social-media a:focus {
  background: var(--primary);
  color: #fff;
  border-radius: 10px;
}
.social-media svg {
  font-size: var(--icn-desktop-lg);
}

.joinin-outer {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: var(--background-color);
  justify-content: space-between;
  margin: 80px 0 -100px;
  overflow: hidden;
  background-color: var(--background-color);
}
.joinin-right {
  width: 50%;
  height: 100%;
  z-index: 1;
  position: relative;
}
.joinin-right:before {
  content: "";
  position: absolute;
  width: 6000px;
  height: 4000px;
  top: -1940px;
  left: -2000px;
  opacity: 0.4;
  z-index: -1;
  -webkit-transform: rotate(30deg) scale(0.5);
          transform: rotate(30deg) scale(0.5);
  background: url("/dark_background.jpg") 0 0 repeat;
}
.joinin-left {
  background-color: var(--primary);
  color: #fff;
  width: 50%;
  -webkit-clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%);
  padding: 50px 0;
  z-index: 2;
}
.joinin-left-content {
  display: flex;
  width: 70%;
  margin: 0 auto;
  align-items: center;
  font-size: var(--fs-lg);
}
.button-join {
  min-width: 10rem;
  color: var(--primary);
  border-radius: 30px;
  padding: 5px;
  font-size: var(--fs-md);
  background: #fff;
  border: 2px solid var(--primary);
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
}
.button-join svg {
  float: right;
  font-size: var(--icn-jackets-hor);
}
.button-join:hover {
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}
.button-join:focus {
  outline-color: #fff;
}
@media screen and (max-width: 1200px) {
  .joinin-left {
    padding: 15px 0;
  }
  .desktop-only {
    display: none;
  }
  .address .phone-number {
    padding: 10px 0;
  }
  .address .phone-number a {
    font-size: calc(var(--lsp-unit) * 1.8);
  }
  .social-media a {
    margin: 5px;
  }
}
@media screen and (max-width: 768px) {
  .joinin-left {
    width: 80%;
  }
  .joinin-right {
    width: 20%;
  }
  .social-media {
    justify-content: center;
  }
  .social-media a {
    height: 50px;
    width: 50px;
  }
  .social-media svg {
    font-size: var(--fs-xl);
  }
  .social-media a:hover,
  .social-media a:focus {
    border-radius: 5px;
  }
}
@media screen and (max-width: 480px) {
  .joinin-left-content {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    text-align: center;
  }
  .button-join {
    width: 80%;
    padding: 10px 0;
    margin-left: 0;
    font-size: var(--fs-md);
    margin-top: 20px;
    height: auto;
  }
  .button-join svg {
    font-size: var(--icn-jackets-ver);
    margin-right: 10px;
  }
  .joinin-outer {
    margin: 50px 0 0;
  }
  .joinin-right {
    display: none;
  }
  .joinin-left {
    width: 100%;
    -webkit-clip-path: none;
            clip-path: none;
  }
  .footer-titles {
    font-size: calc(var(--lsp-unit) * 1.4);
    margin-top: 20px;
  }
  .footer .address li {
    font-size: var(--fs-md);
  }
  .footer {
    min-height: auto;
    margin-top: 0;
  }
}

nav {
  position: fixed;
  top: 0;
  z-index: 101;
}

.brand-link {
  height: 100px;
}
.search-box-wrap {
  overflow: hidden;
  position: relative;
}
.search-box-container {
  /* height: 100px; */
  position: relative;
  right: -410px;
  margin: 35px 0 10px 10px;
  transition: right 0.3s ease-out;
}
.search-box-container.active {
  right: 5px;
}
.search-box-container svg {
  position: absolute;
  left: 10px;
  top: 10px;
  color: rgb(180, 180, 180);
  font-size: var(--fs-xl);
}
.search-box-container.mobile {
  position: relative;
  margin: 10px 5px;
  right: auto;
}
#search-box {
  padding: 10px 10px 10px 50px;
  font-size: var(--fs-md);
  height: 50px;
  border-radius: 25px;
  width: 100%;
  border: 1px solid var(--primary);
  box-shadow: inset 1px 2px 5px 0px var(--shadow-dark);
}
#search-icon {
  cursor: pointer;
}
.navbar-container {
  height: 100px;
  display: flex;
  justify-content: stretch;
  align-items: top;
  color: var(--secondary);
  width: 100%;
}
.nav-links-titles {
  font-size: var(--nav-fs-lg);
  font-weight: bold;
  /* text-transform: uppercase; */
}
.navbar-container a,
.navbar-container button {
  border-radius: 4px;
}
.nav-menu .nav-links-titles {
  padding-top: 10px;
}
.nav-menu.active .nav-links-titles {
  padding-top: 0;
}

nav .nav-links:hover {
  color: var(--primary) !important;
  border-bottom: 3px solid var(--primary);
  cursor: pointer;
}
.inactive-nav p {
  margin-top: -1px;
}
.active-nav section {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
}
.active-nav .user-avatar {
  color: var(--primary);
}
.mob-links-titles {
  font-size: var(--nav-mob-fs);
}

section.nav-links {
  padding-bottom: 5px;
  font-size: var(--nav-icns);
}
section.mobile-links {
  padding-bottom: 5px;
  font-size: var(--nav-icns);
  display: flex;
  align-items: center;
}

nav section:hover,
.active-nav {
  color: var(--primary);
  /* transition: all 0.2s ease-out; */
}

section.mobile-links {
  height: auto;
}

.inside-navs {
  margin: auto 28%;
}

.navbar-logo {
  justify-self: start;
  margin: 15px;
  height: 70px;
  width: 200px;
  cursor: pointer;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.nav-menu {
  display: flex;
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 40px 10px 0;
  height: 100%;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  z-index: 12;
}
.menu-icon,
nav .copyright {
  display: none;
  z-index: 1001;
}

/* nav section svg {
  width: 45px;
  height: auto;
  display: block;
} */

.hidden {
  display: none;
}

.items-inside {
  display: block;
  width: 100%;
  color: var(--navbar-icons-color);
  padding: 10px 0;
}
#user-menu:focus .user-avatar {
  color: var(--primary);
}
@media screen and (max-width: 960px) {
  .navbar-container {
    position: fixed;
    height: 100px !important;
  }

  .nav-menu {
    position: absolute;
    top: 0;
    flex-direction: column;
    padding: 60px 40px 0;
    width: 50vw;
    height: 100vh;
    margin-right: 0;
    right: -100%;
  }
  .nav-menu.active section.nav-links {
    padding: 10px 0;
  }

  .nav-menu a {
    display: none;
  }
  .nav-menu.active a {
    display: block;
    padding: 10px 0;
  }
  .nav-menu.active {
    position: fixed;
    background-color: var(--background-color);
    right: 0;
    transition: all 0.5s ease;
    overscroll-behavior: contain;
    box-shadow: 0px 0px 10px 0px var(--shadow-dark);
    justify-content: flex-start;
    transition: all 0.5s ease;
    overflow: auto;
  }

  nav .nav-links:hover,
  nav section:hover,
  .active-nav section {
    border: none;
  }

  .menu-icon {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  nav .copyright {
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    left: 35%;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  #user-menu {
    display: none;
  }
  .hidden {
    display: block;
  }
  .search-box-wrap {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: -60px;
    background: var(--background-color);
    margin: 5px 0;
  }
  .search-box-container.active {
    padding: 5px;
    height: auto;
    margin: 10px 5px;
  }
  .search-box-container.active svg {
    left: 15px;
    top: 15px;
  }
  nav section {
    text-align: left;
    padding: 30px 0 0 40px;
  }

  nav section svg {
    margin: 0 20px 0 0;
    float: left;
  }
}

@media screen and (max-width: 600px) {
  .search-box-container {
    padding: 0;
  }
  .nav-menu {
    width: 100vw;
    padding: 60px 40px 0;
  }

  .nav-menu.active {
    font-size: 0.9rem;
  }

  .nav-links-titles {
    font-size: var(--fs-md);
  }
}

@media screen and (max-width: 340px) {
  .nav-links-titles {
    font-size: var(--fs-sm);
  }
  nav section {
    padding: 5px 0 0 20px;
  }
}

.user-nav-outer {
  position: relative;
  cursor: pointer;
}
.avatar-img {
  overflow: hidden;
  width: 45px;
  height: auto;
  margin-bottom: -15px;
}
.inner-menu-wrap {
  height: 0;
  z-index: -1;
  transition: height 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-filter: drop-shadow(2px 2px 3px var(--shadow-dark));
          filter: drop-shadow(2px 2px 3px var(--shadow-dark));
  position: absolute;
  top: 50px;
  left: -5px;
  width: 75px;
}
.inner-menu-wrap.active {
  height: 210px;
  z-index: 1;
}
.inner-menu {
  border-radius: 5px 5px 0 0;
  height: 100%;
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 50% 90%);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 50% 90%);
  /* width: 70px; */
}
.user-menu-outer {
  position: relative;
  color: var(--navbar-icons-color);
}
.inner-icons-container {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}
.inner-icons-container button,
.inner-icons-container a {
  flex: 1 1;
  /* min-height: 50px; */
  color: inherit;
  padding: 4px 0;
}
.inner-icons-container button:focus,
.inner-icons-container a:focus {
  outline-offset: -4px;
}
.inner-icons-container button:hover,
.inner-icons-container a:hover,
.user-name:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: var(--primary);
  cursor: pointer;
}
p.user-icons-labels {
  font-size: var(--nav-fs-sm);
  line-height: 0.9rem;
  color: inherit;
  font-weight: bold;
}
.user-icons {
  font-size: var(--fs-xl);
  margin: 6px 10px 0;
  text-align: center;
  /* color: var(--sticky-navbar-icons-color); */
  /* filter: drop-shadow(1px 1px 2px var(--shadow-light)); */
}
.user-avatar {
  margin: 0;
  font-size: var(--nav-icns);
}
.user-nav-outer:hover {
  color: var(--primary);
}
.user-nav-outer:hover svg {
  color: var(--primary);
}

.user-name {
  font-size: var(--nav-fs-lg);
  padding-top: 10px;
  font-weight: bold;
}
.user-name.active {
  color: var(--primary);
}

.logout-waiting {
  position: absolute;
  left: 20px;
  bottom: 20px;
  opacity: 0.5;
}

